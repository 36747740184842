import React from "react";
import cn from "classnames";
import styles from "./styles/SectionCaption.module.scss";
import { CaptionArrow, DemoLiveCaptionIcon } from "components/common/";
import { SectionCaptions } from "./models/enums/section-captions.enum";
import { SettingsCaptionIcon } from "widgets/OptionsWidget/modules/elements";

const SectionCaption: React.FC<PropsType> = React.memo(
  ({ captionTitle, activeElem, setActiveElem, captionPosition }) => {
    return (
      <div
        className={cn(styles.section_caption, {
          [styles.fullWidth]: activeElem,
          [styles.right_sided]: captionPosition === "right",
          [styles.left_sided]: captionPosition === "left",
        })}
        onClick={() => setActiveElem(!activeElem)}
      >
        {captionTitle === SectionCaptions.SETTINGS ? (
          <SettingsCaptionIcon
            className={cn(styles.icon, {
              [styles.active]: activeElem,
            })}
          />

        ) : captionTitle === SectionCaptions.DEMO_MODE || captionTitle === SectionCaptions.MATCH_SELECTOR ? (
          <DemoLiveCaptionIcon
            className={cn(styles.icon, {
              [styles.active]: activeElem,
            })}
          />
        ) : null}

        <h2>{`${captionTitle}`}</h2>

        <CaptionArrow className={cn(styles.arrow)} />
      </div>
    );
  },
);

type PropsType = {
  activeElem: boolean;
  setActiveElem: (val: boolean) => void;
  captionPosition: string;
  captionTitle: string;
};

export default SectionCaption;
