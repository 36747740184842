import React from 'react'
import cn from 'classnames'

import statStyles from './styles/Statistics.module.scss'
import { StatisticTypes, WidgetPosition } from './components'

import { OptionsArrowDropDownIcon } from 'components/common'
import { useAppSelector } from "@/store/store";
import { getActiveSport } from "@/store/selectors/generalSelectors";
import { sportsWidgetsMapConfig } from "./config/sports-widgets-map-config";

const Statistics: React.FC = () => {
  const [expandedElem, toggleExpandedElem] = React.useState<boolean>(true);
  const activeSport = useAppSelector(getActiveSport);

  const getStatHeight = (): number => {
    const elementHeight = 195;
    const widgetTitleHeight = 40;
    const statTypesLength =  Object.values(sportsWidgetsMapConfig[activeSport]).length;

    return elementHeight + widgetTitleHeight * statTypesLength;
  };

  return (
    <div
      className={cn(statStyles.statistics, {
        [statStyles.expanded]: expandedElem,
      })}
      style={{height: expandedElem ? `${getStatHeight()}px` : '',
        marginBottom: !expandedElem ? '5px' : '' }}
    >
      <div
        className={cn(statStyles.statistics__title)}
        key='evStatistics'
        onClick={() => toggleExpandedElem(!expandedElem)}
      >
        <h2>Statistics</h2>

        <OptionsArrowDropDownIcon
          className={cn(statStyles.arrow, {
            [statStyles.rotated]: expandedElem,
          })}
        />
      </div>
      <WidgetPosition />
      <StatisticTypes />
    </div>
  )
}
export default React.memo(Statistics)
