import React from "react";

const TrashIcon: React.FC = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41453 0.166016C4.58846 0.166016 
    3.9188 0.835675 3.9188 1.66174V2.73012H2.85043H1.14103C0.786997 
    2.73012 0.5 3.01712 0.5 3.37114C0.5 3.72517 0.786997
     4.01217 1.14103 4.01217H2.2094V15.337C2.2094 16.163 
     2.87906 16.8327 3.70513 16.8327H12.2521C13.0782 16.8327 
     13.7479 16.163 13.7479 15.337V4.01217H14.8162C15.1703 
     4.01217 15.4573 3.72517 15.4573 3.37114C15.4573 3.01712 
     15.1703 2.73012 14.8162 2.73012H13.1068H12.0385V1.66174C12.0385
      0.835675 11.3688 0.166016 10.5427 0.166016H5.41453ZM10.7564 
      2.73012V1.66174C10.7564 1.54373 10.6607 1.44807 10.5427 
      1.44807H5.41453C5.29652 1.44807 5.20085 1.54373 5.20085
       1.66174V2.73012H10.7564ZM4.55983 4.01217H3.49145V15.337C3.49145
        15.455 3.58712 15.5506 3.70513 15.5506H12.2521C12.3701 
        15.5506 12.4658 15.455 12.4658 15.337V4.01217H11.3974H4.55983Z"
      />
    </svg>
  );
};

export default TrashIcon;
