import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  StylesInterface,
  ThemeInterface,
} from '@/store/reducers/models/interfaces/styles.interface'

import { DefaultTheme } from '@/widgets/OptionsWidget/modules/Settings/models/default-theme.enum'

const initialState = {
  themes: [
    {
      id: DefaultTheme.DARK,
      name: DefaultTheme.DARK,
      colors: {
        teams: {
          home: '#3970FF',
          away: '#1CB702',
          none: '#848B97',
        },
        backgrounds: {
          main: '#232935',
          additional: '#565D6A',
        },
        fonts: {
          main: '#E1E1E1',
          sub_text: '#A4A9B2',
        },
        active: {
          selected: '#7166F9',
          text: '#FFFFFF',
        },
      },
    },
    {
      id: DefaultTheme.LIGHT,
      name: DefaultTheme.LIGHT,
      colors: {
        teams: {
          home: '#3970FF',
          away: '#1CB702',
          none: '#848B97',
        },
        backgrounds: {
          main: '#FFFFFF',
          additional: '#E1E1E1',
        },
        fonts: {
          main: '#565D6A',
          sub_text: '#848B97',
        },
        active: {
          selected: '#7166F9',
          text: '#FFFFFF',
        },
      },
    },
  ],
  selectedThemeId: DefaultTheme.DARK,
} as StylesInterface

const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {
    setStyles(state, action: PayloadAction<StylesInterface>) {
      const { selectedThemeId, themes } = action.payload
      state.selectedThemeId = selectedThemeId
      state.themes = themes
    },
    updateThemes(state, action: PayloadAction<ThemeInterface[]>) {
      state.themes = action.payload
    },
    addStyleTheme(state, action: PayloadAction<ThemeInterface>) {
      state.themes.push(action.payload)
    },
    removeStyleTheme(state, action: PayloadAction<string>) {
      state.themes = state.themes.filter((item) => item.id !== action.payload)
    },
    setActiveStyleThemeId(state, action: PayloadAction<string>) {
      state.selectedThemeId = action.payload
    },
  },
})

export const { setStyles, updateThemes, addStyleTheme, removeStyleTheme, setActiveStyleThemeId } =
  stylesSlice.actions
export default stylesSlice.reducer
