import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import styles from './styles/AnimationIframe.module.scss'
import LiveWidget from './components/LiveWidget/LiveWidget'
import DemoWidget from './components/DemoWidget/DemoWidget'

import { getActiveMode } from '@/store/selectors/generalSelectors'
import { WidgetMode } from '@/store/reducers/models/enums/general.enum'

const AnimationIframe: React.FC = memo(() => {
  const widgetMode = useSelector(getActiveMode)

  return (
    <div className={styles.animation_iframe}>
      {widgetMode === WidgetMode.LIVE ? <LiveWidget /> : <DemoWidget />}

      {/* <div className={styles.title}>Match:</div> */}
      {/* <code>{JSON.stringify(liveMode.selectedMatch, null, 2)}</code> */}
      {/* <div className={styles.title}>Settings:</div> */}
      {/* <code>{JSON.stringify(settings, null, 2)}</code> */}
      {/* <div className={styles.title}>Styles:</div> */}
      {/* <code>{JSON.stringify(stateStyles, null, 2)}</code> */}
      {/* <div className={styles.title}>Language:</div> */}
      {/* <code>{JSON.stringify(languages, null, 2)}</code> */}
    </div>
  )
})

export default AnimationIframe
