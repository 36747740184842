import React from "react";

const LanguageCaptionIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.64863 0C0.773961 0 0 0.674529 
           0 1.58418V13.0086C0 13.2356 0.129408
           13.4428 0.333432 13.5424C0.537457
           13.642 0.780415 13.6166 0.959439
           13.477L4.47458 10.7352C4.55673 10.6711
           4.66574 10.6323 4.7831 10.6323H5.48369V15.1407C5.48369
           16.0121 6.14645 16.8011 7.06201 16.8011H14.9633C15.0408
           16.8011 15.1231 16.8301 15.1949 16.8947L18.4757
           19.8474C18.65 20.0044 18.9004 20.044 19.1148
           19.9485C19.3291 19.8531 19.4671 19.6405 19.4671
           19.4058V7.67681C19.4671 6.8055 18.8044 6.01647
           17.8888 6.01647H14.8974V1.58418C14.8974 0.674529
           14.1234 0 13.2488 0H1.64863ZM13.7093 6.60651L13.7092
           6.61054L13.7093 6.61456V9.0481C13.7093 9.2321 13.5389
           9.44415 13.2488 9.44415H4.7831C4.40815 9.44415 4.04036
           9.56703 3.74384 9.79832L4.10921 10.2667L3.74384
            9.79832L1.18814 11.7918V1.58418C1.18814 1.40018
            1.35846 1.18814 1.64863 1.18814H13.2488C13.5389
            1.18814 13.7093 1.40018 13.7093 1.58418V6.60651ZM14.8974
            7.20461H17.8888C18.0604 7.20461 18.279 7.37036 18.279
            7.67681V18.072L15.9897 16.0116C15.7081 15.7581 15.3453
            15.613 14.9633 15.613H7.06201C6.8904 15.613 6.67183
            15.4472 6.67183 15.1407V10.6323H11.2314L10.5075
            11.9482H10.3581C10.1565 11.9482 9.99319 12.0946
            9.99319 12.2748C9.99319 12.4055 10.079 12.5179
            10.2027 12.57L9.76441 13.4641C9.68345 13.6293
            9.76741 13.822 9.95185 13.8944C10.1364 13.9669
            10.3517 13.8917 10.4326 13.7265L10.9842 12.6014H13.0526L13.6042
            13.7265C13.6642 13.849 13.7981 13.922 13.9386 13.922C13.9875
            13.922 14.0372 13.9132 14.085 13.8944C14.2694 13.822 14.3535
            13.6293 14.2724 13.4641L13.8341 12.57C13.9578 12.5179 14.0437
            12.4055 14.0437 12.2748C14.0437 12.0946 13.8803 11.9482 13.6788
            11.9482H13.5294L12.8054 10.6323H13.2488C14.1234 10.6323 14.8974
            9.95776 14.8974 9.0481V7.20461ZM11.3044 11.9482L11.9495
            10.6323H12.0872L12.7323 11.9482H11.3044ZM5.20776 8.22977C5.11423
            8.03519 5.17668 7.79102 5.34724 7.68431C6.2665
            7.10936 6.88521 6.08137 7.03886 4.92H5.80446C5.60997
            4.92 5.45233 4.74017 5.45233 4.51828C5.45233
            4.2964 5.60997 4.11657 5.80446 4.11657H7.07655V2.44254C7.07655
            2.22065 7.23418 2.04082 7.42868 2.04082C7.62318
            2.04082 7.78082 2.22065 7.78082 2.44254V4.11657H9.38193C9.57643
             4.11657 9.73406 4.2964 9.73406 4.51828C9.73406 4.74017
             9.57643 4.92 9.38193 4.92H7.81851C7.97215 6.08137 8.59086
             7.1092 9.51013 7.68431C9.68069 7.79102 9.74314 8.03519 9.6496
             8.22977C9.5855 8.363 9.46487 8.43847 9.34052 8.43847C9.2833
              8.43847 9.22525 8.42247 9.17147 8.38889C8.38618 7.89757 7.78219
              7.13337 7.42868 6.23217C7.07531 7.13337 6.47118 7.89757
               5.68589 8.38889C5.63211 8.42247 5.57406 8.43847 5.51684
               8.43847C5.3925 8.43847 5.27186 8.363 5.20776 8.22977Z"
      />
    </svg>
  );
};

export default LanguageCaptionIcon;
