import type { FeedType } from './typescript/enums/feed-type.enum'
import type { ProtoRequest } from './typescript/interfaces/proto-request.interface'

const commands_pb = require('./proto-models/commands_pb')

const protoTransform = () => {
  const patchWithMeta = (request: any): number => {
    const meta = new commands_pb.Meta()

    const sec = Math.floor(+new Date() / 1000)
    meta.setTs(sec)
    meta.setRequestId(sec)

    request.setMeta(meta)

    return sec
  }

  const getSubscribeToSport = (
    sportType: string,
    provider: string,
    feedType: FeedType,
  ): ProtoRequest => {
    const request = new commands_pb.Request()
    const subSport = new commands_pb.SubSport()
    const requestId = patchWithMeta(request)
    subSport.setSport(sportType)
    subSport.setFeedType(feedType)
    subSport.setProvider(provider)
    request.setSubsport(subSport)
    return { requestId, data: request.serializeBinary() }
  }

  const getUnsubscribeFromAll = (): ProtoRequest => {
    const request = new commands_pb.Request()
    const unsubAll = new commands_pb.UnsubAll()
    const requestId = patchWithMeta(request)
    request.setUnsuball(unsubAll)

    return { requestId, data: request.serializeBinary() }
  }

  const getSubscribeToLiveSportsList = (provider: string, feedType: FeedType): ProtoRequest => {
    const request = new commands_pb.Request()
    const getSports = new commands_pb.GetSports()
    const requestId = patchWithMeta(request)
    getSports.setFeedType(feedType)
    getSports.setProvider(provider)
    request.setGetsports(getSports)
    return { requestId, data: request.serializeBinary() }
  }

  const convertProtoResponseToJSObject$ = async (data: any) => {
    const bufferedArray = await new Response(data).arrayBuffer()
    if (bufferedArray && bufferedArray.byteLength > 1) {
      const uint8Array = new Uint8Array(bufferedArray)
      try {
        return commands_pb.Response.deserializeBinary(uint8Array).toObject()
      } catch (e) {
        console.error(e)
      }
      return null
    }
  }

  return {
    getSubscribeToLiveSportsList,
    getSubscribeToSport,
    getUnsubscribeFromAll,
    convertProtoResponseToJSObject$,
  }
}

export default protoTransform
