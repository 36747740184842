import React from 'react'

import { AdminPanelWrapper } from './components'

import { getActiveThemeId, getStyles } from '@/store/selectors/stylesSelectors'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { setActiveStyleThemeId } from '@/store/reducers/styles'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { useLiveSocket } from '@/widgets/MatchSelectorWidget/hooks/useLiveSocket'
import { authorize } from '@/store/thunks/backOfficeThunks'

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const styles = useAppSelector(getStyles)
  const activeThemeId = useAppSelector(getActiveThemeId)
  const activeSport = useAppSelector(getActiveSport)

  useLiveSocket(activeSport)

  React.useEffect(() => {
    if (activeThemeId === '') {
      dispatch(setActiveStyleThemeId(styles.themes[0].id))
    }
    dispatch(authorize())
  }, [])

  return (
    <div className='app'>
      <AdminPanelWrapper />
    </div>
  )
}
export default App
