import React from "react";

const CloseIcon: React.FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.89679 0.825437C1.46287 0.391521 0.759353 
0.391521 0.325437 0.825437C-0.108479 1.25935 
-0.108479 1.96287 0.325437 2.39679L8.42865
10.5L0.325437 18.6032C-0.108479 19.0371
-0.108479 19.7406 0.325437 20.1746C0.759353
20.6085 1.46287 20.6085 1.89679 20.1746L10 
12.0713L18.1032 20.1746C18.5371 20.6085 
19.2406 20.6085 19.6746 20.1746C20.1085 
19.7406 20.1085 19.0371 19.6746 18.6032L11.5713 
10.5L19.6746 2.39679C20.1085 1.96287 20.1085 
1.25935 19.6746 0.825437C19.2406 0.391521 
18.5371 0.391521 18.1032 0.825437L10 
8.92865L1.89679 0.825437Z"
      />
    </svg>
  );
};

export default CloseIcon;
