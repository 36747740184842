import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "font-awesome/css/font-awesome.min.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

reportWebVitals();
