import React from "react";

const CheckedIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="1.25"
        stroke="#7166F9"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2827 6.0983C14.6375 5.74588 15.2116 5.74588 15.5664 6.0983C15.9227 6.45226 15.9227 7.0273 15.5664 7.38126L9.00075 13.903C8.64595 14.2554 8.07187 14.2554 7.71708 13.903L4.43425 10.6421C4.07791 10.2882 4.07791 9.71313 4.43425 9.35917C4.78904 9.00675 5.36312 9.00675 5.71792 9.35917L8.35891 11.9825L14.2827 6.0983Z"
        fill="#7166F9"
      />
    </svg>
  );
};

export default CheckedIcon;
