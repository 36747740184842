import { v4 as uuidv4 } from 'uuid'
import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  DemoModeInterface,
  EventInterface,
} from '@/store/reducers/models/interfaces/demoMode.interface'
import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

const initialState: DemoModeInterface = {
  sports: [],
  events: [],
  demoSessionId: uuidv4(),
}

const demoModeSlice = createSlice({
  name: 'demoMode',
  initialState,
  reducers: {
    setDemoSports(state, action: PayloadAction<ActiveSports[]>) {
      state.sports = action.payload
    },
    setDemoSportEvents(state, action: PayloadAction<EventInterface[]>) {
      state.events = action.payload
    },
  },
})

export const { setDemoSports, setDemoSportEvents } = demoModeSlice.actions
export default demoModeSlice.reducer
