import { ActiveSports } from '@/store/reducers/models/enums/general.enum'

export const AvailableSports: ActiveSports[] = [
  ActiveSports.FOOTBALL,
  ActiveSports.BASKETBALL,
  ActiveSports.TENNIS,
  ActiveSports.VOLLEYBALL,
  ActiveSports.AMERICAN_FOOTBALL,
  ActiveSports.HANDBALL,
  ActiveSports.DARTS,
  ActiveSports.ICE_HOCKEY,
  ActiveSports.DOTA_2,
  ActiveSports.LOL,
  ActiveSports.BASEBALL,
  ActiveSports.WATER_POLO,
  ActiveSports.RUGBY_LEAGUE,
  ActiveSports.RUGBY_UNION,
  ActiveSports.CRICKET,
  ActiveSports.TABLE_TENNIS,
]

// less value - more valuable
export const SportWeight: Record<ActiveSports, number> = {
  [ActiveSports.FOOTBALL]: 1,
  [ActiveSports.TENNIS]: 2,
  [ActiveSports.BASKETBALL]: 3,
  [ActiveSports.VOLLEYBALL]: 4,
  [ActiveSports.TABLE_TENNIS]: 5,
  [ActiveSports.DOTA_2]: 6,
  [ActiveSports.ICE_HOCKEY]: 7,
  [ActiveSports.AMERICAN_FOOTBALL]: 8,
  [ActiveSports.BASEBALL]: 9,
  [ActiveSports.CRICKET]: 10,
  [ActiveSports.DARTS]: 11,
  [ActiveSports.HANDBALL]: 12,
  [ActiveSports.RUGBY_LEAGUE]: 13,
  [ActiveSports.RUGBY_UNION]: 14,
  [ActiveSports.WATER_POLO]: 15,
  [ActiveSports.LOL]: 16,
}
