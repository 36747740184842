import React from "react";
import { Header } from "./modules";

const HeaderWidget: React.FC = React.memo(() => {
  return (
    <>
      <Header />
    </>
  );
});

export default HeaderWidget;
