import { createAsyncThunk } from '@reduxjs/toolkit'

import type { AppDispatch, AppStateType } from '@/store/store'

export type AsyncThunkConfig = {
  state: AppStateType
  dispatch: AppDispatch
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncThunkConfig>()
