import React from "react";

const SportsArrowDropDownIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0031 0.923514C12.2854 1.18716 12.2854 
                                             1.62463 12.0031 1.88828L6.98485 6.57524C6.71484 
                                             6.82742 6.28485 6.82742 6.01483 6.57524L0.996557 
                                             1.88828C0.859678 1.76044 0.784843 1.5907 0.784843 
                                             1.4059C0.784843 1.22109 0.859678 1.05136 0.996557 
                                             0.923514C1.26657 0.671328 1.69656 0.671328 1.96658 
                                             0.923514L6.49984 5.15748L11.0331 0.923514C11.3031 
                                             0.671329 11.7331 0.671329 12.0031 0.923514Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default SportsArrowDropDownIcon;
