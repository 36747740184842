import React from "react";

const TickIcon: React.FC = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.77051 0.208984C3.59385 0.208984 0.208008 3.59483 0.208008 7.77148C0.208008 11.9481 3.59385 15.334 7.77051 15.334C11.9472 15.334 15.333 11.9481 15.333 7.77148C15.333 3.59483 11.9472 0.208984 7.77051 0.208984Z"
                fill="#7166F9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0066 5.45136C11.2751 5.71985 11.2751 6.15515 11.0066 6.42364L7.33998 10.0903C7.07149 10.3588 6.63619 10.3588 6.36771 10.0903L4.53437 8.25697C4.26589 7.98848 4.26589 7.55318 4.53437 7.2847C4.80286 7.01621 5.23816 7.01621 5.50664 7.2847L6.85384 8.6319L10.0344 5.45136C10.3029 5.18288 10.7382 5.18288 11.0066 5.45136Z"
                fill="white"
            />
        </svg>
    );
};

export default TickIcon;
