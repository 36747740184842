import React from "react";
import styles from "./styles/Preloader.module.scss";
import preLoader from "../../../../src/assets/images/preloader.gif";

const Preloader: React.FC = () => {
  return (
    <div className={styles.preloader}>
      <img className={styles.preloader__img} alt="preloader" src={preLoader} />
    </div>
  );
};

export default Preloader;
