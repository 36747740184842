import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  LanguagesInterface,
  LanguagesListInterface,
} from './models/interfaces/languages.interface'

import { Languages } from 'widgets/OptionsWidget/modules/Settings/components/LanguageSelect/configs/languages.enum'

const initialState = {
  languages: [
    {
      name: 'English',
      id: Languages.ENGLISH,
    },
    {
      name: 'German',
      id: Languages.GERMAN,
    },
    {
      name: 'French',
      id: Languages.FRENCH,
    },
    {
      name: 'Spanish',
      id: Languages.SPANISH,
    },
    {
      name: 'Portuguese',
      id: Languages.PORTUGUESE,
    },
    {
      name: 'Portuguese Brazil',
      id: Languages.PORTUGUESE_BRAZIL,
    },
    {
      name: 'Greek',
      id: Languages.GREEK,
    },
    {
      name: 'Russian',
      id: Languages.RUSSIAN,
    },
    {
      name: 'Armenian',
      id: Languages.ARMENIAN,
    },
    {
      name: 'Georgian',
      id: Languages.GEORGIAN,
    },
    {
      name: 'Arabic',
      id: Languages.ARABIC,
    },
    {
      name: 'Persian',
      id: Languages.PERSIAN,
    },
    {
      name: 'Turkish',
      id: Languages.TURKISH,
    },
    {
      name: 'China',
      id: Languages.CHINA,
    },
    {
      name: 'Korean',
      id: Languages.KOREAN,
    },
    {
      name: 'Polish',
      id: Languages.POLISH,
    },
    {
      name: 'Romanian',
      id: Languages.ROMANIAN,
    },
    {
      name: 'Ukrainian',
      id: Languages.UKRAINIAN,
    },
    {
      name: 'China Simplified',
      id: Languages.CHINA_SIMPLIFIED,
    },
    {
      name: 'Farsi',
      id: Languages.FARSI,
    },
    {
      name: 'Bulgarian',
      id: Languages.BULGARIAN,
    },
    {
      name: 'Japanese',
      id: Languages.JAPANESE,
    },
    {
      name: 'Azerbaijani',
      id: Languages.AZERBAIJANI,
    },
  ],
  selectedLanguage: 'eng',
} as LanguagesInterface

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguages(state, action: PayloadAction<Array<LanguagesListInterface>>) {
      state.languages = action.payload
    },
    setSelectedLanguage(state, action: PayloadAction<string>) {
      state.selectedLanguage = action.payload
    },
  },
})

export const { setLanguages, setSelectedLanguage } = languagesSlice.actions
export default languagesSlice.reducer
