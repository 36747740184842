import type { ThemeInterface } from '@/store/reducers/models/interfaces/styles.interface'

import { createAppAsyncThunk } from '@/store/thunks/thunkConfig'
import { initAuthorization, setAccessToken } from '@/store/reducers/general'
import {
  convertSettingsToBO,
  convertSportsSettingsFromBackOfficeToStore,
  convertStylesFromBackOfficeToStore,
  convertStylesFromStoreToBackOffice,
  convertThemeFromStoreToBackOffice,
} from '@/widgets/MainWidget/modules/AnimationIframe/components/helpers/convertBackOfficeData'
import { addStyleTheme, removeStyleTheme, setStyles } from '@/store/reducers/styles'
import { changeStatWidgetPosition, setSportsSettings } from '@/store/reducers/settings'

const BACK_OFFICE_BASE_REQUEST_URL = process.env.REACT_APP_BO_API;

export const getNewAccessToken = createAppAsyncThunk(
  'backOffice/getNewAccessToken',
  async (_, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const requestBody = { refresh: general.refreshToken }

    return fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/token/refresh/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.access) {
          const accessToken = response.access
          thunkAPI.dispatch(setAccessToken(accessToken))

          console.log('Success (getNewAccessToken)', response)
          return {
            accessToken,
          }
        }

        console.log('Error (getNewAccessToken)', response)
        return null
      })
      .catch((error) => {
        console.log('Error (getNewAccessToken)', error)
        return null
      })
  },
)

export const getBackOfficeWidgetPosition = createAppAsyncThunk(
  'backOffice/getBackOfficeWidgetPosition',
  async (_, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/widget_positions/`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(getBackOfficeWidgetPosition())
              } else {
                console.log('Error (getBackOfficeWidgetPosition after getNewAccessToken)')
              }
            })
        } else {
          thunkAPI.dispatch(changeStatWidgetPosition(response.position))
          console.log('Success (getBackOfficeWidgetPosition)', response)
        }
      })
      .catch((error) => {
        console.log('Error (getBackOfficeWidgetPosition)', error)
      })
  },
)

export const getBackOfficeWidgetSettings = createAppAsyncThunk(
  'backOffice/getBackOfficeWidgetSettings',
  async (_, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/widgets/`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(getBackOfficeWidgetSettings())
              } else {
                console.log('Error (getBackOfficeWidgetSettings after getNewAccessToken)')
              }
            })
        } else {
          thunkAPI.dispatch(setSportsSettings(convertSportsSettingsFromBackOfficeToStore(response)))
          console.log('Success (getBackOfficeWidgetSettings)', response)
        }
      })
      .catch((error) => {
        console.log('Error (getBackOfficeWidgetSettings)', error)
      })
  },
)

export const getBackOfficeThemes = createAppAsyncThunk(
  'backOffice/getBackOfficeThemes',
  async (_, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/themes/`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(getBackOfficeThemes())
              } else {
                console.log('Error (getBackOfficeThemes after getNewAccessToken)')
              }
            })
        } else {
          thunkAPI.dispatch(setStyles(convertStylesFromBackOfficeToStore(response)))
          console.log('Success (getBackOfficeThemes)', response)
        }
      })
      .catch((error) => {
        console.log('Error (getBackOfficeThemes)', error)
      })
  },
)

export const deleteBackOfficeTheme = createAppAsyncThunk<void, string>(
  'backOffice/deleteBackOfficeThemes',
  async (themeId, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/themes/${themeId}`, {
      headers,
      method: 'DELETE',
    })
      .then((response) => (response.status !== 204 ? response.json() : null))
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(deleteBackOfficeTheme(themeId))
              } else {
                console.log('Error (deleteBackOfficeTheme after getNewAccessToken)')
              }
            })
        } else {
          thunkAPI.dispatch(removeStyleTheme(themeId))
          console.log('Success (deleteBackOfficeTheme)', response)
        }
      })
      .catch((error) => {
        console.log('Error (deleteBackOfficeTheme)', error)
      })
  },
)

export const createBackOfficeTheme = createAppAsyncThunk<void, ThemeInterface>(
  'backOffice/createBackOfficeThemes',
  async (theme, thunkAPI) => {
    const { general } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    const body = JSON.stringify(convertThemeFromStoreToBackOffice(theme))

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/themes/`, {
      headers,
      method: 'POST',
      body,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(createBackOfficeTheme(theme))
              } else {
                console.log('Error (createBackOfficeTheme after getNewAccessToken)')
              }
            })
        } else {
          thunkAPI.dispatch(addStyleTheme({ ...theme, id: response.id }))
          console.log('Success (createBackOfficeTheme)', response)
        }
      })
      .catch((error) => {
        console.log('Error (createBackOfficeTheme)', error)
      })
  },
)

export const saveChangesToBackOffice = createAppAsyncThunk<void, void>(
  'backOffice/saveChangesToBackOffice',
  async (_, thunkAPI) => {
    const { general, styles, settings } = thunkAPI.getState()

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${general.accessToken}`,
    }

    const stylesForBackOffice = convertStylesFromStoreToBackOffice(styles)
    const settingsForBackOffice = convertSettingsToBO(settings)

    const body = JSON.stringify({ styles: stylesForBackOffice, settings: settingsForBackOffice })

    fetch(`${BACK_OFFICE_BASE_REQUEST_URL}/api/v1/animation/settings_update/`, {
      headers,
      method: 'POST',
      body,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.code === 'token_not_valid') {
          thunkAPI
            .dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
              if (result) {
                thunkAPI.dispatch(saveChangesToBackOffice())
              } else {
                console.log('Error (saveChangesToBackOffice after getNewAccessToken)')
              }
            })
        } else {
          console.log('Success (saveChangesToBackOffice)', response)
        }
      })
      .catch((error) => {
        console.log('Error (saveChangesToBackOffice)', error)
      })
  },
)

export const authorize = createAppAsyncThunk('backOffice/authorize', async (_, thunkAPI) => {
  const queryParams = new URLSearchParams(window.location.search)
  const accessToken = queryParams.get('access')
  const refreshToken = queryParams.get('refresh')

  const useAuthorization = !!accessToken && !!refreshToken

  thunkAPI.dispatch(
    initAuthorization({
      accessToken,
      refreshToken,
      useAuthorization,
    }),
  )

  if (useAuthorization) {
    thunkAPI.dispatch(getBackOfficeThemes())
    thunkAPI.dispatch(getBackOfficeWidgetPosition())
    thunkAPI.dispatch(getBackOfficeWidgetSettings())
  }
})
