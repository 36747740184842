import React from "react";

const UncheckedIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="1.25"
        stroke="#848B97"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default UncheckedIcon;
