import React from 'react'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import styles from './styles/DemoMode.module.scss'
import { DemoSportEvents } from './components'

import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

import { fetchEventsListBySport } from '@/store/thunks/demoModeThunks'
import { SectionCaption, SportsListSelect } from 'components/common'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getDemoSportsList } from '@/store/selectors/demoModeSelectors'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { setSport } from '@/store/reducers/general'

const DemoMode: React.FC = () => {
  const dispatch = useAppDispatch()
  const sports = useAppSelector(getDemoSportsList)
  const demoSportType = useAppSelector(getActiveSport)

  const [activeElem, setActiveElem] = React.useState<boolean>(true)

  const [expandedSelect, toggleExpandedSelect] = React.useState<boolean>(false)
  const [activeEventIndex, setActiveEventIndex] = React.useState<number>(0)

  const demoModeRef = React.useRef<HTMLDivElement | null>(null)

  const handleChange = (elem: string) => {
    setActiveEventIndex(0)
    dispatch(fetchEventsListBySport(elem as ActiveSports))
    dispatch(setSport(elem as ActiveSports))
    toggleExpandedSelect(false)
  }

  return (
    <section
      ref={demoModeRef}
      className={cn(styles.demo_mode, {
        [styles.expanded]: activeElem,
      })}
    >
      <SectionCaption
        captionTitle='Demo Mode'
        activeElem={activeElem}
        setActiveElem={setActiveElem}
        captionPosition='left'
      />

      {activeElem && (
        <SportsListSelect
          expandedSelect={expandedSelect}
          toggleExpandedSelect={toggleExpandedSelect}
          sportType={demoSportType}
          handleChange={handleChange}
          sportsList={sports}
          elemRef={demoModeRef.current}
        />
      )}

      {activeElem && (
        <OverlayScrollbarsComponent>
          <DemoSportEvents
            // @ts-ignore
            activeEventIndex={activeEventIndex}
            setActiveEventIndex={setActiveEventIndex}
          />
        </OverlayScrollbarsComponent>
      )}
    </section>
  )
}

export default React.memo(DemoMode)
