import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

import { setDemoSportEvents, setDemoSports } from '@/store/reducers/demoMode'
import { createAppAsyncThunk } from '@/store/thunks/thunkConfig'
import { SportWeight } from '@/config/available-sports'

const demoServerUrl = process.env.REACT_APP_DEMO_SERVER_URL

export const fetchEventsListBySport = createAppAsyncThunk<void, ActiveSports>(
  'demo/fetchEventsListBySport',
  async (sport, thunkAPI) => {
    fetch(`${demoServerUrl}/sport-events?sport=${sport}`)
      .then((response) => response.json())
      .then((response) => {
        if (!!response.error) {
          return;
        }

        thunkAPI.dispatch(setDemoSportEvents(response));
      })
  },
)
export const fetchDemoSports = createAppAsyncThunk<void, void>(
  'demo/fetchDemoSports',
  async (_, thunkAPI) => {
    const { demoMode, general } = thunkAPI.getState()

    fetch(`${demoServerUrl}/sports`)
      .then((response) => response.json())
      .then((response) => {
        const sports = response.sort((a: ActiveSports, b: ActiveSports) => SportWeight[a] - SportWeight[b]);

        thunkAPI.dispatch(setDemoSports(sports))
        thunkAPI.dispatch(fetchEventsListBySport(general.sport || sports[0]))
      })
  },
)

export const fetchActiveEvent = createAppAsyncThunk<void, { eventID: number | string; uuid: string }>(
  'demo/fetchActiveEvent',
  async ({ eventID, uuid }) => {
    const random = Math.random()
    const team = random > 0.5 ? 1 : 2
    fetch(`${demoServerUrl}/emit-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid, type: eventID, team }),
    })
  },
)

export const fetchResetDemo = createAppAsyncThunk<void, string>(
  'demo/fetchResetDemo',
  async (uuid) => {
    fetch(`${demoServerUrl}/reset-demo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid }),
    })
  },
)
