import React from "react";

const EditIcon: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7396 0.484186C10.9399 0.283917 
    11.2646 0.283917 11.4649 0.484186L13.5161 
    2.53547C13.7164 2.73574 13.7164 3.06044 
    13.5161 3.26071L11.4649 5.31199L7.36229 
    9.41455C7.26612 9.51073 7.13568 9.56475
     6.99967 9.56475H4.94839C4.66517 9.56475 
     4.43557 9.33516 4.43557 
     9.05193V7.00065C4.43557 6.86464 4.4896 
     6.73421 4.58577 6.63803L8.68816 
     2.53565C8.68822 2.53559 8.68828 
     2.53553 8.68834 2.53547C8.6884 
     2.53541 8.68846 2.53535 8.68852 
     2.53529L10.7396 0.484186ZM9.05096 
     3.62332L5.46121 7.21307V8.53911H6.78726L10.377 
     4.94937L9.05096 3.62332ZM11.1022 4.22413L9.77619 
     2.89809L11.1022 1.57204L12.4283 2.89809L11.1022 
     4.22413ZM1.52959 2.72715C1.43518 2.72715 1.35865
      2.80368 1.35865 2.89809V12.4707C1.35865 12.5651 
      1.43518 12.6417 1.52959 12.6417H11.1022C11.1966 
      12.6417 11.2732 12.5651 11.2732 
      12.4707V9.05193C11.2732 8.76871 11.5028 8.53911 
      11.786 8.53911C12.0692 8.53911 12.2988 8.76871 
      12.2988 9.05193V12.4707C12.2988 13.1316 11.7631 
      13.6673 11.1022 13.6673H1.52959C0.868736 13.6673 
      0.333008 13.1316 0.333008 12.4707V2.89809C0.333008
       2.23723 0.868735 1.70151 1.52959 1.70151H4.94839C5.23162 
       1.70151 5.46121 1.9311 5.46121 2.21433C5.46121 
       2.49755 5.23162 2.72715 4.94839 2.72715H1.52959Z"
      />
    </svg>
  );
};

export default EditIcon;
