import React from "react";

const PositionDoubleLeftIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}
    >
      <path
        d="M32.8571 8.33398L7.14286 8.33398C6.57454 8.33398 6.02949 8.52309 5.62763 8.85969C5.22576 9.19629 5 9.65283 5 10.1289L5 29.8724C5 30.3485 5.22576 30.805 5.62763 31.1416C6.02949 31.4782 6.57454 31.6673 7.14286 31.6673L32.8571 31.6673C33.4255 31.6673 33.9705 31.4782 34.3724 31.1416C34.7742 30.805 35 30.3485 35 29.8724V10.1289C35 9.65283 34.7742 9.19629 34.3724 8.85969C33.9705 8.52309 33.4255 8.33398 32.8571 8.33398ZM7.14286 10.1289L24.2857 10.1289L24.2857 29.8724L7.14286 29.8724L7.14286 10.1289ZM32.8571 29.8724H26.4286L26.4286 10.1289H32.8571V29.8724Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default PositionDoubleLeftIcon;
