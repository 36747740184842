import React from 'react'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import { LanguageCaptionIcon, StyleCaptionIcon } from '../elements'
import MainCaptionIcon from '../elements/MainCaptionIcon'

import sStyles from './styles/Settings.module.scss'
import { Statistics, Header, Timeline } from './components'
import LanguageSelect from './components/LanguageSelect'
import Themes from './components/Themes'
import { SelectedElements } from './models/enums/selected-elements.enum'
import { CreateThemePopup, EditThemePopup } from './components/Themes/components/elements'

import { getStyles } from '@/store/selectors/stylesSelectors'
import { SectionCaption } from 'components/common'
import { useAppSelector } from '@/store/store'
import DeleteThemePopup
  from '@/widgets/OptionsWidget/modules/Settings/components/Themes/components/elements/DeleteThemePopup'

const Settings: React.FC = () => {
  const styles = useAppSelector(getStyles)

  const [activeElem, setActiveElem] = React.useState<boolean>(false)
  const [activeSettings, setActiveSettings] = React.useState<boolean>(true)
  const [activeStyle, setActiveStyle] = React.useState<boolean>(false)
  const [activeLanguage, setActiveLanguage] = React.useState<boolean>(false)
  const [activeCreateThemePopup, setActiveCreateThemePopup] = React.useState<boolean>(false)
  const [activeEditThemePopup, setActiveEditThemePopup] = React.useState<boolean>(false)
  const [deleteId, setDeleteId] = React.useState<string | null>(null)

  const settingsRef = React.useRef<HTMLElement>(null)

  const displaySelectedElement =
    (active: string): (() => void) =>
      () => {
        setActiveLanguage(active === SelectedElements.LANGUAGE)
        setActiveStyle(active === SelectedElements.STYLE)
        setActiveSettings(active === SelectedElements.SETTINGS)
      }

  return (
    <section
      ref={settingsRef}
      className={cn(sStyles.options__section, {
        [sStyles.expanded]: activeElem,
      })}
    >
      {activeCreateThemePopup ? (
        // @ts-ignore
        <CreateThemePopup setActiveCreateThemePopup={setActiveCreateThemePopup} />
      ) : null}
      {activeEditThemePopup ? (
        // @ts-ignore
        <EditThemePopup setActiveEditThemePopup={setActiveEditThemePopup} />
      ) : null}

      {deleteId ? (
        // @ts-ignore
        <DeleteThemePopup setDeleteId={setDeleteId} id={deleteId} />
      ) : null}

      <SectionCaption
        captionTitle='Settings'
        activeElem={activeElem}
        setActiveElem={setActiveElem}
        captionPosition='right'
      />
      {activeElem ? (
        <div className={cn(sStyles.wrapper)}>
          <div
            onClick={displaySelectedElement(SelectedElements.SETTINGS)}
            className={activeSettings ? cn(sStyles.iconSettingsActive) : cn(sStyles.iconStyle)}
          >
            <MainCaptionIcon />
          </div>
          <div
            onClick={displaySelectedElement(SelectedElements.STYLE)}
            className={activeStyle ? cn(sStyles.iconStyleActive) : cn(sStyles.iconStyle)}
          >
            <StyleCaptionIcon />
          </div>
          <div
            onClick={displaySelectedElement(SelectedElements.LANGUAGE)}
            className={activeLanguage ? cn(sStyles.iconLanguageActive) : cn(sStyles.iconStyle)}
          >
            <LanguageCaptionIcon />
          </div>
        </div>
      ) : null}
      {activeElem ? (
        <>
          {activeStyle ? (
            <Themes
              styles={styles}
              setActiveCreateThemePopup={setActiveCreateThemePopup}
              setActiveEditThemePopup={setActiveEditThemePopup}
              setActiveDeleteThemePopup={setDeleteId}
            />
          ) : null}
          {activeLanguage ? <LanguageSelect /> : null}
          {activeSettings ? (
            <OverlayScrollbarsComponent>
              <div className={sStyles.mainSettingsWrapper}>
                {/* <Animation /> */}
                <Statistics />
                <Header />
                <Timeline />
              </div>
            </OverlayScrollbarsComponent>
          ) : null}
        </>
      ) : null}
    </section>
  )
}
export default React.memo(Settings)
