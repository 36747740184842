import React from 'react'
import cn from 'classnames'

import popupStyles from './styles/DeleteThemePopup.module.scss'
import { TrashIcon, WarningIcon } from './elements'

import { getStylesThemes } from 'store/selectors/stylesSelectors'
import { CloseIcon } from 'components/common'
import { useAppDispatch, useAppSelector } from 'store/store'
import { deleteBackOfficeTheme } from '@/store/thunks/backOfficeThunks'
import { getUseAuthorization } from '@/store/selectors/generalSelectors'
import { removeStyleTheme } from '@/store/reducers/styles'

type PropsType = {
  setDeleteId: (value: string | null) => void
  id: string
}

const DeleteThemePopup: React.FC<PropsType> = ({ setDeleteId, id }) => {
  const dispatch = useAppDispatch()

  const themes = useAppSelector(getStylesThemes)
  const useAuthorization = useAppSelector(getUseAuthorization)

  const handlePopupClose = () => {
    setDeleteId(null)
  }

  React.useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlePopupClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const removeTheme = (objId: string) => {
    if (themes.length <= 1) {
      return false
    }

    dispatch(useAuthorization ? deleteBackOfficeTheme(objId) : removeStyleTheme(objId))

    handlePopupClose()
  }

  return (
    <div className={cn(popupStyles.popup)}>
      <div className={cn(popupStyles.popup__content)}>
        <div className={cn(popupStyles.popup__header)}>
          <h2 className={cn(popupStyles.title)}>Edit Theme</h2>
          <span onClick={handlePopupClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={cn(popupStyles.popup__body)}>
          <WarningIcon />
          <span>{`If you delete this Theme ${
            themes.find((item) => item.id === id)?.name
          }, you won't be able to recover it. Do you want to delete it?`}</span>
        </div>
        <div className={cn(popupStyles.popup__footer)}>
          <button className={cn(popupStyles.remove)} onClick={() => removeTheme(id)}>
            <TrashIcon />
            <span>Delete Theme</span>
          </button>
          <button className={cn(popupStyles.cancel)} onClick={handlePopupClose}>
            Cancel
          </button>
        </div>
      </div>
      <div className={cn(popupStyles.popup__backdrop)} onClick={handlePopupClose} />
    </div>
  )
}
export default React.memo(DeleteThemePopup)
