// source: commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var match_pb = require("./match_pb.js");
goog.object.extend(proto, match_pb);
var odds_pb = require("./odds_pb.js");
goog.object.extend(proto, odds_pb);
var running_ball_pb = require("./running_ball_pb.js");
goog.object.extend(proto, running_ball_pb);
goog.exportSymbol("proto.Error", null, global);
goog.exportSymbol("proto.FeedType", null, global);
goog.exportSymbol("proto.GetProviders", null, global);
goog.exportSymbol("proto.GetSnapshot", null, global);
goog.exportSymbol("proto.GetSports", null, global);
goog.exportSymbol("proto.MatchType", null, global);
goog.exportSymbol("proto.Meta", null, global);
goog.exportSymbol("proto.Object", null, global);
goog.exportSymbol("proto.Object.ArgCase", null, global);
goog.exportSymbol("proto.Ping", null, global);
goog.exportSymbol("proto.ProviderStatus", null, global);
goog.exportSymbol("proto.ProviderStatus.Status", null, global);
goog.exportSymbol("proto.Providers", null, global);
goog.exportSymbol("proto.Request", null, global);
goog.exportSymbol("proto.Request.ArgCase", null, global);
goog.exportSymbol("proto.Response", null, global);
goog.exportSymbol("proto.Response.ArgCase", null, global);
goog.exportSymbol("proto.Response.Event", null, global);
goog.exportSymbol("proto.Sports", null, global);
goog.exportSymbol("proto.SubMatch", null, global);
goog.exportSymbol("proto.SubOk", null, global);
goog.exportSymbol("proto.SubProvider", null, global);
goog.exportSymbol("proto.SubSport", null, global);
goog.exportSymbol("proto.UnsubAll", null, global);
goog.exportSymbol("proto.UnsubMatch", null, global);
goog.exportSymbol("proto.UnsubOk", null, global);
goog.exportSymbol("proto.UnsubProvider", null, global);
goog.exportSymbol("proto.UnsubSport", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Meta = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Meta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Meta.displayName = "proto.Meta";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Request = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Request.oneofGroups_);
};
goog.inherits(proto.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Request.displayName = "proto.Request";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Response = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Response.oneofGroups_);
};
goog.inherits(proto.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Response.displayName = "proto.Response";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Object = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Object.oneofGroups_);
};
goog.inherits(proto.Object, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Object.displayName = "proto.Object";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Ping = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Ping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Ping.displayName = "proto.Ping";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubSport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubSport.repeatedFields_, null);
};
goog.inherits(proto.SubSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubSport.displayName = "proto.SubSport";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubProvider = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SubProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubProvider.displayName = "proto.SubProvider";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubMatch = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SubMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubMatch.displayName = "proto.SubMatch";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProviderStatus = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProviderStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProviderStatus.displayName = "proto.ProviderStatus";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnsubAll = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnsubAll, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnsubAll.displayName = "proto.UnsubAll";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubOk = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SubOk.repeatedFields_, null);
};
goog.inherits(proto.SubOk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubOk.displayName = "proto.SubOk";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnsubOk = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnsubOk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnsubOk.displayName = "proto.UnsubOk";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnsubSport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnsubSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnsubSport.displayName = "proto.UnsubSport";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnsubProvider = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnsubProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnsubProvider.displayName = "proto.UnsubProvider";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnsubMatch = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnsubMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnsubMatch.displayName = "proto.UnsubMatch";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSports = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetSports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSports.displayName = "proto.GetSports";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetProviders = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetProviders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetProviders.displayName = "proto.GetProviders";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetSnapshot = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetSnapshot.displayName = "proto.GetSnapshot";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Error = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Error.displayName = "proto.Error";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Sports = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Sports.repeatedFields_, null);
};
goog.inherits(proto.Sports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Sports.displayName = "proto.Sports";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Providers = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Providers.repeatedFields_, null);
};
goog.inherits(proto.Providers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Providers.displayName = "proto.Providers";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Meta.prototype.toObject = function (opt_includeInstance) {
    return proto.Meta.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Meta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Meta.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ts: jspb.Message.getFieldWithDefault(msg, 1, 0),
        requestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Meta}
 */
proto.Meta.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Meta();
  return proto.Meta.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Meta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Meta}
 */
proto.Meta.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setRequestId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Meta.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Meta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Meta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Meta.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};

/**
 * optional int64 ts = 1;
 * @return {number}
 */
proto.Meta.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.Meta} returns this
 */
proto.Meta.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 request_id = 2;
 * @return {number}
 */
proto.Meta.prototype.getRequestId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Meta} returns this
 */
proto.Meta.prototype.setRequestId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Request.oneofGroups_ = [[2, 3, 4, 5, 7, 8, 9, 10, 11, 14, 15]];

/**
 * @enum {number}
 */
proto.Request.ArgCase = {
  ARG_NOT_SET: 0,
  PING: 2,
  SUBSPORT: 3,
  SUBMATCH: 4,
  UNSUBALL: 5,
  UNSUBMATCH: 7,
  UNSUBSPORT: 8,
  GETSPORTS: 9,
  SUBPROVIDER: 10,
  UNSUBPROVIDER: 11,
  GETPROVIDERS: 14,
  GETSNAPSHOT: 15,
};

/**
 * @return {proto.Request.ArgCase}
 */
proto.Request.prototype.getArgCase = function () {
  return /** @type {proto.Request.ArgCase} */ (
    jspb.Message.computeOneofCase(this, proto.Request.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Request.prototype.toObject = function (opt_includeInstance) {
    return proto.Request.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Request} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Request.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        meta: (f = msg.getMeta()) && proto.Meta.toObject(includeInstance, f),
        ping: (f = msg.getPing()) && proto.Ping.toObject(includeInstance, f),
        subsport: (f = msg.getSubsport()) && proto.SubSport.toObject(includeInstance, f),
        submatch: (f = msg.getSubmatch()) && proto.SubMatch.toObject(includeInstance, f),
        unsuball: (f = msg.getUnsuball()) && proto.UnsubAll.toObject(includeInstance, f),
        unsubmatch: (f = msg.getUnsubmatch()) && proto.UnsubMatch.toObject(includeInstance, f),
        unsubsport: (f = msg.getUnsubsport()) && proto.UnsubSport.toObject(includeInstance, f),
        getsports: (f = msg.getGetsports()) && proto.GetSports.toObject(includeInstance, f),
        subprovider: (f = msg.getSubprovider()) && proto.SubProvider.toObject(includeInstance, f),
        unsubprovider:
          (f = msg.getUnsubprovider()) && proto.UnsubProvider.toObject(includeInstance, f),
        getproviders:
          (f = msg.getGetproviders()) && proto.GetProviders.toObject(includeInstance, f),
        getsnapshot: (f = msg.getGetsnapshot()) && proto.GetSnapshot.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Request}
 */
proto.Request.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Request();
  return proto.Request.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Request}
 */
proto.Request.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.Meta();
        reader.readMessage(value, proto.Meta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      case 2:
        var value = new proto.Ping();
        reader.readMessage(value, proto.Ping.deserializeBinaryFromReader);
        msg.setPing(value);
        break;
      case 3:
        var value = new proto.SubSport();
        reader.readMessage(value, proto.SubSport.deserializeBinaryFromReader);
        msg.setSubsport(value);
        break;
      case 4:
        var value = new proto.SubMatch();
        reader.readMessage(value, proto.SubMatch.deserializeBinaryFromReader);
        msg.setSubmatch(value);
        break;
      case 5:
        var value = new proto.UnsubAll();
        reader.readMessage(value, proto.UnsubAll.deserializeBinaryFromReader);
        msg.setUnsuball(value);
        break;
      case 7:
        var value = new proto.UnsubMatch();
        reader.readMessage(value, proto.UnsubMatch.deserializeBinaryFromReader);
        msg.setUnsubmatch(value);
        break;
      case 8:
        var value = new proto.UnsubSport();
        reader.readMessage(value, proto.UnsubSport.deserializeBinaryFromReader);
        msg.setUnsubsport(value);
        break;
      case 9:
        var value = new proto.GetSports();
        reader.readMessage(value, proto.GetSports.deserializeBinaryFromReader);
        msg.setGetsports(value);
        break;
      case 10:
        var value = new proto.SubProvider();
        reader.readMessage(value, proto.SubProvider.deserializeBinaryFromReader);
        msg.setSubprovider(value);
        break;
      case 11:
        var value = new proto.UnsubProvider();
        reader.readMessage(value, proto.UnsubProvider.deserializeBinaryFromReader);
        msg.setUnsubprovider(value);
        break;
      case 14:
        var value = new proto.GetProviders();
        reader.readMessage(value, proto.GetProviders.deserializeBinaryFromReader);
        msg.setGetproviders(value);
        break;
      case 15:
        var value = new proto.GetSnapshot();
        reader.readMessage(value, proto.GetSnapshot.deserializeBinaryFromReader);
        msg.setGetsnapshot(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Request.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Request.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(1, f, proto.Meta.serializeBinaryToWriter);
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(2, f, proto.Ping.serializeBinaryToWriter);
  }
  f = message.getSubsport();
  if (f != null) {
    writer.writeMessage(3, f, proto.SubSport.serializeBinaryToWriter);
  }
  f = message.getSubmatch();
  if (f != null) {
    writer.writeMessage(4, f, proto.SubMatch.serializeBinaryToWriter);
  }
  f = message.getUnsuball();
  if (f != null) {
    writer.writeMessage(5, f, proto.UnsubAll.serializeBinaryToWriter);
  }
  f = message.getUnsubmatch();
  if (f != null) {
    writer.writeMessage(7, f, proto.UnsubMatch.serializeBinaryToWriter);
  }
  f = message.getUnsubsport();
  if (f != null) {
    writer.writeMessage(8, f, proto.UnsubSport.serializeBinaryToWriter);
  }
  f = message.getGetsports();
  if (f != null) {
    writer.writeMessage(9, f, proto.GetSports.serializeBinaryToWriter);
  }
  f = message.getSubprovider();
  if (f != null) {
    writer.writeMessage(10, f, proto.SubProvider.serializeBinaryToWriter);
  }
  f = message.getUnsubprovider();
  if (f != null) {
    writer.writeMessage(11, f, proto.UnsubProvider.serializeBinaryToWriter);
  }
  f = message.getGetproviders();
  if (f != null) {
    writer.writeMessage(14, f, proto.GetProviders.serializeBinaryToWriter);
  }
  f = message.getGetsnapshot();
  if (f != null) {
    writer.writeMessage(15, f, proto.GetSnapshot.serializeBinaryToWriter);
  }
};

/**
 * optional Meta meta = 1;
 * @return {?proto.Meta}
 */
proto.Request.prototype.getMeta = function () {
  return /** @type{?proto.Meta} */ (jspb.Message.getWrapperField(this, proto.Meta, 1));
};

/**
 * @param {?proto.Meta|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setMeta = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Ping ping = 2;
 * @return {?proto.Ping}
 */
proto.Request.prototype.getPing = function () {
  return /** @type{?proto.Ping} */ (jspb.Message.getWrapperField(this, proto.Ping, 2));
};

/**
 * @param {?proto.Ping|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setPing = function (value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearPing = function () {
  return this.setPing(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasPing = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional SubSport subsport = 3;
 * @return {?proto.SubSport}
 */
proto.Request.prototype.getSubsport = function () {
  return /** @type{?proto.SubSport} */ (jspb.Message.getWrapperField(this, proto.SubSport, 3));
};

/**
 * @param {?proto.SubSport|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setSubsport = function (value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearSubsport = function () {
  return this.setSubsport(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasSubsport = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional SubMatch submatch = 4;
 * @return {?proto.SubMatch}
 */
proto.Request.prototype.getSubmatch = function () {
  return /** @type{?proto.SubMatch} */ (jspb.Message.getWrapperField(this, proto.SubMatch, 4));
};

/**
 * @param {?proto.SubMatch|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setSubmatch = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearSubmatch = function () {
  return this.setSubmatch(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasSubmatch = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional UnsubAll unsuball = 5;
 * @return {?proto.UnsubAll}
 */
proto.Request.prototype.getUnsuball = function () {
  return /** @type{?proto.UnsubAll} */ (jspb.Message.getWrapperField(this, proto.UnsubAll, 5));
};

/**
 * @param {?proto.UnsubAll|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setUnsuball = function (value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearUnsuball = function () {
  return this.setUnsuball(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasUnsuball = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional UnsubMatch unsubmatch = 7;
 * @return {?proto.UnsubMatch}
 */
proto.Request.prototype.getUnsubmatch = function () {
  return /** @type{?proto.UnsubMatch} */ (jspb.Message.getWrapperField(this, proto.UnsubMatch, 7));
};

/**
 * @param {?proto.UnsubMatch|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setUnsubmatch = function (value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearUnsubmatch = function () {
  return this.setUnsubmatch(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasUnsubmatch = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional UnsubSport unsubsport = 8;
 * @return {?proto.UnsubSport}
 */
proto.Request.prototype.getUnsubsport = function () {
  return /** @type{?proto.UnsubSport} */ (jspb.Message.getWrapperField(this, proto.UnsubSport, 8));
};

/**
 * @param {?proto.UnsubSport|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setUnsubsport = function (value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearUnsubsport = function () {
  return this.setUnsubsport(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasUnsubsport = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional GetSports getsports = 9;
 * @return {?proto.GetSports}
 */
proto.Request.prototype.getGetsports = function () {
  return /** @type{?proto.GetSports} */ (jspb.Message.getWrapperField(this, proto.GetSports, 9));
};

/**
 * @param {?proto.GetSports|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setGetsports = function (value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearGetsports = function () {
  return this.setGetsports(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasGetsports = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional SubProvider subprovider = 10;
 * @return {?proto.SubProvider}
 */
proto.Request.prototype.getSubprovider = function () {
  return /** @type{?proto.SubProvider} */ (
    jspb.Message.getWrapperField(this, proto.SubProvider, 10)
  );
};

/**
 * @param {?proto.SubProvider|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setSubprovider = function (value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearSubprovider = function () {
  return this.setSubprovider(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasSubprovider = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional UnsubProvider unsubprovider = 11;
 * @return {?proto.UnsubProvider}
 */
proto.Request.prototype.getUnsubprovider = function () {
  return /** @type{?proto.UnsubProvider} */ (
    jspb.Message.getWrapperField(this, proto.UnsubProvider, 11)
  );
};

/**
 * @param {?proto.UnsubProvider|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setUnsubprovider = function (value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearUnsubprovider = function () {
  return this.setUnsubprovider(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasUnsubprovider = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional GetProviders getproviders = 14;
 * @return {?proto.GetProviders}
 */
proto.Request.prototype.getGetproviders = function () {
  return /** @type{?proto.GetProviders} */ (
    jspb.Message.getWrapperField(this, proto.GetProviders, 14)
  );
};

/**
 * @param {?proto.GetProviders|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setGetproviders = function (value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearGetproviders = function () {
  return this.setGetproviders(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasGetproviders = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional GetSnapshot getsnapshot = 15;
 * @return {?proto.GetSnapshot}
 */
proto.Request.prototype.getGetsnapshot = function () {
  return /** @type{?proto.GetSnapshot} */ (
    jspb.Message.getWrapperField(this, proto.GetSnapshot, 15)
  );
};

/**
 * @param {?proto.GetSnapshot|undefined} value
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.setGetsnapshot = function (value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Request.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Request} returns this
 */
proto.Request.prototype.clearGetsnapshot = function () {
  return this.setGetsnapshot(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Request.prototype.hasGetsnapshot = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Response.oneofGroups_ = [[3, 4, 17, 5, 11, 6, 7, 8, 12, 13, 14, 15, 18, 19, 20, 23, 24, 22]];

/**
 * @enum {number}
 */
proto.Response.ArgCase = {
  ARG_NOT_SET: 0,
  PING_OK: 3,
  UNSUB_OK: 4,
  SUB_OK: 17,
  ERROR: 5,
  SPORT: 11,
  MATCH: 6,
  MARKET: 7,
  SELECTION: 8,
  TIMER: 12,
  STAT: 13,
  GAME_EVENT: 14,
  SPORTS: 15,
  PROPERTY: 18,
  STAT_LOG: 19,
  PROVIDER_STATUS: 20,
  MATCH_RESULT: 23,
  RESULT: 24,
  PROVIDERS: 22,
};

/**
 * @return {proto.Response.ArgCase}
 */
proto.Response.prototype.getArgCase = function () {
  return /** @type {proto.Response.ArgCase} */ (
    jspb.Message.computeOneofCase(this, proto.Response.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Response.prototype.toObject = function (opt_includeInstance) {
    return proto.Response.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Response} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Response.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        meta: (f = msg.getMeta()) && proto.Meta.toObject(includeInstance, f),
        event: jspb.Message.getFieldWithDefault(msg, 2, 0),
        pingOk: (f = msg.getPingOk()) && proto.Ping.toObject(includeInstance, f),
        unsubOk: (f = msg.getUnsubOk()) && proto.UnsubOk.toObject(includeInstance, f),
        subOk: (f = msg.getSubOk()) && proto.SubOk.toObject(includeInstance, f),
        error: (f = msg.getError()) && proto.Error.toObject(includeInstance, f),
        sport: jspb.Message.getFieldWithDefault(msg, 11, ""),
        match: (f = msg.getMatch()) && match_pb.Match.toObject(includeInstance, f),
        market: (f = msg.getMarket()) && odds_pb.Market.toObject(includeInstance, f),
        selection: (f = msg.getSelection()) && odds_pb.Selection.toObject(includeInstance, f),
        timer: (f = msg.getTimer()) && running_ball_pb.Timer.toObject(includeInstance, f),
        stat: (f = msg.getStat()) && running_ball_pb.StatItem.toObject(includeInstance, f),
        gameEvent:
          (f = msg.getGameEvent()) && running_ball_pb.GameEvent.toObject(includeInstance, f),
        sports: (f = msg.getSports()) && proto.Sports.toObject(includeInstance, f),
        property: (f = msg.getProperty()) && odds_pb.Property.toObject(includeInstance, f),
        statLog: (f = msg.getStatLog()) && running_ball_pb.StatLog.toObject(includeInstance, f),
        providerStatus:
          (f = msg.getProviderStatus()) && proto.ProviderStatus.toObject(includeInstance, f),
        matchResult: (f = msg.getMatchResult()) && odds_pb.MatchResult.toObject(includeInstance, f),
        result: (f = msg.getResult()) && odds_pb.Result.toObject(includeInstance, f),
        providers: (f = msg.getProviders()) && proto.Providers.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Response}
 */
proto.Response.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Response();
  return proto.Response.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Response}
 */
proto.Response.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.Meta();
        reader.readMessage(value, proto.Meta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      case 2:
        var value = /** @type {!proto.Response.Event} */ (reader.readEnum());
        msg.setEvent(value);
        break;
      case 3:
        var value = new proto.Ping();
        reader.readMessage(value, proto.Ping.deserializeBinaryFromReader);
        msg.setPingOk(value);
        break;
      case 4:
        var value = new proto.UnsubOk();
        reader.readMessage(value, proto.UnsubOk.deserializeBinaryFromReader);
        msg.setUnsubOk(value);
        break;
      case 17:
        var value = new proto.SubOk();
        reader.readMessage(value, proto.SubOk.deserializeBinaryFromReader);
        msg.setSubOk(value);
        break;
      case 5:
        var value = new proto.Error();
        reader.readMessage(value, proto.Error.deserializeBinaryFromReader);
        msg.setError(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 6:
        var value = new match_pb.Match();
        reader.readMessage(value, match_pb.Match.deserializeBinaryFromReader);
        msg.setMatch(value);
        break;
      case 7:
        var value = new odds_pb.Market();
        reader.readMessage(value, odds_pb.Market.deserializeBinaryFromReader);
        msg.setMarket(value);
        break;
      case 8:
        var value = new odds_pb.Selection();
        reader.readMessage(value, odds_pb.Selection.deserializeBinaryFromReader);
        msg.setSelection(value);
        break;
      case 12:
        var value = new running_ball_pb.Timer();
        reader.readMessage(value, running_ball_pb.Timer.deserializeBinaryFromReader);
        msg.setTimer(value);
        break;
      case 13:
        var value = new running_ball_pb.StatItem();
        reader.readMessage(value, running_ball_pb.StatItem.deserializeBinaryFromReader);
        msg.setStat(value);
        break;
      case 14:
        var value = new running_ball_pb.GameEvent();
        reader.readMessage(value, running_ball_pb.GameEvent.deserializeBinaryFromReader);
        msg.setGameEvent(value);
        break;
      case 15:
        var value = new proto.Sports();
        reader.readMessage(value, proto.Sports.deserializeBinaryFromReader);
        msg.setSports(value);
        break;
      case 18:
        var value = new odds_pb.Property();
        reader.readMessage(value, odds_pb.Property.deserializeBinaryFromReader);
        msg.setProperty(value);
        break;
      case 19:
        var value = new running_ball_pb.StatLog();
        reader.readMessage(value, running_ball_pb.StatLog.deserializeBinaryFromReader);
        msg.setStatLog(value);
        break;
      case 20:
        var value = new proto.ProviderStatus();
        reader.readMessage(value, proto.ProviderStatus.deserializeBinaryFromReader);
        msg.setProviderStatus(value);
        break;
      case 23:
        var value = new odds_pb.MatchResult();
        reader.readMessage(value, odds_pb.MatchResult.deserializeBinaryFromReader);
        msg.setMatchResult(value);
        break;
      case 24:
        var value = new odds_pb.Result();
        reader.readMessage(value, odds_pb.Result.deserializeBinaryFromReader);
        msg.setResult(value);
        break;
      case 22:
        var value = new proto.Providers();
        reader.readMessage(value, proto.Providers.deserializeBinaryFromReader);
        msg.setProviders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Response.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Response.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(1, f, proto.Meta.serializeBinaryToWriter);
  }
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getPingOk();
  if (f != null) {
    writer.writeMessage(3, f, proto.Ping.serializeBinaryToWriter);
  }
  f = message.getUnsubOk();
  if (f != null) {
    writer.writeMessage(4, f, proto.UnsubOk.serializeBinaryToWriter);
  }
  f = message.getSubOk();
  if (f != null) {
    writer.writeMessage(17, f, proto.SubOk.serializeBinaryToWriter);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(5, f, proto.Error.serializeBinaryToWriter);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(11, f);
  }
  f = message.getMatch();
  if (f != null) {
    writer.writeMessage(6, f, match_pb.Match.serializeBinaryToWriter);
  }
  f = message.getMarket();
  if (f != null) {
    writer.writeMessage(7, f, odds_pb.Market.serializeBinaryToWriter);
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(8, f, odds_pb.Selection.serializeBinaryToWriter);
  }
  f = message.getTimer();
  if (f != null) {
    writer.writeMessage(12, f, running_ball_pb.Timer.serializeBinaryToWriter);
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(13, f, running_ball_pb.StatItem.serializeBinaryToWriter);
  }
  f = message.getGameEvent();
  if (f != null) {
    writer.writeMessage(14, f, running_ball_pb.GameEvent.serializeBinaryToWriter);
  }
  f = message.getSports();
  if (f != null) {
    writer.writeMessage(15, f, proto.Sports.serializeBinaryToWriter);
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(18, f, odds_pb.Property.serializeBinaryToWriter);
  }
  f = message.getStatLog();
  if (f != null) {
    writer.writeMessage(19, f, running_ball_pb.StatLog.serializeBinaryToWriter);
  }
  f = message.getProviderStatus();
  if (f != null) {
    writer.writeMessage(20, f, proto.ProviderStatus.serializeBinaryToWriter);
  }
  f = message.getMatchResult();
  if (f != null) {
    writer.writeMessage(23, f, odds_pb.MatchResult.serializeBinaryToWriter);
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(24, f, odds_pb.Result.serializeBinaryToWriter);
  }
  f = message.getProviders();
  if (f != null) {
    writer.writeMessage(22, f, proto.Providers.serializeBinaryToWriter);
  }
};

/**
 * @enum {number}
 */
proto.Response.Event = {
  UPDATED: 0,
  PATCHED: 1,
  DELETED: 2,
};

/**
 * optional Meta meta = 1;
 * @return {?proto.Meta}
 */
proto.Response.prototype.getMeta = function () {
  return /** @type{?proto.Meta} */ (jspb.Message.getWrapperField(this, proto.Meta, 1));
};

/**
 * @param {?proto.Meta|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setMeta = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Event event = 2;
 * @return {!proto.Response.Event}
 */
proto.Response.prototype.getEvent = function () {
  return /** @type {!proto.Response.Event} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.Response.Event} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setEvent = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional Ping ping_ok = 3;
 * @return {?proto.Ping}
 */
proto.Response.prototype.getPingOk = function () {
  return /** @type{?proto.Ping} */ (jspb.Message.getWrapperField(this, proto.Ping, 3));
};

/**
 * @param {?proto.Ping|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setPingOk = function (value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearPingOk = function () {
  return this.setPingOk(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasPingOk = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional UnsubOk unsub_ok = 4;
 * @return {?proto.UnsubOk}
 */
proto.Response.prototype.getUnsubOk = function () {
  return /** @type{?proto.UnsubOk} */ (jspb.Message.getWrapperField(this, proto.UnsubOk, 4));
};

/**
 * @param {?proto.UnsubOk|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setUnsubOk = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearUnsubOk = function () {
  return this.setUnsubOk(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasUnsubOk = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional SubOk sub_ok = 17;
 * @return {?proto.SubOk}
 */
proto.Response.prototype.getSubOk = function () {
  return /** @type{?proto.SubOk} */ (jspb.Message.getWrapperField(this, proto.SubOk, 17));
};

/**
 * @param {?proto.SubOk|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setSubOk = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearSubOk = function () {
  return this.setSubOk(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasSubOk = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional Error error = 5;
 * @return {?proto.Error}
 */
proto.Response.prototype.getError = function () {
  return /** @type{?proto.Error} */ (jspb.Message.getWrapperField(this, proto.Error, 5));
};

/**
 * @param {?proto.Error|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setError = function (value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearError = function () {
  return this.setError(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasError = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string sport = 11;
 * @return {string}
 */
proto.Response.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/**
 * @param {string} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setSport = function (value) {
  return jspb.Message.setOneofField(this, 11, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearSport = function () {
  return jspb.Message.setOneofField(this, 11, proto.Response.oneofGroups_[0], undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasSport = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional Match match = 6;
 * @return {?proto.Match}
 */
proto.Response.prototype.getMatch = function () {
  return /** @type{?proto.Match} */ (jspb.Message.getWrapperField(this, match_pb.Match, 6));
};

/**
 * @param {?proto.Match|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setMatch = function (value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearMatch = function () {
  return this.setMatch(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasMatch = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional Market market = 7;
 * @return {?proto.Market}
 */
proto.Response.prototype.getMarket = function () {
  return /** @type{?proto.Market} */ (jspb.Message.getWrapperField(this, odds_pb.Market, 7));
};

/**
 * @param {?proto.Market|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setMarket = function (value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearMarket = function () {
  return this.setMarket(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasMarket = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional Selection selection = 8;
 * @return {?proto.Selection}
 */
proto.Response.prototype.getSelection = function () {
  return /** @type{?proto.Selection} */ (jspb.Message.getWrapperField(this, odds_pb.Selection, 8));
};

/**
 * @param {?proto.Selection|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setSelection = function (value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearSelection = function () {
  return this.setSelection(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasSelection = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional Timer timer = 12;
 * @return {?proto.Timer}
 */
proto.Response.prototype.getTimer = function () {
  return /** @type{?proto.Timer} */ (jspb.Message.getWrapperField(this, running_ball_pb.Timer, 12));
};

/**
 * @param {?proto.Timer|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setTimer = function (value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearTimer = function () {
  return this.setTimer(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasTimer = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional StatItem stat = 13;
 * @return {?proto.StatItem}
 */
proto.Response.prototype.getStat = function () {
  return /** @type{?proto.StatItem} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.StatItem, 13)
  );
};

/**
 * @param {?proto.StatItem|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setStat = function (value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearStat = function () {
  return this.setStat(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasStat = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional GameEvent game_event = 14;
 * @return {?proto.GameEvent}
 */
proto.Response.prototype.getGameEvent = function () {
  return /** @type{?proto.GameEvent} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.GameEvent, 14)
  );
};

/**
 * @param {?proto.GameEvent|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setGameEvent = function (value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearGameEvent = function () {
  return this.setGameEvent(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasGameEvent = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional Sports sports = 15;
 * @return {?proto.Sports}
 */
proto.Response.prototype.getSports = function () {
  return /** @type{?proto.Sports} */ (jspb.Message.getWrapperField(this, proto.Sports, 15));
};

/**
 * @param {?proto.Sports|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setSports = function (value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearSports = function () {
  return this.setSports(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasSports = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional Property property = 18;
 * @return {?proto.Property}
 */
proto.Response.prototype.getProperty = function () {
  return /** @type{?proto.Property} */ (jspb.Message.getWrapperField(this, odds_pb.Property, 18));
};

/**
 * @param {?proto.Property|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setProperty = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearProperty = function () {
  return this.setProperty(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasProperty = function () {
  return jspb.Message.getField(this, 18) != null;
};

/**
 * optional StatLog stat_log = 19;
 * @return {?proto.StatLog}
 */
proto.Response.prototype.getStatLog = function () {
  return /** @type{?proto.StatLog} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.StatLog, 19)
  );
};

/**
 * @param {?proto.StatLog|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setStatLog = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearStatLog = function () {
  return this.setStatLog(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasStatLog = function () {
  return jspb.Message.getField(this, 19) != null;
};

/**
 * optional ProviderStatus provider_status = 20;
 * @return {?proto.ProviderStatus}
 */
proto.Response.prototype.getProviderStatus = function () {
  return /** @type{?proto.ProviderStatus} */ (
    jspb.Message.getWrapperField(this, proto.ProviderStatus, 20)
  );
};

/**
 * @param {?proto.ProviderStatus|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setProviderStatus = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearProviderStatus = function () {
  return this.setProviderStatus(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasProviderStatus = function () {
  return jspb.Message.getField(this, 20) != null;
};

/**
 * optional MatchResult match_result = 23;
 * @return {?proto.MatchResult}
 */
proto.Response.prototype.getMatchResult = function () {
  return /** @type{?proto.MatchResult} */ (
    jspb.Message.getWrapperField(this, odds_pb.MatchResult, 23)
  );
};

/**
 * @param {?proto.MatchResult|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setMatchResult = function (value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearMatchResult = function () {
  return this.setMatchResult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasMatchResult = function () {
  return jspb.Message.getField(this, 23) != null;
};

/**
 * optional Result result = 24;
 * @return {?proto.Result}
 */
proto.Response.prototype.getResult = function () {
  return /** @type{?proto.Result} */ (jspb.Message.getWrapperField(this, odds_pb.Result, 24));
};

/**
 * @param {?proto.Result|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setResult = function (value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearResult = function () {
  return this.setResult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasResult = function () {
  return jspb.Message.getField(this, 24) != null;
};

/**
 * optional Providers providers = 22;
 * @return {?proto.Providers}
 */
proto.Response.prototype.getProviders = function () {
  return /** @type{?proto.Providers} */ (jspb.Message.getWrapperField(this, proto.Providers, 22));
};

/**
 * @param {?proto.Providers|undefined} value
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.setProviders = function (value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.Response.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Response} returns this
 */
proto.Response.prototype.clearProviders = function () {
  return this.setProviders(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Response.prototype.hasProviders = function () {
  return jspb.Message.getField(this, 22) != null;
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Object.oneofGroups_ = [[1, 5, 6, 7, 8, 12, 13, 14, 15, 16, 17, 19, 20]];

/**
 * @enum {number}
 */
proto.Object.ArgCase = {
  ARG_NOT_SET: 0,
  META: 1,
  ERROR: 5,
  MATCH: 6,
  MARKET: 7,
  SELECTION: 8,
  TIMER: 12,
  STAT: 13,
  GAME_EVENT: 14,
  PROPERTY: 15,
  PROVIDER_STATUS: 16,
  STAT_LOG: 17,
  MATCH_RESULT: 19,
  RESULT: 20,
};

/**
 * @return {proto.Object.ArgCase}
 */
proto.Object.prototype.getArgCase = function () {
  return /** @type {proto.Object.ArgCase} */ (
    jspb.Message.computeOneofCase(this, proto.Object.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Object.prototype.toObject = function (opt_includeInstance) {
    return proto.Object.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Object} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Object.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        meta: (f = msg.getMeta()) && proto.Meta.toObject(includeInstance, f),
        error: (f = msg.getError()) && proto.Error.toObject(includeInstance, f),
        match: (f = msg.getMatch()) && match_pb.Match.toObject(includeInstance, f),
        market: (f = msg.getMarket()) && odds_pb.Market.toObject(includeInstance, f),
        selection: (f = msg.getSelection()) && odds_pb.Selection.toObject(includeInstance, f),
        timer: (f = msg.getTimer()) && running_ball_pb.Timer.toObject(includeInstance, f),
        stat: (f = msg.getStat()) && running_ball_pb.StatItem.toObject(includeInstance, f),
        gameEvent:
          (f = msg.getGameEvent()) && running_ball_pb.GameEvent.toObject(includeInstance, f),
        property: (f = msg.getProperty()) && odds_pb.Property.toObject(includeInstance, f),
        providerStatus:
          (f = msg.getProviderStatus()) && proto.ProviderStatus.toObject(includeInstance, f),
        statLog: (f = msg.getStatLog()) && running_ball_pb.StatLog.toObject(includeInstance, f),
        matchResult: (f = msg.getMatchResult()) && odds_pb.MatchResult.toObject(includeInstance, f),
        result: (f = msg.getResult()) && odds_pb.Result.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Object}
 */
proto.Object.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Object();
  return proto.Object.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Object} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Object}
 */
proto.Object.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.Meta();
        reader.readMessage(value, proto.Meta.deserializeBinaryFromReader);
        msg.setMeta(value);
        break;
      case 5:
        var value = new proto.Error();
        reader.readMessage(value, proto.Error.deserializeBinaryFromReader);
        msg.setError(value);
        break;
      case 6:
        var value = new match_pb.Match();
        reader.readMessage(value, match_pb.Match.deserializeBinaryFromReader);
        msg.setMatch(value);
        break;
      case 7:
        var value = new odds_pb.Market();
        reader.readMessage(value, odds_pb.Market.deserializeBinaryFromReader);
        msg.setMarket(value);
        break;
      case 8:
        var value = new odds_pb.Selection();
        reader.readMessage(value, odds_pb.Selection.deserializeBinaryFromReader);
        msg.setSelection(value);
        break;
      case 12:
        var value = new running_ball_pb.Timer();
        reader.readMessage(value, running_ball_pb.Timer.deserializeBinaryFromReader);
        msg.setTimer(value);
        break;
      case 13:
        var value = new running_ball_pb.StatItem();
        reader.readMessage(value, running_ball_pb.StatItem.deserializeBinaryFromReader);
        msg.setStat(value);
        break;
      case 14:
        var value = new running_ball_pb.GameEvent();
        reader.readMessage(value, running_ball_pb.GameEvent.deserializeBinaryFromReader);
        msg.setGameEvent(value);
        break;
      case 15:
        var value = new odds_pb.Property();
        reader.readMessage(value, odds_pb.Property.deserializeBinaryFromReader);
        msg.setProperty(value);
        break;
      case 16:
        var value = new proto.ProviderStatus();
        reader.readMessage(value, proto.ProviderStatus.deserializeBinaryFromReader);
        msg.setProviderStatus(value);
        break;
      case 17:
        var value = new running_ball_pb.StatLog();
        reader.readMessage(value, running_ball_pb.StatLog.deserializeBinaryFromReader);
        msg.setStatLog(value);
        break;
      case 19:
        var value = new odds_pb.MatchResult();
        reader.readMessage(value, odds_pb.MatchResult.deserializeBinaryFromReader);
        msg.setMatchResult(value);
        break;
      case 20:
        var value = new odds_pb.Result();
        reader.readMessage(value, odds_pb.Result.deserializeBinaryFromReader);
        msg.setResult(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Object.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Object.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Object} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Object.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(1, f, proto.Meta.serializeBinaryToWriter);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(5, f, proto.Error.serializeBinaryToWriter);
  }
  f = message.getMatch();
  if (f != null) {
    writer.writeMessage(6, f, match_pb.Match.serializeBinaryToWriter);
  }
  f = message.getMarket();
  if (f != null) {
    writer.writeMessage(7, f, odds_pb.Market.serializeBinaryToWriter);
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(8, f, odds_pb.Selection.serializeBinaryToWriter);
  }
  f = message.getTimer();
  if (f != null) {
    writer.writeMessage(12, f, running_ball_pb.Timer.serializeBinaryToWriter);
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(13, f, running_ball_pb.StatItem.serializeBinaryToWriter);
  }
  f = message.getGameEvent();
  if (f != null) {
    writer.writeMessage(14, f, running_ball_pb.GameEvent.serializeBinaryToWriter);
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(15, f, odds_pb.Property.serializeBinaryToWriter);
  }
  f = message.getProviderStatus();
  if (f != null) {
    writer.writeMessage(16, f, proto.ProviderStatus.serializeBinaryToWriter);
  }
  f = message.getStatLog();
  if (f != null) {
    writer.writeMessage(17, f, running_ball_pb.StatLog.serializeBinaryToWriter);
  }
  f = message.getMatchResult();
  if (f != null) {
    writer.writeMessage(19, f, odds_pb.MatchResult.serializeBinaryToWriter);
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(20, f, odds_pb.Result.serializeBinaryToWriter);
  }
};

/**
 * optional Meta meta = 1;
 * @return {?proto.Meta}
 */
proto.Object.prototype.getMeta = function () {
  return /** @type{?proto.Meta} */ (jspb.Message.getWrapperField(this, proto.Meta, 1));
};

/**
 * @param {?proto.Meta|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setMeta = function (value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearMeta = function () {
  return this.setMeta(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasMeta = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Error error = 5;
 * @return {?proto.Error}
 */
proto.Object.prototype.getError = function () {
  return /** @type{?proto.Error} */ (jspb.Message.getWrapperField(this, proto.Error, 5));
};

/**
 * @param {?proto.Error|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setError = function (value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearError = function () {
  return this.setError(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasError = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional Match match = 6;
 * @return {?proto.Match}
 */
proto.Object.prototype.getMatch = function () {
  return /** @type{?proto.Match} */ (jspb.Message.getWrapperField(this, match_pb.Match, 6));
};

/**
 * @param {?proto.Match|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setMatch = function (value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearMatch = function () {
  return this.setMatch(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasMatch = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional Market market = 7;
 * @return {?proto.Market}
 */
proto.Object.prototype.getMarket = function () {
  return /** @type{?proto.Market} */ (jspb.Message.getWrapperField(this, odds_pb.Market, 7));
};

/**
 * @param {?proto.Market|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setMarket = function (value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearMarket = function () {
  return this.setMarket(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasMarket = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional Selection selection = 8;
 * @return {?proto.Selection}
 */
proto.Object.prototype.getSelection = function () {
  return /** @type{?proto.Selection} */ (jspb.Message.getWrapperField(this, odds_pb.Selection, 8));
};

/**
 * @param {?proto.Selection|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setSelection = function (value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearSelection = function () {
  return this.setSelection(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasSelection = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional Timer timer = 12;
 * @return {?proto.Timer}
 */
proto.Object.prototype.getTimer = function () {
  return /** @type{?proto.Timer} */ (jspb.Message.getWrapperField(this, running_ball_pb.Timer, 12));
};

/**
 * @param {?proto.Timer|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setTimer = function (value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearTimer = function () {
  return this.setTimer(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasTimer = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional StatItem stat = 13;
 * @return {?proto.StatItem}
 */
proto.Object.prototype.getStat = function () {
  return /** @type{?proto.StatItem} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.StatItem, 13)
  );
};

/**
 * @param {?proto.StatItem|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setStat = function (value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearStat = function () {
  return this.setStat(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasStat = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional GameEvent game_event = 14;
 * @return {?proto.GameEvent}
 */
proto.Object.prototype.getGameEvent = function () {
  return /** @type{?proto.GameEvent} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.GameEvent, 14)
  );
};

/**
 * @param {?proto.GameEvent|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setGameEvent = function (value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearGameEvent = function () {
  return this.setGameEvent(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasGameEvent = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional Property property = 15;
 * @return {?proto.Property}
 */
proto.Object.prototype.getProperty = function () {
  return /** @type{?proto.Property} */ (jspb.Message.getWrapperField(this, odds_pb.Property, 15));
};

/**
 * @param {?proto.Property|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setProperty = function (value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearProperty = function () {
  return this.setProperty(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasProperty = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional ProviderStatus provider_status = 16;
 * @return {?proto.ProviderStatus}
 */
proto.Object.prototype.getProviderStatus = function () {
  return /** @type{?proto.ProviderStatus} */ (
    jspb.Message.getWrapperField(this, proto.ProviderStatus, 16)
  );
};

/**
 * @param {?proto.ProviderStatus|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setProviderStatus = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearProviderStatus = function () {
  return this.setProviderStatus(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasProviderStatus = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional StatLog stat_log = 17;
 * @return {?proto.StatLog}
 */
proto.Object.prototype.getStatLog = function () {
  return /** @type{?proto.StatLog} */ (
    jspb.Message.getWrapperField(this, running_ball_pb.StatLog, 17)
  );
};

/**
 * @param {?proto.StatLog|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setStatLog = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearStatLog = function () {
  return this.setStatLog(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasStatLog = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional MatchResult match_result = 19;
 * @return {?proto.MatchResult}
 */
proto.Object.prototype.getMatchResult = function () {
  return /** @type{?proto.MatchResult} */ (
    jspb.Message.getWrapperField(this, odds_pb.MatchResult, 19)
  );
};

/**
 * @param {?proto.MatchResult|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setMatchResult = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearMatchResult = function () {
  return this.setMatchResult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasMatchResult = function () {
  return jspb.Message.getField(this, 19) != null;
};

/**
 * optional Result result = 20;
 * @return {?proto.Result}
 */
proto.Object.prototype.getResult = function () {
  return /** @type{?proto.Result} */ (jspb.Message.getWrapperField(this, odds_pb.Result, 20));
};

/**
 * @param {?proto.Result|undefined} value
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.setResult = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.Object.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Object} returns this
 */
proto.Object.prototype.clearResult = function () {
  return this.setResult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Object.prototype.hasResult = function () {
  return jspb.Message.getField(this, 20) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Ping.prototype.toObject = function (opt_includeInstance) {
    return proto.Ping.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Ping} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Ping.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Ping}
 */
proto.Ping.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Ping();
  return proto.Ping.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Ping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Ping}
 */
proto.Ping.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Ping.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Ping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Ping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ping.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubSport.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubSport.prototype.toObject = function (opt_includeInstance) {
    return proto.SubSport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubSport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubSport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
        sport: jspb.Message.getFieldWithDefault(msg, 1, ""),
        odds: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        marketTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubSport}
 */
proto.SubSport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubSport();
  return proto.SubSport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubSport}
 */
proto.SubSport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setOdds(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.addMarketTypes(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubSport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubSport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getOdds();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getMarketTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(7, f);
  }
};

/**
 * optional string provider = 3;
 * @return {string}
 */
proto.SubSport.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.SubSport.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional string sport = 1;
 * @return {string}
 */
proto.SubSport.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool odds = 6;
 * @return {boolean}
 */
proto.SubSport.prototype.getOdds = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.setOdds = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * repeated string market_types = 7;
 * @return {!Array<string>}
 */
proto.SubSport.prototype.getMarketTypesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.setMarketTypesList = function (value) {
  return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.addMarketTypes = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubSport} returns this
 */
proto.SubSport.prototype.clearMarketTypesList = function () {
  return this.setMarketTypesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubProvider.prototype.toObject = function (opt_includeInstance) {
    return proto.SubProvider.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubProvider} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubProvider.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
        matchType: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubProvider}
 */
proto.SubProvider.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubProvider();
  return proto.SubProvider.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubProvider}
 */
proto.SubProvider.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 3:
        var value = /** @type {!proto.MatchType} */ (reader.readEnum());
        msg.setMatchType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubProvider.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubProvider.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * optional string provider = 1;
 * @return {string}
 */
proto.SubProvider.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubProvider} returns this
 */
proto.SubProvider.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.SubProvider.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.SubProvider} returns this
 */
proto.SubProvider.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional MatchType match_type = 3;
 * @return {!proto.MatchType}
 */
proto.SubProvider.prototype.getMatchType = function () {
  return /** @type {!proto.MatchType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.MatchType} value
 * @return {!proto.SubProvider} returns this
 */
proto.SubProvider.prototype.setMatchType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubMatch.prototype.toObject = function (opt_includeInstance) {
    return proto.SubMatch.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubMatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubMatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 6, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 4, 0),
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        linkId: jspb.Message.getFieldWithDefault(msg, 5, ""),
        snapshot: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
        odds: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        gameevents: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        fullMarket: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
        resetMargin: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubMatch}
 */
proto.SubMatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubMatch();
  return proto.SubMatch.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubMatch}
 */
proto.SubMatch.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 4:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setLinkId(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSnapshot(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setOdds(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setGameevents(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setFullMarket(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setResetMargin(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubMatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubMatch.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getLinkId();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getSnapshot();
  if (f) {
    writer.writeBool(9, f);
  }
  f = message.getOdds();
  if (f) {
    writer.writeBool(2, f);
  }
  f = message.getGameevents();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getFullMarket();
  if (f) {
    writer.writeBool(7, f);
  }
  f = message.getResetMargin();
  if (f) {
    writer.writeBool(8, f);
  }
};

/**
 * optional string provider = 6;
 * @return {string}
 */
proto.SubMatch.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional FeedType feed_type = 4;
 * @return {!proto.FeedType}
 */
proto.SubMatch.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.SubMatch.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string link_id = 5;
 * @return {string}
 */
proto.SubMatch.prototype.getLinkId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setLinkId = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool snapshot = 9;
 * @return {boolean}
 */
proto.SubMatch.prototype.getSnapshot = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setSnapshot = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};

/**
 * optional bool odds = 2;
 * @return {boolean}
 */
proto.SubMatch.prototype.getOdds = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setOdds = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool gameevents = 3;
 * @return {boolean}
 */
proto.SubMatch.prototype.getGameevents = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setGameevents = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool full_market = 7;
 * @return {boolean}
 */
proto.SubMatch.prototype.getFullMarket = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setFullMarket = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional bool reset_margin = 8;
 * @return {boolean}
 */
proto.SubMatch.prototype.getResetMargin = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};

/**
 * @param {boolean} value
 * @return {!proto.SubMatch} returns this
 */
proto.SubMatch.prototype.setResetMargin = function (value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ProviderStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.ProviderStatus.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ProviderStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ProviderStatus.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
        status: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProviderStatus}
 */
proto.ProviderStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProviderStatus();
  return proto.ProviderStatus.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProviderStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProviderStatus}
 */
proto.ProviderStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 3:
        var value = /** @type {!proto.ProviderStatus.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProviderStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ProviderStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProviderStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProviderStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * @enum {number}
 */
proto.ProviderStatus.Status = {
  NOTFOUND: 0,
  FAILED: 1,
  ACTIVE: 2,
};

/**
 * optional string provider = 1;
 * @return {string}
 */
proto.ProviderStatus.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.ProviderStatus} returns this
 */
proto.ProviderStatus.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.ProviderStatus.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.ProviderStatus} returns this
 */
proto.ProviderStatus.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional Status status = 3;
 * @return {!proto.ProviderStatus.Status}
 */
proto.ProviderStatus.prototype.getStatus = function () {
  return /** @type {!proto.ProviderStatus.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.ProviderStatus.Status} value
 * @return {!proto.ProviderStatus} returns this
 */
proto.ProviderStatus.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnsubAll.prototype.toObject = function (opt_includeInstance) {
    return proto.UnsubAll.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnsubAll} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UnsubAll.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnsubAll}
 */
proto.UnsubAll.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnsubAll();
  return proto.UnsubAll.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnsubAll} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnsubAll}
 */
proto.UnsubAll.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnsubAll.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnsubAll.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnsubAll} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnsubAll.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubOk.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubOk.prototype.toObject = function (opt_includeInstance) {
    return proto.SubOk.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubOk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubOk.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        stateList: jspb.Message.toObjectList(
          msg.getStateList(),
          proto.Object.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubOk}
 */
proto.SubOk.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubOk();
  return proto.SubOk.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubOk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubOk}
 */
proto.SubOk.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.Object();
        reader.readMessage(value, proto.Object.deserializeBinaryFromReader);
        msg.addState(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubOk.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubOk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubOk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubOk.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.Object.serializeBinaryToWriter);
  }
};

/**
 * repeated Object state = 1;
 * @return {!Array<!proto.Object>}
 */
proto.SubOk.prototype.getStateList = function () {
  return /** @type{!Array<!proto.Object>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Object, 1)
  );
};

/**
 * @param {!Array<!proto.Object>} value
 * @return {!proto.SubOk} returns this
 */
proto.SubOk.prototype.setStateList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.Object=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Object}
 */
proto.SubOk.prototype.addState = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Object, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubOk} returns this
 */
proto.SubOk.prototype.clearStateList = function () {
  return this.setStateList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnsubOk.prototype.toObject = function (opt_includeInstance) {
    return proto.UnsubOk.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnsubOk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UnsubOk.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnsubOk}
 */
proto.UnsubOk.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnsubOk();
  return proto.UnsubOk.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnsubOk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnsubOk}
 */
proto.UnsubOk.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnsubOk.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnsubOk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnsubOk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnsubOk.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnsubSport.prototype.toObject = function (opt_includeInstance) {
    return proto.UnsubSport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnsubSport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UnsubSport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sport: jspb.Message.getFieldWithDefault(msg, 1, ""),
        provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnsubSport}
 */
proto.UnsubSport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnsubSport();
  return proto.UnsubSport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnsubSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnsubSport}
 */
proto.UnsubSport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnsubSport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnsubSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnsubSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnsubSport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional string sport = 1;
 * @return {string}
 */
proto.UnsubSport.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubSport} returns this
 */
proto.UnsubSport.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string provider = 3;
 * @return {string}
 */
proto.UnsubSport.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubSport} returns this
 */
proto.UnsubSport.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.UnsubSport.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.UnsubSport} returns this
 */
proto.UnsubSport.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnsubProvider.prototype.toObject = function (opt_includeInstance) {
    return proto.UnsubProvider.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnsubProvider} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UnsubProvider.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
        matchType: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnsubProvider}
 */
proto.UnsubProvider.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnsubProvider();
  return proto.UnsubProvider.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnsubProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnsubProvider}
 */
proto.UnsubProvider.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 3:
        var value = /** @type {!proto.MatchType} */ (reader.readEnum());
        msg.setMatchType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnsubProvider.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnsubProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnsubProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnsubProvider.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getMatchType();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * optional string provider = 1;
 * @return {string}
 */
proto.UnsubProvider.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubProvider} returns this
 */
proto.UnsubProvider.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.UnsubProvider.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.UnsubProvider} returns this
 */
proto.UnsubProvider.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional MatchType match_type = 3;
 * @return {!proto.MatchType}
 */
proto.UnsubProvider.prototype.getMatchType = function () {
  return /** @type {!proto.MatchType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.MatchType} value
 * @return {!proto.UnsubProvider} returns this
 */
proto.UnsubProvider.prototype.setMatchType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnsubMatch.prototype.toObject = function (opt_includeInstance) {
    return proto.UnsubMatch.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnsubMatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UnsubMatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ""),
        linkId: jspb.Message.getFieldWithDefault(msg, 3, ""),
        provider: jspb.Message.getFieldWithDefault(msg, 4, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnsubMatch}
 */
proto.UnsubMatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnsubMatch();
  return proto.UnsubMatch.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnsubMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnsubMatch}
 */
proto.UnsubMatch.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setLinkId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnsubMatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnsubMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnsubMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnsubMatch.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getLinkId();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.UnsubMatch.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubMatch} returns this
 */
proto.UnsubMatch.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string link_id = 3;
 * @return {string}
 */
proto.UnsubMatch.prototype.getLinkId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubMatch} returns this
 */
proto.UnsubMatch.prototype.setLinkId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string provider = 4;
 * @return {string}
 */
proto.UnsubMatch.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.UnsubMatch} returns this
 */
proto.UnsubMatch.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.UnsubMatch.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.UnsubMatch} returns this
 */
proto.UnsubMatch.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetSports.prototype.toObject = function (opt_includeInstance) {
    return proto.GetSports.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetSports} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetSports.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSports}
 */
proto.GetSports.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSports();
  return proto.GetSports.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSports}
 */
proto.GetSports.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 2:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSports.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetSports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSports.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional string provider = 1;
 * @return {string}
 */
proto.GetSports.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.GetSports} returns this
 */
proto.GetSports.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional FeedType feed_type = 2;
 * @return {!proto.FeedType}
 */
proto.GetSports.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.GetSports} returns this
 */
proto.GetSports.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetProviders.prototype.toObject = function (opt_includeInstance) {
    return proto.GetProviders.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetProviders} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetProviders.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        feedType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetProviders}
 */
proto.GetProviders.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetProviders();
  return proto.GetProviders.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetProviders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetProviders}
 */
proto.GetProviders.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetProviders.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetProviders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetProviders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetProviders.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};

/**
 * optional FeedType feed_type = 1;
 * @return {!proto.FeedType}
 */
proto.GetProviders.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.GetProviders} returns this
 */
proto.GetProviders.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetSnapshot.prototype.toObject = function (opt_includeInstance) {
    return proto.GetSnapshot.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetSnapshot} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetSnapshot.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        provider: jspb.Message.getFieldWithDefault(msg, 2, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 3, 0),
        id: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetSnapshot}
 */
proto.GetSnapshot.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetSnapshot();
  return proto.GetSnapshot.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetSnapshot}
 */
proto.GetSnapshot.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setRequestId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 3:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetSnapshot.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetSnapshot.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.GetSnapshot.prototype.getRequestId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetSnapshot} returns this
 */
proto.GetSnapshot.prototype.setRequestId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string provider = 2;
 * @return {string}
 */
proto.GetSnapshot.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.GetSnapshot} returns this
 */
proto.GetSnapshot.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional FeedType feed_type = 3;
 * @return {!proto.FeedType}
 */
proto.GetSnapshot.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.GetSnapshot} returns this
 */
proto.GetSnapshot.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional string id = 4;
 * @return {string}
 */
proto.GetSnapshot.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.GetSnapshot} returns this
 */
proto.GetSnapshot.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Error.prototype.toObject = function (opt_includeInstance) {
    return proto.Error.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Error} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Error.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        desc: jspb.Message.getFieldWithDefault(msg, 1, ""),
        provider: jspb.Message.getFieldWithDefault(msg, 2, ""),
        feedType: jspb.Message.getFieldWithDefault(msg, 3, 0),
        matchId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Error}
 */
proto.Error.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Error();
  return proto.Error.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Error}
 */
proto.Error.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDesc(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setProvider(value);
        break;
      case 3:
        var value = /** @type {!proto.FeedType} */ (reader.readEnum());
        msg.setFeedType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setMatchId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Error.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Error.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getFeedType();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string desc = 1;
 * @return {string}
 */
proto.Error.prototype.getDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.Error} returns this
 */
proto.Error.prototype.setDesc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string provider = 2;
 * @return {string}
 */
proto.Error.prototype.getProvider = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.Error} returns this
 */
proto.Error.prototype.setProvider = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional FeedType feed_type = 3;
 * @return {!proto.FeedType}
 */
proto.Error.prototype.getFeedType = function () {
  return /** @type {!proto.FeedType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.FeedType} value
 * @return {!proto.Error} returns this
 */
proto.Error.prototype.setFeedType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional string match_id = 4;
 * @return {string}
 */
proto.Error.prototype.getMatchId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Error} returns this
 */
proto.Error.prototype.setMatchId = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Sports.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Sports.prototype.toObject = function (opt_includeInstance) {
    return proto.Sports.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Sports} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Sports.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Sports}
 */
proto.Sports.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Sports();
  return proto.Sports.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Sports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Sports}
 */
proto.Sports.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addSports(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Sports.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Sports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Sports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Sports.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string sports = 1;
 * @return {!Array<string>}
 */
proto.Sports.prototype.getSportsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.Sports} returns this
 */
proto.Sports.prototype.setSportsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Sports} returns this
 */
proto.Sports.prototype.addSports = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Sports} returns this
 */
proto.Sports.prototype.clearSportsList = function () {
  return this.setSportsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Providers.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Providers.prototype.toObject = function (opt_includeInstance) {
    return proto.Providers.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Providers} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Providers.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        providersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Providers}
 */
proto.Providers.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Providers();
  return proto.Providers.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Providers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Providers}
 */
proto.Providers.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addProviders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Providers.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Providers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Providers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Providers.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};

/**
 * repeated string providers = 1;
 * @return {!Array<string>}
 */
proto.Providers.prototype.getProvidersList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.Providers} returns this
 */
proto.Providers.prototype.setProvidersList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Providers} returns this
 */
proto.Providers.prototype.addProviders = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Providers} returns this
 */
proto.Providers.prototype.clearProvidersList = function () {
  return this.setProvidersList([]);
};

/**
 * @enum {number}
 */
proto.FeedType = {
  LIVE: 0,
  PREMATCH: 1,
};

/**
 * @enum {number}
 */
proto.MatchType = {
  REGULAR: 0,
  OUTRIGHTS: 1,
};

goog.object.extend(exports, proto);
