import React from 'react'
import cn from 'classnames'

import aStyles from './styles/AnimationResolution.module.scss'
import { RangeBubbleIcon } from './elements'

import { getWidgetMinWidth, getWidgetWidth } from '@/store/selectors/generalSelectors'
import { setMinWidth, setWidth } from '@/store/reducers/general'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { getStatisticsWidgetPosition } from '@/store/selectors/settingsSelectors'
import { SettingsPosition } from '@/store/reducers/models/enums/settings.enum'
import { WidgetMinWidth } from '@/store/reducers/models/enums/general.enum'

const AnimationResolution: React.FC = () => {
  const dispatch = useAppDispatch()
  const width = useAppSelector(getWidgetWidth)
  const minWidth = useAppSelector(getWidgetMinWidth)
  const position = useAppSelector(getStatisticsWidgetPosition)

  const rangeRef = React.useRef<HTMLInputElement>(null)
  const labelRef = React.useRef<HTMLLabelElement>(null)

  function setBubble(range: typeof rangeRef, bubble: typeof labelRef) {
    const val = range!.current!.value
    const { min } = range!.current!
    const { max } = range!.current!
    const newVal = Number(((+val - +min) * 100) / (+max - +min))
    const minVal = `calc(${newVal}% + (${10 - newVal * 0.17}px))`
    const midlVal = `calc(${newVal}% + (${8 - newVal * 0.17}px))`
    const maxVal = `calc(${newVal}% + (${7 - newVal * 0.17}px))`

    bubble!.current!.style.left =
      newVal < 35 ? minVal : newVal > 35 && newVal < 60 ? midlVal : maxVal
  }

  const getAnimationWidth = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = rangeRef!.current!
      dispatch(setWidth(+value))
      setBubble(rangeRef, labelRef)
    },
    [dispatch],
  )

  React.useEffect(() => {
    setBubble(rangeRef, labelRef)
  }, [getAnimationWidth, minWidth])

  React.useEffect(() => {
    if (position === SettingsPosition.DOUBLE_RIGHT || position === SettingsPosition.DOUBLE_LEFT) {
      dispatch(setMinWidth(WidgetMinWidth.LARGE))
    } else {
      dispatch(setMinWidth(WidgetMinWidth.SMALL))
    }
  }, [dispatch, position])

  return (
    <div className={cn(aStyles.range_slider_container)}>
      <span>min {minWidth} px</span>
      <div className={cn(aStyles.range_slider)}>
        <input
          type='range'
          id='range'
          min={minWidth}
          max='1000'
          value={width}
          onInput={getAnimationWidth}
          ref={rangeRef}
        />
        <label ref={labelRef} htmlFor='range'>
          <span>{width}</span>
          <RangeBubbleIcon />
        </label>
      </div>
      <span>max 1000 px</span>
    </div>
  )
}
export default React.memo(AnimationResolution)
