export enum WidgetMode {
  LIVE = 'live',
  DEMO = 'demo',
}

export enum ActiveSports {
  FOOTBALL = 'football',
  BASKETBALL = 'basketball',
  TENNIS = 'tennis',
  VOLLEYBALL = 'volleyball',
  AMERICAN_FOOTBALL = 'american_football',
  HANDBALL = 'handball',
  DARTS = 'darts',
  ICE_HOCKEY = 'ice_hockey',
  DOTA_2 = 'dota_2',
  LOL = 'league_of_legends',
  BASEBALL = 'baseball',
  WATER_POLO = 'water_polo',
  RUGBY_UNION = 'rugby_union',
  RUGBY_LEAGUE = 'rugby_league',
  CRICKET = 'cricket',
  TABLE_TENNIS = 'table_tennis',
}

export enum WidgetMinWidth {
  SMALL = 250,
  LARGE = 500,
}
