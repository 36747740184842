import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  SettingsInterface,
  SportsInterface,
  SportsWidgetInterface,
  WidgetItemInterface,
} from '@/store/reducers/models/interfaces/settings.interface'
import type {
  TimelineType,
  HeaderType,
  AllStatisticsTypes,
} from '@/store/reducers/models/types/settings.type'

import { ActiveSports } from '@/store/reducers/models/enums/general.enum'
import {
  AmericanFootballStatisticsWidgets,
  BasketballStatisticsWidgets,
  FootballStatisticsWidgets,
  HandballStatisticsWidgets,
  HeaderWidgets,
  IceHockeyStatisticsWidgets,
  SettingsPosition,
  TennisStatisticsWidgets,
  VolleyballStatisticsWidgets,
  Dota2StatisticsWidgets,
  OtherWidgets,
  DartsStatisticsWidgets,
  BaseballStatisticsWidgets,
  WaterPoloStatisticsWidgets,
  RugbyStatisticsWidgets,
  CricketStatisticsWidgets,
  TableTennisStatisticsWidgets,
  LoLStatisticsWidgets,
} from '@/store/reducers/models/enums/settings.enum'

const initialState: SettingsInterface = {
  position: SettingsPosition.DOUBLE_BOTTOM,
  sports: {
    [ActiveSports.FOOTBALL]: {
      statistics: convertSportValueToItem(Object.values(FootballStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.BASKETBALL]: {
      statistics: convertSportValueToItem(Object.values(BasketballStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.TENNIS]: {
      statistics: convertSportValueToItem(Object.values(TennisStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.VOLLEYBALL]: {
      statistics: convertSportValueToItem(Object.values(VolleyballStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.AMERICAN_FOOTBALL]: {
      statistics: convertSportValueToItem(
        Object.values(AmericanFootballStatisticsWidgets),
        'statistic',
      ),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.HANDBALL]: {
      statistics: convertSportValueToItem(Object.values(HandballStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.ICE_HOCKEY]: {
      statistics: convertSportValueToItem(Object.values(IceHockeyStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.DARTS]: {
      statistics: convertSportValueToItem(Object.values(DartsStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.DOTA_2]: {
      statistics: convertSportValueToItem(Object.values(Dota2StatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.LOL]: {
      statistics: convertSportValueToItem(Object.values(LoLStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.BASEBALL]: {
      statistics: convertSportValueToItem(Object.values(BaseballStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.WATER_POLO]: {
      statistics: convertSportValueToItem(Object.values(WaterPoloStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.RUGBY_LEAGUE]: {
      statistics: convertSportValueToItem(Object.values(RugbyStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.RUGBY_UNION]: {
      statistics: convertSportValueToItem(Object.values(RugbyStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([OtherWidgets.TIMER], 'other'),
    },
    [ActiveSports.CRICKET]: {
      statistics: convertSportValueToItem(Object.values(CricketStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
    [ActiveSports.TABLE_TENNIS]: {
      statistics: convertSportValueToItem(Object.values(TableTennisStatisticsWidgets), 'statistic'),
      header: convertSportValueToItem(Object.values(HeaderWidgets), 'header'),
      other: convertSportValueToItem([], 'other'),
    },
  },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state, action: PayloadAction<SettingsInterface>) {
      const { position, sports } = action.payload
      state.position = position
      state.sports = sports
    },
    setSportsSettings(state, action: PayloadAction<SportsWidgetInterface>) {
      state.sports = action.payload
    },
    // TODO rename
    changeStatWidgetPosition(state, action: PayloadAction<SettingsPosition>) {
      state.position = action.payload
    },
    toggleEventStatTypes(
      state,
      action: PayloadAction<{ statistics: WidgetItemInterface[]; sport: ActiveSports }>,
    ) {
      const { sport, statistics } = action.payload
      state.sports[sport].statistics = statistics
    },
    toggleEventHeaderTypes(
      state,
      action: PayloadAction<{ header: WidgetItemInterface[]; sport: ActiveSports }>,
    ) {
      const { sport, header } = action.payload
      state.sports[sport].header = header
    },
    toggleEventOther(
      state,
      action: PayloadAction<{ other: WidgetItemInterface[]; sport: ActiveSports }>,
    ) {
      const { sport, other } = action.payload
      state.sports[sport].other = other
    },
  },
})
export const {
  setSettings,
  setSportsSettings,
  changeStatWidgetPosition,
  toggleEventStatTypes,
  toggleEventHeaderTypes,
  toggleEventOther,
} = settingsSlice.actions

export default settingsSlice.reducer

function convertSportValueToItem(values: string[], group: string): WidgetItemInterface[] {
  return values.map((v) => ({
    value: true,
    group,
    key: v,
    name: v,
  }))
}
