import React, { useEffect, useLayoutEffect, useRef } from 'react'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import styles from './styles/SportTypeEvent.module.scss'

import type { RefObject } from 'react'
import type {
  LiveModeMatchesInterface,
  TournamentsInterface,
} from '@/store/reducers/models/interfaces/liveMode/liveModeMatches.interface'

import { Preloader, SportsArrowDropDownIcon } from '@/components/common'
import { useAppDispatch } from '@/store/store'
import { setSelectedMatch } from '@/store/reducers/liveMode'

const SportTypeEvent: React.FC<PropsType> = ({
  liveMatches,
  tournaments,
  activeEventName,
  setActiveEventName,
  selectedMatch,
}) => {
  const dispatch = useAppDispatch()

  const showLiveMatchesList = Object.values(liveMatches).length

  const activeMatchRef: RefObject<HTMLDivElement> = useRef(null)
  const sportTypeScrollRef: RefObject<OverlayScrollbarsComponent> = useRef(null)
  const scrollCountRef = useRef(0)

  const getSelectedMatchIndex = (selectedMatchId: string): string | undefined => Object.keys(tournaments).find(name => tournaments[name].includes(selectedMatchId));

  useEffect(() => {
    if (selectedMatch) {
      setActiveEventName(getSelectedMatchIndex(selectedMatch))
    }
  }, [selectedMatch, tournaments])

  useLayoutEffect(() => {
    const sportTypeScroll = sportTypeScrollRef.current?.osInstance()
    window.setTimeout(() => {
      if (sportTypeScroll && activeMatchRef.current && scrollCountRef.current === 0) {
        const isFirstMatchInList =
          activeMatchRef.current.parentElement?.children[1] === activeMatchRef.current
        scrollCountRef.current = 1

        if (isFirstMatchInList) {
          sportTypeScroll.scroll(
            {
              el: activeMatchRef.current.parentElement,
            },
            200,
          )
        } else {
          sportTypeScroll.scroll(
            {
              el: activeMatchRef.current,
            },
            200,
          )
        }
      }
    }, 1500)
  }, [activeMatchRef.current])

  return (
    <OverlayScrollbarsComponent ref={sportTypeScrollRef}>
      <ul className={cn(styles.sportsType__event_list)}>
        {showLiveMatchesList ? (
          Object.entries(tournaments).map(([tournamentTitle, matchIds], index: number) => (
            <li
              key={`${tournamentTitle}_${index}`}
              className={cn(styles.sportsType__event, {
                [styles.active]: activeEventName === tournamentTitle,
              })}
              onClick={() => {
                setActiveEventName(tournamentTitle)
              }}
            >
              <h3
                className={cn(styles.sportsType__event_title, {
                  [styles.active_title]: activeEventName === tournamentTitle,
                })}
              >
                <span>{tournamentTitle}</span>
                <div>
                  <b>{matchIds.length}</b>
                  <SportsArrowDropDownIcon className={cn(styles.arrow)} />
                </div>
              </h3>

              {matchIds.map((id, index2: number) => {
                const { match } = liveMatches[id]
                const { name, dtStart: startDate } = match
                const time = new Date(startDate * 1e3).toLocaleTimeString().slice(0, 5)

                return (
                  <div
                    className={cn(styles.sportsType__event_match, {
                      [styles.active_match]: selectedMatch === id,
                    })}
                    ref={selectedMatch === id ? activeMatchRef : null}
                    onClick={() => dispatch(setSelectedMatch(id))}
                    key={`${name}_${index2}`}
                  >
                    <span className={cn(styles.title)}>{name}</span>

                    <div className={cn(styles.start_date)}>
                      <span className={cn(styles.time)}>{time}</span>
                    </div>
                  </div>
                )
              })}
            </li>
          ))
        ) : (
          <Preloader />
        )}
      </ul>
    </OverlayScrollbarsComponent>
  )
}

type PropsType = {
  liveMatches: LiveModeMatchesInterface
  tournaments: TournamentsInterface
  activeEventName: string | undefined
  selectedMatch: string
  setActiveEventName: (name: string | undefined) => void
}

export default React.memo(SportTypeEvent)
