import React from "react";
import cn from "classnames";
import buttonStyles from "./styles/CreateThemeBtn.module.scss";
import { PlusIcon } from "../index";

const CreateThemeBtn: React.FC<PropsType> = React.memo(({ setActiveCreateThemePopup }) => {
  return (
    <div className={cn(buttonStyles.button_wrapper)}>
      <button onClick={() => setActiveCreateThemePopup(true)}>
        <PlusIcon />
        <span>Create Theme</span>
      </button>
    </div>
  );
});

type PropsType = {
  setActiveCreateThemePopup: (value: boolean) => void;
};

export default CreateThemeBtn;
