import type { LiveModeMatchInterface } from '@/store/reducers/models/interfaces/liveMode/liveModeMatchInterface'
import type { TournamentsInterface } from '@/store/reducers/models/interfaces/liveMode/liveModeMatches.interface'

import { createAppAsyncThunk } from '@/store/thunks/thunkConfig'
import { clearMatches, deleteMatch, setIsMatchesLoaded, setSelectedMatch, updateMatch } from '@/store/reducers/liveMode'
import { getExistingSportType, getTournaments } from '@/store/selectors/liveModeSelectors'
import { setSport } from '@/store/reducers/general'
import { ActiveSports } from '@/store/reducers/models/enums/general.enum'

export const updateMatchThunk = createAppAsyncThunk<void, LiveModeMatchInterface>(
  'liveMode/updateMatchThunk',
  (match, { dispatch, getState }) => {
    const state = getState()
    const {
      liveMode: { matches },
    } = state

    const existingSportType = getExistingSportType(state)

    // when loading first match
    if (!Object.values(matches).length) {
      setTimeout(() => {
        const state2 = getState()
        const { selectedMatchId } = state2.liveMode
        const tournaments: TournamentsInterface = getTournaments(state2)
        const firstMatchId = Object.values(tournaments)?.[0]?.[0]

        dispatch(setIsMatchesLoaded(true))

        if (!selectedMatchId && firstMatchId && existingSportType)
          dispatch(setSelectedMatch(firstMatchId))
      }, 1000)
    }

    if (existingSportType) dispatch(updateMatch(match))
  },
)

export const deleteMatchAndSetNew = createAppAsyncThunk<void, {matchId: string, subFn: any}>(
  'liveMode/deleteMatchAndSetNew',
  ({matchId, subFn}, { dispatch, getState }) => {
    dispatch(deleteMatch(matchId));

    const state = getState()
    const {
      liveMode: { matches, sports },
      general: {sport}
    } = state

    const matchesCount = Object.keys(matches).length;

    if (matchesCount > 0) {
      dispatch(setSelectedMatch(Object.keys(matches)[0]));
    } else if (sports.length > 0) {
      dispatch(setSport(sports[0] as ActiveSports));
      dispatch(clearMatches())
      subFn(sports[0]);
    }
  },
)
