import { ActiveSports } from '@/store/reducers/models/enums/general.enum'
import {
  AmericanFootballStatisticsWidgets,
  BasketballStatisticsWidgets,
  DartsStatisticsWidgets,
  FootballStatisticsWidgets,
  HandballStatisticsWidgets,
  IceHockeyStatisticsWidgets,
  TennisStatisticsWidgets,
  VolleyballStatisticsWidgets,
  Dota2StatisticsWidgets,
  BaseballStatisticsWidgets,
  WaterPoloStatisticsWidgets,
  RugbyStatisticsWidgets,
  CricketStatisticsWidgets,
  TableTennisStatisticsWidgets,
  LoLStatisticsWidgets,
} from '@/store/reducers/models/enums/settings.enum'

export const sportsWidgetsMapConfig = {
  [ActiveSports.FOOTBALL]: FootballStatisticsWidgets,
  [ActiveSports.BASKETBALL]: BasketballStatisticsWidgets,
  [ActiveSports.TENNIS]: TennisStatisticsWidgets,
  [ActiveSports.VOLLEYBALL]: VolleyballStatisticsWidgets,
  [ActiveSports.AMERICAN_FOOTBALL]: AmericanFootballStatisticsWidgets,
  [ActiveSports.HANDBALL]: HandballStatisticsWidgets,
  [ActiveSports.ICE_HOCKEY]: IceHockeyStatisticsWidgets,
  [ActiveSports.DARTS]: DartsStatisticsWidgets,
  [ActiveSports.DOTA_2]: Dota2StatisticsWidgets,
  [ActiveSports.LOL]: LoLStatisticsWidgets,
  [ActiveSports.BASEBALL]: BaseballStatisticsWidgets,
  [ActiveSports.WATER_POLO]: WaterPoloStatisticsWidgets,
  [ActiveSports.RUGBY_LEAGUE]: RugbyStatisticsWidgets,
  [ActiveSports.RUGBY_UNION]: RugbyStatisticsWidgets,
  [ActiveSports.CRICKET]: CricketStatisticsWidgets,
  [ActiveSports.TABLE_TENNIS]: TableTennisStatisticsWidgets,
}
