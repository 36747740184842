import { createSelector } from '@reduxjs/toolkit'

import type { AppStateType } from '../store'
import type { LanguagesInterface } from '@/store/reducers/models/interfaces/languages.interface'

export const getLanguages = (state: AppStateType) => state.languages

export const getLanguagesList = createSelector(
  getLanguages,
  (languages: LanguagesInterface) => languages.languages,
)

export const getSelectedLanguage = createSelector(
  getLanguages,
  (languages: LanguagesInterface) => languages.selectedLanguage,
)
