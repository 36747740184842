import React from 'react'
import cn from 'classnames'

import hStyles from './styles/HeaderTypes.module.scss'

import type { HeaderType } from '@/store/reducers/models/types/settings.type'

import { CheckedIcon, UncheckedIcon } from 'widgets/OptionsWidget/modules/elements'
import { getEventHeader } from 'store/selectors/settingsSelectors'
import { useAppDispatch, useAppSelector } from 'store/store'
import { HeaderWidgets } from '@/store/reducers/models/enums/settings.enum'
import { setEventHeaderTypes } from '@/store/thunks/settingsThunks'

const HeaderTypes: React.FC = () => {
  const dispatch = useAppDispatch()
  const eventHeader = useAppSelector(getEventHeader)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const update = eventHeader.map(item => {
      if (item.key === name) {
        return {
          ...item,
          value: checked,
        }
      }

      return item
    });

    dispatch(setEventHeaderTypes(update))
  }

  return (
    <div className={cn(hStyles.header__types)}>
      <ul className={cn(hStyles.header__types_list)}>
        {
          eventHeader.map((item, index) => {
            const replacedKey = item.name.replace('_', ' ')

            return (
              <li className={cn(hStyles.list_item)} key={`${item.key}_${index}`}>
                <input
                  id={`${item.key}_${index}`}
                  name={item.key}
                  type='checkbox'
                  checked={item.value}
                  onChange={handleChange}
                />
                <label htmlFor={`${item.key}_${index}`}>
                  {item.value === true ? <CheckedIcon /> : <UncheckedIcon />}
                </label>
                <span>{replacedKey}</span>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default React.memo(HeaderTypes)
