import React from "react";

const PlayIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 10C2.25 5.44365 5.94365 
              1.75 10.5 1.75C15.0563 1.75 18.75 
              5.44365 18.75 10C18.75 14.5563
               15.0563 18.25 10.5 18.25C5.94365 18.25 
               2.25 14.5563 2.25 10ZM10.5 0.25C5.11522 
               0.25 0.75 4.61522 0.75 10C0.75 15.3848 
               5.11522 19.75 10.5 19.75C15.8848 19.75
                20.25 15.3848 20.25 10C20.25 4.61522 
                15.8848 0.25 10.5 0.25ZM8.88587 
                6.35688C8.65417 6.21786 8.36561 
                6.21422 8.13048 6.34735C7.89534 
                6.48048 7.75 6.7298 7.75 7V13C7.75 
                13.2702 7.89534 13.5195 8.13048 
                13.6526C8.36561 13.7858 8.65417 
                13.7821 8.88587 13.6431L13.8859 
                10.6431C14.1118 10.5076 14.25 10.2634 
                14.25 10C14.25 9.73655 14.1118 
                9.49242 13.8859 9.35688L8.88587 
                6.35688ZM12.0423 10L9.25 
                11.6754V8.32464L12.0423 10Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default PlayIcon;
