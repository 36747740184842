import React from 'react'

import { ActiveSports } from '@/store/reducers/models/enums/general.enum'

import { useAppDispatch } from '@/store/store'
import protoTransform from '@/proto/proto-transform'
import { clearMatches, deleteMatch, setSportsList } from '@/store/reducers/liveMode'
import { AvailableSports, SportWeight } from '@/config/available-sports'
import { deleteMatchAndSetNew, updateMatchThunk } from '@/store/thunks/liveModeThunks'
import { Event } from '@/proto/typescript/enums/event.enum'

function isOpen(ws: WebSocket) {
  return ws.readyState === ws.OPEN
}

const multiLiveConfig = {
  provider: 'fc',
  feedType: 0,
}

const connection: WebSocket = new WebSocket(
  `${process.env.REACT_APP_PROXY_URL}?token=${process.env.REACT_APP_PROXY_TOKEN}&partner_id=${process.env.REACT_APP_PROXY_CLIENT}`,
)

export const useLiveSocket = (selectedSportType: string) => {
  const {
    getSubscribeToLiveSportsList,
    getSubscribeToSport,
    getUnsubscribeFromAll,
    convertProtoResponseToJSObject$,
  } = protoTransform()

  const dispatch = useAppDispatch()
  const getSportsList = (): void => {
    if (!isOpen(connection)) return

    const sportsRequest = getSubscribeToLiveSportsList(
      multiLiveConfig.provider,
      multiLiveConfig.feedType,
    )

    connection.send(sportsRequest.data)
  }

  const subscribeToSport = (sportType: string): void => {
    if (!isOpen(connection)) return

    const config = { ...multiLiveConfig, sportType }
    const matchesInfoRequest = getSubscribeToSport(
      config.sportType,
      config.provider,
      config.feedType,
    )

    console.log('Socket subscribe to sport', config)
    connection.send(matchesInfoRequest.data)
  }

  const unsubscribeFromAll = (): void => {
    if (!isOpen(connection)) return

    const unsubFromSportRequest = getUnsubscribeFromAll()

    console.log('Socket unsubscribe from all')
    connection.send(unsubFromSportRequest.data)
  }

  React.useEffect(() => {
    connection.onopen = () => {
      console.log('Socket connected')
      getSportsList()
      subscribeToSport(selectedSportType)
    }
    connection.onmessage = async (message: MessageEvent) => {
      const data = await convertProtoResponseToJSObject$(message.data)

      if (data?.unsubOk) {
        console.log('Socket unsub Ok', data)
        dispatch(clearMatches())
      }

      if (data?.error) {
        console.log('Socket error', data)
      }

      if (data?.sports) {
        const sportsList = AvailableSports
          .filter((s: ActiveSports) => data.sports.sportsList?.includes(s))
          .sort((a: ActiveSports, b: ActiveSports) => SportWeight[a] - SportWeight[b])

        dispatch(setSportsList(sportsList))
      }

      if (data?.match) {
        if (data?.event === Event.updated) {
          dispatch(updateMatchThunk(data))
          return
        }

        if (data?.event === Event.deleted) {
          dispatch(deleteMatchAndSetNew({ matchId: data.match.matchId.id, subFn: subscribeToSport }))
        }
      }
    }

    connection.onclose = () => {
      console.log('Socket disconnected')
      connection.close()
    }
  }, [])

  return { subscribeToSport, unsubscribeFromAll }
}
