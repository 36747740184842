import { createSelector } from 'reselect'

import type { AppStateType } from '../store'
import type { ThemeInterface } from '@/store/reducers/models/interfaces/styles.interface'

export const getStyles = (state: AppStateType) => state.styles

export const getStylesThemes = createSelector(getStyles, (state) => state.themes)

export const getActiveThemeId = createSelector(getStyles, (state) => state.selectedThemeId)

export const getActiveTheme = createSelector(
  getStylesThemes,
  getActiveThemeId,
  (themes, activeThemeId) => {
    const activeTheme = themes.find((elem: ThemeInterface) => elem.id === activeThemeId)
    if (activeTheme === undefined) {
      return themes[0]
    }
    return activeTheme
  },
)
