import React from 'react'

const WarningIcon: React.FC = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.1619 6.45833C19.2845 4.51389 22.0911 4.51389 23.2137 6.45833L37.6474 31.4583C38.7701 33.4028 37.3668 35.8333 35.1215 35.8333H6.25402C4.00877 35.8333 2.60549 33.4028 3.72812 31.4583L18.1619 6.45833ZM21.0486 7.70833C20.8883 7.43056 20.4873 7.43055 20.3269 7.70833L5.89318 32.7083C5.73281 32.9861 5.93328 33.3333 6.25402 33.3333H35.1215C35.4423 33.3333 35.6428 32.9861 35.4824 32.7083L21.0486 7.70833ZM19.3543 27.9167C19.3543 27.2263 19.914 26.6667 20.6043 26.6667H20.771C21.4614 26.6667 22.021 27.2263 22.021 27.9167V28.0833C22.021 28.7737 21.4614 29.3333 20.771 29.3333H20.6043C19.914 29.3333 19.3543 28.7737 19.3543 28.0833V27.9167ZM21.9373 16.25C21.9373 15.5596 21.3777 15 20.6873 15C19.997 15 19.4373 15.5596 19.4373 16.25V22.9167C19.4373 23.607 19.997 24.1667 20.6873 24.1667C21.3777 24.1667 21.9373 23.607 21.9373 22.9167V16.25Z'
      fill='#ED6C02'
    />
  </svg>
)

export default WarningIcon
