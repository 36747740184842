import React from "react";

const MainCaptionIcon: React.FC = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.808 9.87435H13.4413C11.7663 9.87435 10.958 9.05768 10.958 7.39102V4.02435C10.958 2.34935 11.7747 1.54102 13.4413 1.54102H16.808C18.483 1.54102 19.2913 2.35768 19.2913 4.02435V7.39102C19.2913 9.05768 18.4747 9.87435 16.808 9.87435ZM13.4413 2.79102C12.458 2.79102 12.208 3.04102 12.208 4.02435V7.39102C12.208 8.37435 12.458 8.62435 13.4413 8.62435H16.808C17.7913 8.62435 18.0413 8.37435 18.0413 7.39102V4.02435C18.0413 3.04102 17.7913 2.79102 16.808 2.79102H13.4413V2.79102Z" fill="white"/>
            <path d="M7.225 9.87435H3.85833C2.18333 9.87435 1.375 9.13268 1.375 7.59935V3.81602C1.375 2.28268 2.19167 1.54102 3.85833 1.54102H7.225C8.9 1.54102 9.70833 2.28268 9.70833 3.81602V7.59102C9.70833 9.13268 8.89167 9.87435 7.225 9.87435ZM3.85833 2.79102C2.74167 2.79102 2.625 3.10768 2.625 3.81602V7.59102C2.625 8.30768 2.74167 8.61602 3.85833 8.61602H7.225C8.34167 8.61602 8.45833 8.29935 8.45833 7.59102V3.81602C8.45833 3.09935 8.34167 2.79102 7.225 2.79102H3.85833Z" fill="white"/>
            <path d="M7.225 19.4583H3.85833C2.18333 19.4583 1.375 18.6417 1.375 16.975V13.6083C1.375 11.9333 2.19167 11.125 3.85833 11.125H7.225C8.9 11.125 9.70833 11.9417 9.70833 13.6083V16.975C9.70833 18.6417 8.89167 19.4583 7.225 19.4583ZM3.85833 12.375C2.875 12.375 2.625 12.625 2.625 13.6083V16.975C2.625 17.9583 2.875 18.2083 3.85833 18.2083H7.225C8.20833 18.2083 8.45833 17.9583 8.45833 16.975V13.6083C8.45833 12.625 8.20833 12.375 7.225 12.375H3.85833Z" fill="white"/>
            <path d="M17.416 15.709H12.416C12.0743 15.709 11.791 15.4257 11.791 15.084C11.791 14.7423 12.0743 14.459 12.416 14.459H17.416C17.7577 14.459 18.041 14.7423 18.041 15.084C18.041 15.4257 17.7577 15.709 17.416 15.709Z" fill="white"/>
            <path d="M14.916 18.209C14.5743 18.209 14.291 17.9257 14.291 17.584V12.584C14.291 12.2423 14.5743 11.959 14.916 11.959C15.2577 11.959 15.541 12.2423 15.541 12.584V17.584C15.541 17.9257 15.2577 18.209 14.916 18.209Z" fill="white"/>
        </svg>
    );
};

export default MainCaptionIcon;
