import React from 'react'
import cn from 'classnames'

import { isValidTheme } from '../../../helpers/isValidTheme'

import popupStyles from './styles/EditThemePopup.module.scss'
import { TrashIcon } from './elements'

import type { ThemeInterface } from '@/store/reducers/models/interfaces/styles.interface'

import { getActiveTheme, getStylesThemes } from 'store/selectors/stylesSelectors'
import { removeStyleTheme, updateThemes } from 'store/reducers/styles'
import { CloseIcon } from 'components/common'
import { useAppDispatch, useAppSelector } from 'store/store'

type PropsType = {
  setActiveEditThemePopup: (value: boolean) => void
}

const EditThemePopup: React.FC<PropsType> = ({ setActiveEditThemePopup }) => {
  const dispatch = useAppDispatch()

  const themes = useAppSelector(getStylesThemes)
  const activeTheme = useAppSelector(getActiveTheme)

  const handlePopupClose = () => {
    setActiveEditThemePopup(false)
  }

  const { id, name } = activeTheme
  React.useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlePopupClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const [val, setVal] = React.useState<string>(name)
  const [themeNameError, setThemeNameError] = React.useState(false)

  const isEdited = isValidTheme(themes, val)

  const editStyleTheme = () => {
    const updatedObj = themes.map((themeObj: ThemeInterface) => {
      if (isEdited && themeObj.id === id) {
        return { ...themeObj, name: val }
      }
      return themeObj
    })

    if (!isEdited) {
      setThemeNameError(true)
      return false
    }

    dispatch(updateThemes(updatedObj))
    handlePopupClose()
  }

  /* const removeTheme = (objId: string) => {
    if (themes.length <= 1) {
      return false
    }
    dispatch(removeStyleTheme(objId))
    handlePopupClose()
  } */

  return (
    <div className={cn(popupStyles.popup)}>
      <div className={cn(popupStyles.popup__content)}>
        <div className={cn(popupStyles.popup__header)}>
          <h2 className={cn(popupStyles.title)}>Edit Theme</h2>
          <span onClick={handlePopupClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={cn(popupStyles.popup__body)}>
          <label>Theme Name</label>
          <input
            value={val}
            maxLength={16}
            onChange={(e) => setVal(e.target.value)}
            onInput={() => setThemeNameError(false)}
            type='text'
            name='create_theme'
            style={{ borderColor: themeNameError ? 'red' : '' }}
          />
          {themeNameError && (
            <span className={popupStyles.validation_message}>
              Theme with this name already exists
            </span>
          )}
        </div>
        <div className={cn(popupStyles.popup__footer)}>
          {/* <button className={cn(popupStyles.remove)} onClick={() => removeTheme(id)}>
            <TrashIcon />
            <span>Remove Theme</span>
          </button> */}

          <button className={cn(popupStyles.cancel)} onClick={handlePopupClose}>
            Cancel
          </button>
          <button className={cn(popupStyles.confirm)} onClick={editStyleTheme} disabled={!val}>
            Confirm
          </button>
        </div>
      </div>
      <div className={cn(popupStyles.popup__backdrop)} onClick={handlePopupClose} />
    </div>
  )
}
export default React.memo(EditThemePopup)
