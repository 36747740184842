import React from "react";
import cn from "classnames";
import optStyles from "./styles/OptionsWidget.module.scss";
import { Settings } from "./modules/";

const OptionsWidget: React.FC = () => {
  return (
    <div className={cn(optStyles.options)}>
      <Settings />
    </div>
  );
};

export default OptionsWidget;