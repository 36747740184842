// source: match.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var id_pb = require("./id_pb.js");
goog.object.extend(proto, id_pb);
goog.exportSymbol("proto.Match", null, global);
goog.exportSymbol("proto.MatchSettlement", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Match = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Match, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Match.displayName = "proto.Match";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MatchSettlement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MatchSettlement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MatchSettlement.displayName = "proto.MatchSettlement";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Match.prototype.toObject = function (opt_includeInstance) {
    return proto.Match.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Match} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Match.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        matchId: (f = msg.getMatchId()) && id_pb.ID.toObject(includeInstance, f),
        sport: jspb.Message.getFieldWithDefault(msg, 3, ""),
        tournament: jspb.Message.getFieldWithDefault(msg, 4, ""),
        isTournament: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
        name: jspb.Message.getFieldWithDefault(msg, 8, ""),
        dtStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
        gamePart: jspb.Message.getFieldWithDefault(msg, 9, ""),
        teamsMap: (f = msg.getTeamsMap()) ? f.toObject(includeInstance, undefined) : [],
        ts: jspb.Message.getFieldWithDefault(msg, 11, 0),
        liveId: jspb.Message.getFieldWithDefault(msg, 12, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Match}
 */
proto.Match.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Match();
  return proto.Match.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Match} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Match}
 */
proto.Match.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setMatchId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTournament(value);
        break;
      case 10:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsTournament(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setDtStart(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setGamePart(value);
        break;
      case 5:
        var value = msg.getTeamsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint32,
            jspb.BinaryReader.prototype.readString,
            null,
            0,
            "",
          );
        });
        break;
      case 11:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setLiveId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Match.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Match.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Match} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Match.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getTournament();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getIsTournament();
  if (f) {
    writer.writeBool(10, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getDtStart();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getGamePart();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getTeamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      5,
      writer,
      jspb.BinaryWriter.prototype.writeUint32,
      jspb.BinaryWriter.prototype.writeString,
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(11, f);
  }
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
};

/**
 * optional ID match_id = 1;
 * @return {?proto.ID}
 */
proto.Match.prototype.getMatchId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setMatchId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.clearMatchId = function () {
  return this.setMatchId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Match.prototype.hasMatchId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string sport = 3;
 * @return {string}
 */
proto.Match.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string tournament = 4;
 * @return {string}
 */
proto.Match.prototype.getTournament = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setTournament = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional bool is_tournament = 10;
 * @return {boolean}
 */
proto.Match.prototype.getIsTournament = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};

/**
 * @param {boolean} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setIsTournament = function (value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};

/**
 * optional string name = 8;
 * @return {string}
 */
proto.Match.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional int64 dt_start = 2;
 * @return {number}
 */
proto.Match.prototype.getDtStart = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setDtStart = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string game_part = 9;
 * @return {string}
 */
proto.Match.prototype.getGamePart = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setGamePart = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * map<uint32, string> teams = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.Match.prototype.getTeamsMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
    jspb.Message.getMapField(this, 5, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.clearTeamsMap = function () {
  this.getTeamsMap().clear();
  return this;
};

/**
 * optional int64 ts = 11;
 * @return {number}
 */
proto.Match.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};

/**
 * optional string live_id = 12;
 * @return {string}
 */
proto.Match.prototype.getLiveId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};

/**
 * @param {string} value
 * @return {!proto.Match} returns this
 */
proto.Match.prototype.setLiveId = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MatchSettlement.prototype.toObject = function (opt_includeInstance) {
    return proto.MatchSettlement.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MatchSettlement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MatchSettlement.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        matchId: (f = msg.getMatchId()) && id_pb.ID.toObject(includeInstance, f),
        sport: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MatchSettlement}
 */
proto.MatchSettlement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MatchSettlement();
  return proto.MatchSettlement.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MatchSettlement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MatchSettlement}
 */
proto.MatchSettlement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setMatchId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MatchSettlement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MatchSettlement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MatchSettlement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MatchSettlement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional ID match_id = 1;
 * @return {?proto.ID}
 */
proto.MatchSettlement.prototype.getMatchId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.MatchSettlement} returns this
 */
proto.MatchSettlement.prototype.setMatchId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.MatchSettlement} returns this
 */
proto.MatchSettlement.prototype.clearMatchId = function () {
  return this.setMatchId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MatchSettlement.prototype.hasMatchId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string sport = 2;
 * @return {string}
 */
proto.MatchSettlement.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.MatchSettlement} returns this
 */
proto.MatchSettlement.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

goog.object.extend(exports, proto);
