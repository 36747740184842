// source: odds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var id_pb = require("./id_pb.js");
goog.object.extend(proto, id_pb);
goog.exportSymbol("proto.GamePart", null, global);
goog.exportSymbol("proto.GamePart.ParamType", null, global);
goog.exportSymbol("proto.Market", null, global);
goog.exportSymbol("proto.MatchResult", null, global);
goog.exportSymbol("proto.Param", null, global);
goog.exportSymbol("proto.Param.ValueCase", null, global);
goog.exportSymbol("proto.Property", null, global);
goog.exportSymbol("proto.Result", null, global);
goog.exportSymbol("proto.Selection", null, global);
goog.exportSymbol("proto.Selection.SelectionStatus", null, global);
goog.exportSymbol("proto.SelectionResult", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Param = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Param.oneofGroups_);
};
goog.inherits(proto.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Param.displayName = "proto.Param";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GamePart = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GamePart.repeatedFields_, null);
};
goog.inherits(proto.GamePart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GamePart.displayName = "proto.GamePart";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Selection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Selection.repeatedFields_, null);
};
goog.inherits(proto.Selection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Selection.displayName = "proto.Selection";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Market = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Market.repeatedFields_, null);
};
goog.inherits(proto.Market, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Market.displayName = "proto.Market";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Property = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Property.displayName = "proto.Property";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MatchResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MatchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MatchResult.displayName = "proto.MatchResult";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Result = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Result.displayName = "proto.Result";
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Param.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.Param.ValueCase = {
  VALUE_NOT_SET: 0,
  N: 1,
  S: 2,
};

/**
 * @return {proto.Param.ValueCase}
 */
proto.Param.prototype.getValueCase = function () {
  return /** @type {proto.Param.ValueCase} */ (
    jspb.Message.computeOneofCase(this, proto.Param.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Param.prototype.toObject = function (opt_includeInstance) {
    return proto.Param.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Param} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Param.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        n: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
        s: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Param}
 */
proto.Param.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Param();
  return proto.Param.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Param}
 */
proto.Param.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setN(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setS(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Param.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Param.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
};

/**
 * optional float n = 1;
 * @return {number}
 */
proto.Param.prototype.getN = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.Param} returns this
 */
proto.Param.prototype.setN = function (value) {
  return jspb.Message.setOneofField(this, 1, proto.Param.oneofGroups_[0], value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Param} returns this
 */
proto.Param.prototype.clearN = function () {
  return jspb.Message.setOneofField(this, 1, proto.Param.oneofGroups_[0], undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Param.prototype.hasN = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string s = 2;
 * @return {string}
 */
proto.Param.prototype.getS = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.Param} returns this
 */
proto.Param.prototype.setS = function (value) {
  return jspb.Message.setOneofField(this, 2, proto.Param.oneofGroups_[0], value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Param} returns this
 */
proto.Param.prototype.clearS = function () {
  return jspb.Message.setOneofField(this, 2, proto.Param.oneofGroups_[0], undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Param.prototype.hasS = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GamePart.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GamePart.prototype.toObject = function (opt_includeInstance) {
    return proto.GamePart.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GamePart} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GamePart.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, ""),
        paramType: jspb.Message.getFieldWithDefault(msg, 2, 0),
        paramsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GamePart}
 */
proto.GamePart.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GamePart();
  return proto.GamePart.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GamePart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GamePart}
 */
proto.GamePart.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {!proto.GamePart.ParamType} */ (reader.readEnum());
        msg.setParamType(value);
        break;
      case 3:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addParams(values[i]);
        }
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GamePart.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GamePart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GamePart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GamePart.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getParamType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writePackedInt32(3, f);
  }
};

/**
 * @enum {number}
 */
proto.GamePart.ParamType = {
  NONE: 0,
  ENUM: 1,
  RANGE: 2,
};

/**
 * optional string type = 1;
 * @return {string}
 */
proto.GamePart.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.GamePart} returns this
 */
proto.GamePart.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional ParamType param_type = 2;
 * @return {!proto.GamePart.ParamType}
 */
proto.GamePart.prototype.getParamType = function () {
  return /** @type {!proto.GamePart.ParamType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.GamePart.ParamType} value
 * @return {!proto.GamePart} returns this
 */
proto.GamePart.prototype.setParamType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * repeated int32 params = 3;
 * @return {!Array<number>}
 */
proto.GamePart.prototype.getParamsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.GamePart} returns this
 */
proto.GamePart.prototype.setParamsList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.GamePart} returns this
 */
proto.GamePart.prototype.addParams = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GamePart} returns this
 */
proto.GamePart.prototype.clearParamsList = function () {
  return this.setParamsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Selection.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Selection.prototype.toObject = function (opt_includeInstance) {
    return proto.Selection.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Selection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Selection.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        selectionId: (f = msg.getSelectionId()) && id_pb.ID.toObject(includeInstance, f),
        label: jspb.Message.getFieldWithDefault(msg, 15, ""),
        isTournament: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
        price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        paramsList: jspb.Message.toObjectList(
          msg.getParamsList(),
          proto.Param.toObject,
          includeInstance,
        ),
        gamePart: (f = msg.getGamePart()) && proto.GamePart.toObject(includeInstance, f),
        kind: jspb.Message.getFieldWithDefault(msg, 5, ""),
        scope: jspb.Message.getFieldWithDefault(msg, 8, ""),
        type: jspb.Message.getFieldWithDefault(msg, 14, ""),
        status: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Selection}
 */
proto.Selection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Selection();
  return proto.Selection.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Selection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Selection}
 */
proto.Selection.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setSelectionId(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setLabel(value);
        break;
      case 12:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsTournament(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setPrice(value);
        break;
      case 3:
        var value = new proto.Param();
        reader.readMessage(value, proto.Param.deserializeBinaryFromReader);
        msg.addParams(value);
        break;
      case 9:
        var value = new proto.GamePart();
        reader.readMessage(value, proto.GamePart.deserializeBinaryFromReader);
        msg.setGamePart(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setKind(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setScope(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {!proto.Selection.SelectionStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Selection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Selection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Selection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Selection.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(15, f);
  }
  f = message.getIsTournament();
  if (f) {
    writer.writeBool(12, f);
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(2, f);
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.Param.serializeBinaryToWriter);
  }
  f = message.getGamePart();
  if (f != null) {
    writer.writeMessage(9, f, proto.GamePart.serializeBinaryToWriter);
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }
};

/**
 * @enum {number}
 */
proto.Selection.SelectionStatus = {
  BLOCKED: 0,
  ACTIVE: 1,
};

/**
 * optional ID selection_id = 1;
 * @return {?proto.ID}
 */
proto.Selection.prototype.getSelectionId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setSelectionId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.clearSelectionId = function () {
  return this.setSelectionId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Selection.prototype.hasSelectionId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string label = 15;
 * @return {string}
 */
proto.Selection.prototype.getLabel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/**
 * @param {string} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional bool is_tournament = 12;
 * @return {boolean}
 */
proto.Selection.prototype.getIsTournament = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};

/**
 * @param {boolean} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setIsTournament = function (value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};

/**
 * optional float price = 2;
 * @return {number}
 */
proto.Selection.prototype.getPrice = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setPrice = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * repeated Param params = 3;
 * @return {!Array<!proto.Param>}
 */
proto.Selection.prototype.getParamsList = function () {
  return /** @type{!Array<!proto.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Param, 3)
  );
};

/**
 * @param {!Array<!proto.Param>} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setParamsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Param}
 */
proto.Selection.prototype.addParams = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Param, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.clearParamsList = function () {
  return this.setParamsList([]);
};

/**
 * optional GamePart game_part = 9;
 * @return {?proto.GamePart}
 */
proto.Selection.prototype.getGamePart = function () {
  return /** @type{?proto.GamePart} */ (jspb.Message.getWrapperField(this, proto.GamePart, 9));
};

/**
 * @param {?proto.GamePart|undefined} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setGamePart = function (value) {
  return jspb.Message.setWrapperField(this, 9, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.clearGamePart = function () {
  return this.setGamePart(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Selection.prototype.hasGamePart = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional string kind = 5;
 * @return {string}
 */
proto.Selection.prototype.getKind = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setKind = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string scope = 8;
 * @return {string}
 */
proto.Selection.prototype.getScope = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setScope = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string type = 14;
 * @return {string}
 */
proto.Selection.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};

/**
 * @param {string} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * optional SelectionStatus status = 6;
 * @return {!proto.Selection.SelectionStatus}
 */
proto.Selection.prototype.getStatus = function () {
  return /** @type {!proto.Selection.SelectionStatus} */ (
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};

/**
 * @param {!proto.Selection.SelectionStatus} value
 * @return {!proto.Selection} returns this
 */
proto.Selection.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Market.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Market.prototype.toObject = function (opt_includeInstance) {
    return proto.Market.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Market} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Market.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        marketId: (f = msg.getMarketId()) && id_pb.ID.toObject(includeInstance, f),
        type: jspb.Message.getFieldWithDefault(msg, 2, ""),
        label: jspb.Message.getFieldWithDefault(msg, 4, ""),
        sport: jspb.Message.getFieldWithDefault(msg, 7, ""),
        scope: jspb.Message.getFieldWithDefault(msg, 8, ""),
        gamePart: (f = msg.getGamePart()) && proto.GamePart.toObject(includeInstance, f),
        selectionsList: jspb.Message.toObjectList(
          msg.getSelectionsList(),
          proto.Selection.toObject,
          includeInstance,
        ),
        isTournament: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        ts: jspb.Message.getFieldWithDefault(msg, 10, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Market}
 */
proto.Market.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Market();
  return proto.Market.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Market} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Market}
 */
proto.Market.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setMarketId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setLabel(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setScope(value);
        break;
      case 9:
        var value = new proto.GamePart();
        reader.readMessage(value, proto.GamePart.deserializeBinaryFromReader);
        msg.setGamePart(value);
        break;
      case 5:
        var value = new proto.Selection();
        reader.readMessage(value, proto.Selection.deserializeBinaryFromReader);
        msg.addSelections(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsTournament(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Market.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Market.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Market} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Market.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMarketId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getGamePart();
  if (f != null) {
    writer.writeMessage(9, f, proto.GamePart.serializeBinaryToWriter);
  }
  f = message.getSelectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.Selection.serializeBinaryToWriter);
  }
  f = message.getIsTournament();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(10, f);
  }
};

/**
 * optional ID market_id = 1;
 * @return {?proto.ID}
 */
proto.Market.prototype.getMarketId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setMarketId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.clearMarketId = function () {
  return this.setMarketId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Market.prototype.hasMarketId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.Market.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string label = 4;
 * @return {string}
 */
proto.Market.prototype.getLabel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string sport = 7;
 * @return {string}
 */
proto.Market.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string scope = 8;
 * @return {string}
 */
proto.Market.prototype.getScope = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setScope = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional GamePart game_part = 9;
 * @return {?proto.GamePart}
 */
proto.Market.prototype.getGamePart = function () {
  return /** @type{?proto.GamePart} */ (jspb.Message.getWrapperField(this, proto.GamePart, 9));
};

/**
 * @param {?proto.GamePart|undefined} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setGamePart = function (value) {
  return jspb.Message.setWrapperField(this, 9, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.clearGamePart = function () {
  return this.setGamePart(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Market.prototype.hasGamePart = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * repeated Selection selections = 5;
 * @return {!Array<!proto.Selection>}
 */
proto.Market.prototype.getSelectionsList = function () {
  return /** @type{!Array<!proto.Selection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Selection, 5)
  );
};

/**
 * @param {!Array<!proto.Selection>} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setSelectionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.Selection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Selection}
 */
proto.Market.prototype.addSelections = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Selection, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.clearSelectionsList = function () {
  return this.setSelectionsList([]);
};

/**
 * optional bool is_tournament = 6;
 * @return {boolean}
 */
proto.Market.prototype.getIsTournament = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};

/**
 * @param {boolean} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setIsTournament = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional int64 ts = 10;
 * @return {number}
 */
proto.Market.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.Market} returns this
 */
proto.Market.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Property.prototype.toObject = function (opt_includeInstance) {
    return proto.Property.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Property} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Property.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        propertyId: (f = msg.getPropertyId()) && id_pb.ID.toObject(includeInstance, f),
        team: jspb.Message.getFieldWithDefault(msg, 2, 0),
        propertiesMap: (f = msg.getPropertiesMap()) ? f.toObject(includeInstance, undefined) : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Property}
 */
proto.Property.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Property();
  return proto.Property.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Property}
 */
proto.Property.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setPropertyId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTeam(value);
        break;
      case 3:
        var value = msg.getPropertiesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            "",
            "",
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Property.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Property.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getTeam();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getPropertiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      3,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString,
    );
  }
};

/**
 * optional ID property_id = 1;
 * @return {?proto.ID}
 */
proto.Property.prototype.getPropertyId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.setPropertyId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.clearPropertyId = function () {
  return this.setPropertyId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Property.prototype.hasPropertyId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 team = 2;
 * @return {number}
 */
proto.Property.prototype.getTeam = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.setTeam = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * map<string, string> properties = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.Property.prototype.getPropertiesMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.clearPropertiesMap = function () {
  this.getPropertiesMap().clear();
  return this;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MatchResult.prototype.toObject = function (opt_includeInstance) {
    return proto.MatchResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MatchResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MatchResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: (f = msg.getId()) && id_pb.ID.toObject(includeInstance, f),
        name: jspb.Message.getFieldWithDefault(msg, 2, ""),
        tournament: jspb.Message.getFieldWithDefault(msg, 4, ""),
        sport: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MatchResult}
 */
proto.MatchResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MatchResult();
  return proto.MatchResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MatchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MatchResult}
 */
proto.MatchResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTournament(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MatchResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MatchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MatchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MatchResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getTournament();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.MatchResult.prototype.getId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.MatchResult} returns this
 */
proto.MatchResult.prototype.setId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.MatchResult} returns this
 */
proto.MatchResult.prototype.clearId = function () {
  return this.setId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MatchResult.prototype.hasId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.MatchResult.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.MatchResult} returns this
 */
proto.MatchResult.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string tournament = 4;
 * @return {string}
 */
proto.MatchResult.prototype.getTournament = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.MatchResult} returns this
 */
proto.MatchResult.prototype.setTournament = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string sport = 3;
 * @return {string}
 */
proto.MatchResult.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.MatchResult} returns this
 */
proto.MatchResult.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Result.prototype.toObject = function (opt_includeInstance) {
    return proto.Result.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Result} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Result.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: (f = msg.getId()) && id_pb.ID.toObject(includeInstance, f),
        sport: jspb.Message.getFieldWithDefault(msg, 4, ""),
        label: jspb.Message.getFieldWithDefault(msg, 2, ""),
        market: jspb.Message.getFieldWithDefault(msg, 5, ""),
        result: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Result}
 */
proto.Result.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Result();
  return proto.Result.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Result}
 */
proto.Result.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setSport(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setLabel(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMarket(value);
        break;
      case 3:
        var value = /** @type {!proto.SelectionResult} */ (reader.readEnum());
        msg.setResult(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Result.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Result.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getSport();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMarket();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * optional ID id = 1;
 * @return {?proto.ID}
 */
proto.Result.prototype.getId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.setId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.clearId = function () {
  return this.setId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Result.prototype.hasId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string sport = 4;
 * @return {string}
 */
proto.Result.prototype.getSport = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.setSport = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string label = 2;
 * @return {string}
 */
proto.Result.prototype.getLabel = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string market = 5;
 * @return {string}
 */
proto.Result.prototype.getMarket = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.setMarket = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional SelectionResult result = 3;
 * @return {!proto.SelectionResult}
 */
proto.Result.prototype.getResult = function () {
  return /** @type {!proto.SelectionResult} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.SelectionResult} value
 * @return {!proto.Result} returns this
 */
proto.Result.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * @enum {number}
 */
proto.SelectionResult = {
  UNDEFINED: 0,
  WIN: 1,
  LOSE: 2,
  RETURN: 3,
  WINRET: 4,
  LOSERET: 5,
  IGNORE: 6,
};

goog.object.extend(exports, proto);
