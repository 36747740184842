import React from 'react'

import styles from './styles/DemoModeWidget.module.scss'
import { DemoMode } from './modules'

import { fetchDemoSports } from '@/store/thunks/demoModeThunks'
import { useAppDispatch } from '@/store/store'

const DemoModeWidget: React.FC = React.memo(() => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(fetchDemoSports())
  }, [])

  return (
    <div className={styles.demo_mode_widget}>
      <DemoMode />
    </div>
  )
})

export default DemoModeWidget
