export enum AvailableStatisticsWidgets {
  STANDINGS = 'standings',
  SEASON_TREE = 'season_tree',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  NEWS = 'news',
  BET_BOOSTER = 'bet_booster',
  PLAY2PLAY = 'play2play',
  STAT = 'stat',
}

export enum FootballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
  SEASON_TREE = 'season_tree',
}

export enum BasketballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
  SEASON_TREE = 'season_tree',
}

export enum TennisStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
  SEASON_TREE = 'season_tree',
}

export enum VolleyballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
  SEASON_TREE = 'season_tree',
}

export enum AmericanFootballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
  SEASON_TREE = 'season_tree',
}

export enum HandballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
}

export enum IceHockeyStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
}

export enum Dota2StatisticsWidgets {
  INVENTORY = 'inventory',
  TOURNAMENT = 'tournament',
  LINE_UP = 'line_up',
  STAT = 'stat',
}

export enum LoLStatisticsWidgets {
  TOURNAMENT = 'tournament',
  LINE_UP = 'line_up',
  STAT = 'stat',
}

export enum DartsStatisticsWidgets {
  STAT = 'stat',
}

export enum BaseballStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
}

export enum WaterPoloStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
}

export enum RugbyStatisticsWidgets {
  STAT = 'stat',
}

export enum CricketStatisticsWidgets {
  STAT = 'stat',
}

export enum TableTennisStatisticsWidgets {
  STANDINGS = 'standings',
  LINE_UP = 'line_up',
  H2H = 'h2h',
  PREVIOUS_GAMES = 'previous_games',
  CALENDAR = 'calendar',
  STAT = 'stat',
}

export type AllStatisticWidgets =
  | FootballStatisticsWidgets
  | BasketballStatisticsWidgets
  | TennisStatisticsWidgets
  | VolleyballStatisticsWidgets
  | AmericanFootballStatisticsWidgets
  | DartsStatisticsWidgets
  | HandballStatisticsWidgets
  | IceHockeyStatisticsWidgets
  | Dota2StatisticsWidgets
  | LoLStatisticsWidgets
  | BaseballStatisticsWidgets
  | WaterPoloStatisticsWidgets
  | RugbyStatisticsWidgets
  | CricketStatisticsWidgets
  | TableTennisStatisticsWidgets

export enum HeaderWidgets {
  SCORE = 'score',
  TEAM_NAMES = 'team_names',
  TEAM_LOGOS = 'team_logos',
}

export enum SettingsPosition {
  SINGLE = 'single',
  DOUBLE_LEFT = 'double_left',
  DOUBLE_RIGHT = 'double_right',
  DOUBLE_BOTTOM = 'double_bottom',
  SEPARATE = 'separate',
}

export enum OtherWidgets {
  TIMELINE = 'timeline',
  TIMER = 'timer',
}
