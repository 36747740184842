import React from "react";
import styles from "./styles/MatchSelectorWidget.module.scss";
import { MatchSelector } from "./modules";

const MatchSelectorWidget: React.FC = React.memo(() => {
  return (
    <div className={styles.match_selector_widget}>
      <MatchSelector />
    </div>
  );
});

export default MatchSelectorWidget;
