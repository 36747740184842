export enum Languages {
  ENGLISH = "eng",
  GERMAN = "ger",
  FRENCH = "fre",
  SPANISH = "spa",
  ITALIAN = "ita",
  PORTUGUESE = "por",
  PORTUGUESE_BRAZIL = "pt-br", // portuguese (brazil)
  PORTUGUESE_BRAZIL_2 = "por_2", // portuguese (brazil)
  GREEK = "gre",
  RUSSIAN = "rus",
  LITHUANIAN = "lit",
  LATVIAN = "lav",
  ARMENIAN = "arm",
  GEORGIAN = "geo",
  ARABIC = "arb",
  PERSIAN = "far", // persian (farsi)
  TURKISH = "tur",
  CHINA = "chn",
  CHINA_CHI = "chi", // china (this case is for statistics call)
  CHINA_ZHO = "zho", // china
  ESTONIAN = "est",
  INDONESIAN = "ind",
  KOREAN = "kor",
  MALAY = "msa",
  POLISH = "pol",
  ROMANIAN = "ron",
  CZECH = "cze",
  SLOVAK = "slo",
  SLOVAK_SLK = "slk",
  KURDISH = "kur",
  JAPANESE = "jpn",
  AZERBAIJANI = "aze",
  SERBIAN = "srp",
  UKRAINIAN = "ukr",
  CHINA_SIMPLIFIED = "zhh", // china simplified
  BULGARIAN = "bgr",
  TRANSLIT = "translit",
  NORWAY = "nor",
  DUTCH = "dut", // dutch
  DUTCH_NLD = "nld", // dutch
  FARSI = "fas",
  HEBREW = "heb",
  SWEDISH = "swe",
  FINNISH = "fin",
  SLOVENE = "slv",
  THAI = "tha",
  ALBANIAN = "alb",
}
