import React from 'react'
import cn from 'classnames'

import styles from './styles/NoLiveEventsPopup.module.scss'

import type { WidgetMode, ActiveSports } from '@/store/reducers/models/enums/general.enum'

import { useAppDispatch, useAppSelector } from 'store/store'
import { setMode, setSport } from '@/store/reducers/general'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { useLiveSocket } from '@/widgets/MatchSelectorWidget/hooks/useLiveSocket'
import { setModeThunk } from "@/store/thunks/settingsThunks";

const NoLiveMatchesPopup: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedSportType = useAppSelector(getActiveSport)
  const { unsubscribeFromAll } = useLiveSocket(selectedSportType)

  const handleSetActiveBtn = (e: React.MouseEvent<HTMLElement>) => {
    unsubscribeFromAll()
    dispatch(setSport(selectedSportType as ActiveSports))
    dispatch(setModeThunk(e.currentTarget.id as WidgetMode))
  }

  return (
    <div className={cn(styles.popup)}>
      <div className={cn(styles.popup__content)}>
        <h2 className={cn(styles.popup__title)}>
          Currently we have no live events. Please use Demo Mode
        </h2>
        <button onClick={handleSetActiveBtn} id='demo' className={cn(styles.popup__btn)}>
          Demo
        </button>
      </div>
    </div>
  )
}

export default React.memo(NoLiveMatchesPopup)
