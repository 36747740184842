import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import themesStyles from './styles/Themes.module.scss'
import { Theme } from './components'
import { CreateThemeBtn } from './components/elements'

import type { RefObject } from 'react'
import type {
  StylesInterface,
  ThemeInterface,
} from '@/store/reducers/models/interfaces/styles.interface'

const Themes: React.FC<PropsType> = React.memo(
  ({ styles, setActiveCreateThemePopup, setActiveEditThemePopup, setActiveDeleteThemePopup }) => {
    const themesScrollbarRef: RefObject<OverlayScrollbarsComponent> = useRef(null)
    const [amountThemes, setAmountThemes] = useState(
      themesScrollbarRef.current?.props.children.length,
    )
    const { themes } = styles

    useEffect(() => {
      setAmountThemes(themesScrollbarRef.current?.props.children.length)
    }, [])

    const themesAutoScroll = (currentAmountThemes: number) => {
      const themesScrollbar = themesScrollbarRef.current?.osInstance()
      if (amountThemes && themesScrollbar) {
        const scrollTo = currentAmountThemes > amountThemes ? '100%' : 0
        themesScrollbar.scroll([0, scrollTo], 500)
        setAmountThemes(currentAmountThemes)
      }
    }

    return (
      <div
        className={`${cn(themesStyles.themes)} ${themes.length >= 3 ? cn(themesStyles.multipleThemes) : ''}`}
      >
        <h2 className={cn(themesStyles.themes__title)}>Themes</h2>
        <OverlayScrollbarsComponent
          ref={themesScrollbarRef}
          options={{
            callbacks: {
              onContentSizeChanged: () =>
                themesAutoScroll(themesScrollbarRef.current?.props.children.length),
            },
          }}
        >
          {themes.map((elem: ThemeInterface) => (
            // @ts-ignore
            <Theme
              key={elem.id}
              {...elem}
              setActiveEditThemePopup={setActiveEditThemePopup}
              setActiveDeleteThemePopup={setActiveDeleteThemePopup}
            />
          ))}
        </OverlayScrollbarsComponent>

        <CreateThemeBtn setActiveCreateThemePopup={setActiveCreateThemePopup} />
      </div>
    )
  },
)

type PropsType = {
  styles: StylesInterface
  setActiveCreateThemePopup: (val: boolean) => void
  setActiveEditThemePopup: (val: boolean) => void
  setActiveDeleteThemePopup: (val: string | null) => void
}

export default Themes
