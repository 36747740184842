import React from "react";

const StyleCaptionIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47769 4.47769 0 10 0C15.3686 
                        0 20 3.9001 20 9.03846C20 12.4358 
                        17.2435 15.1923 13.8462 15.1923H12.1442C12.0896 
                        15.1923 12.0481 15.2338 12.0481 15.2885C12.0481 
                        15.2931 12.0489 15.301 12.0535 15.3126C12.0584 
                        15.3251 12.0662 15.3378 12.0753 15.3481C12.505 
                        15.8325 12.7885 16.4803 12.7885 17.2115C12.7885 
                        18.7531 11.5415 20 10 20C4.47769 20 0 15.5223 
                        0 10ZM4.61539 8.55769C4.61539 8.61231 4.65692 
                        8.65385 4.71154 8.65385C4.76616 8.65385 
                        4.80769 8.61231 4.80769 8.55769C4.80769 
                        8.50308 4.76616 8.46154 4.71154 8.46154C4.65692 
                        8.46154 4.61539 8.50308 4.61539 8.55769ZM7.5 
                        4.71154C7.5 4.76616 7.54154 4.80769 7.59615 
                        4.80769C7.65077 4.80769 7.69231 4.76616 7.69231 
                        4.71154C7.69231 4.65692 7.65077 4.61539 7.59615 
                        4.61539C7.54154 4.61539 7.5 4.65692 7.5 4.71154ZM12.3077 
                        4.71154C12.3077 4.76616 12.3492 4.80769 12.4038 
                        4.80769C12.4585 4.80769 12.5 4.76616 12.5 4.71154C12.5 
                        4.65692 12.4585 4.61539 12.4038 4.61539C12.3492 
                        4.61539 12.3077 4.65692 12.3077 4.71154ZM15.1923 
                        8.55769C15.1923 8.61231 15.2338 8.65385 15.2885 
                        8.65385C15.3431 8.65385 15.3846 8.61231 15.3846 
                        8.55769C15.3846 8.50308 15.3431 8.46154 15.2885 
                        8.46154C15.2338 8.46154 15.1923 8.50308 15.1923 
                        8.55769ZM1.34615 10C1.34615 5.22115 5.22115 1.34615 
                        10 1.34615C14.7788 1.34615 18.6538 4.78846 18.6538 
                        9.03846C18.6538 11.6923 16.5 13.8462 13.8462 
                        13.8462H12.1442C11.3462 13.8462 10.7019 14.4904 
                        10.7019 15.2885C10.7019 15.6538 10.8462 15.9904 
                        11.0673 16.2404C11.2981 16.5 11.4423 16.8365 
                        11.4423 17.2115C11.4423 18.0096 10.7981 18.6538 
                        10 18.6538C5.22115 18.6538 1.34615 14.7788 1.34615 
                        10ZM3.26923 8.55769C3.26923 9.35577 3.91346 
                        10 4.71154 10C5.50962 10 6.15385 9.35577 
                        6.15385 8.55769C6.15385 7.75962 5.50962 
                        7.11539 4.71154 7.11539C3.91346 7.11539 
                        3.26923 7.75962 3.26923 8.55769ZM6.15385 
                        4.71154C6.15385 5.50962 6.79808 6.15385 
                        7.59615 6.15385C8.39423 6.15385 9.03846 
                        5.50962 9.03846 4.71154C9.03846 3.91346 
                        8.39423 3.26923 7.59615 3.26923C6.79808 
                        3.26923 6.15385 3.91346 6.15385 
                        4.71154ZM10.9615 4.71154C10.9615 
                        5.50962 11.6058 6.15385 12.4038 
                        6.15385C13.2019 6.15385 13.8462 
                        5.50962 13.8462 4.71154C13.8462 3.91346 
                        13.2019 3.26923 12.4038 3.26923C11.6058 
                        3.26923 10.9615 3.91346 10.9615 4.71154ZM13.8462 
                        8.55769C13.8462 9.35577 14.4904 10 15.2885 
                        10C16.0865 10 16.7308 9.35577 16.7308 8.55769C16.7308 
                        7.75962 16.0865 7.11539 15.2885 7.11539C14.4904 
                        7.11539 13.8462 7.75962 13.8462 8.55769Z"
      />
    </svg>
  );
};

export default StyleCaptionIcon;
