import { createSelector } from 'reselect'

import type { AppStateType } from '../store'
import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'
import type {
  LiveModeMatchesInterface,
  TournamentsInterface,
} from '@/store/reducers/models/interfaces/liveMode/liveModeMatches.interface'

import { getActiveSport } from '@/store/selectors/generalSelectors'

export const getSportsList = (state: AppStateType) => state.liveMode.sports

export const getSelectedMatchId = (state: AppStateType) => state.liveMode.selectedMatchId

export const getIsMatchesLoaded = (state: AppStateType) => state.liveMode.isMatchesLoaded

export const getLiveModeMatches = (state: AppStateType) => state.liveMode.matches

export const getTournaments = createSelector(
  getLiveModeMatches,
  (matches: LiveModeMatchesInterface): TournamentsInterface =>
    Object.values(matches).reduce((acc: any, { match }) => {
      const tournament = match.tournament || 'Other'
      const { id } = match.matchId
      return {
        ...acc,
        [tournament]: [...(acc[tournament] || []), id],
      }
    }, {}),
)

export const getExistingSportType = createSelector(
  getSportsList,
  getActiveSport,
  (sports: Array<ActiveSports>, sportType: ActiveSports) =>
    sports.find((sport) => sport === sportType),
)
