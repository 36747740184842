import { createSelector } from 'reselect'

import type { AppStateType } from '../store'
import type { EventInterface } from '@/store/reducers/models/interfaces/demoMode.interface'

export const getDemoMode = (state: AppStateType) => state.demoMode

export const getDemoSessionId = (state: AppStateType) => state.demoMode.demoSessionId

export const getDemoSportsList = (state: AppStateType) => state.demoMode.sports

export const getSportsEventsList = (state: AppStateType) => state.demoMode.events

export const getSelectedSportEventsList = createSelector(
  getSportsEventsList,
  (eventsList: EventInterface[]) =>
    [...eventsList].sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0)),
)
