import React from "react";

const PlusIcon: React.FC = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50032 11.8327C6.82249 11.8327 
7.08366 11.5715 7.08366 11.2493L7.08366 
6.58268H11.7503C12.0725 6.58268 12.3337 
6.32152 12.3337 5.99935C12.3337 5.67718 
12.0725 5.41602 11.7503 5.41602H7.08366L7.08366 
0.749349C7.08366 0.427183 6.82249
0.166016 6.50033 0.166016C6.17816 
0.166016 5.91699 0.427183 5.91699 
0.749349L5.91699 5.41602H1.25033C0.92816 
5.41602 0.666992 5.67718 0.666992 
5.99935C0.666992 6.32151 0.92816 
6.58268 1.25033 6.58268H5.91699L5.91699 
11.2493C5.91699 11.5715 6.17816 
11.8327 6.50032 11.8327Z"
      />
    </svg>
  );
};

export default PlusIcon;
