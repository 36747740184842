import type {
    TimelineType,
} from '@/store/reducers/models/types/settings.type'

import {
    toggleEventHeaderTypes, toggleEventOther,
    toggleEventStatTypes,
} from '@/store/reducers/settings'
import {createAppAsyncThunk} from '@/store/thunks/thunkConfig'
import {WidgetMode} from '@/store/reducers/models/enums/general.enum'
import {setMode} from '@/store/reducers/general'
import {WidgetItemInterface} from '@/store/reducers/models/interfaces/settings.interface'

export const setEventStatTypes = createAppAsyncThunk<void, WidgetItemInterface[]>(
    'settings/setEventStatTypes',
    (statistics, {dispatch, getState}) => {
        const {sport} = getState().general
        dispatch(toggleEventStatTypes({statistics, sport}))
    },
)
export const setEventHeaderTypes = createAppAsyncThunk<void, WidgetItemInterface[]>(
    'settings/setEventHeaderTypes',
    (header, {dispatch, getState}) => {
        const {sport} = getState().general
        dispatch(toggleEventHeaderTypes({header, sport}))
    },
)
export const setEventOther = createAppAsyncThunk<void, WidgetItemInterface[]>(
    'settings/setEventTimeline',
    (other, {dispatch, getState}) => {
        const {sport} = getState().general
        dispatch(toggleEventOther({other, sport}))
    },
)
export const setModeThunk = createAppAsyncThunk<void, any>(
    'settings/setModeThunk',
    (mode, {dispatch, getState}) => {
        const {demoSessionId} = getState().demoMode
        // @ts-ignore
        window.SCAW('_clearClientInfo', {})
        // @ts-ignore
        window.SCAW('setIsDemoMode', mode !== WidgetMode.LIVE)
        window.setTimeout(() => {
            const clientID = mode === WidgetMode.LIVE
                ? process.env.REACT_APP_PROXY_CLIENT
                : demoSessionId;
            // @ts-ignore
            window.SCAW('_setClientInfo', {clientID})
        }, 100)
        dispatch(setMode(mode))
    },
)
