import React from 'react'
import cn from 'classnames'

import wStyles from './styles/WidgetPosition.module.scss'
import {
  PositionDoubleBottomIcon,
  PositionDoubleLeftIcon,
  PositionDoubleRightIcon,
  PositionSingleIcon,
} from './elements'

import { SettingsPosition } from '@/store/reducers/models/enums/settings.enum'
import { getIsDisabledWidgetPosition } from '@/store/selectors/generalSelectors'
import { changeStatWidgetPosition } from 'store/reducers/settings'
import { getStatisticsWidgetPosition } from 'store/selectors/settingsSelectors'
import { useAppDispatch, useAppSelector } from 'store/store'

const WidgetPosition: React.FC = () => {
  const dispatch = useAppDispatch()
  const position = useAppSelector(getStatisticsWidgetPosition)
  const isDisabledWidgetPosition = useAppSelector(getIsDisabledWidgetPosition)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    dispatch(changeStatWidgetPosition(value as SettingsPosition))
  }

  React.useEffect(() => {
    if (isDisabledWidgetPosition && position !== SettingsPosition.SINGLE && position !== SettingsPosition.SEPARATE) {
      dispatch(changeStatWidgetPosition(SettingsPosition.DOUBLE_BOTTOM))
    }
  }, [dispatch, isDisabledWidgetPosition])

  const isDisabledInput = (value: string): boolean =>
    (value === SettingsPosition.DOUBLE_RIGHT || value === SettingsPosition.DOUBLE_LEFT) &&
    isDisabledWidgetPosition

  const positionInputs = [
    {
      id: SettingsPosition.SINGLE,
      value: SettingsPosition.SINGLE,
    },
    {
      id: SettingsPosition.DOUBLE_RIGHT,
      value: SettingsPosition.DOUBLE_RIGHT,
    },
    {
      id: SettingsPosition.DOUBLE_LEFT,
      value: SettingsPosition.DOUBLE_LEFT,
    },
    {
      id: SettingsPosition.DOUBLE_BOTTOM,
      value: SettingsPosition.DOUBLE_BOTTOM,
    },
    // {
    //   id: SettingsPosition.SEPARATE,
    //   value: SettingsPosition.SEPARATE,
    // },
  ]

  return (
    <div className={cn(wStyles.statistics__types)}>
      <h3 className={cn(wStyles.statistics__types_title)}>Statistics widget position</h3>
      <ul className={cn(wStyles.statistics__types_list)}>
        {positionInputs.map((elem) => (
          <li
            key={elem.id}
            className={cn(wStyles.list_item, {
              [wStyles.list_item_disabled]:
              position !== elem.value &&
              isDisabledWidgetPosition &&
              (elem.value === SettingsPosition.DOUBLE_LEFT ||
                elem.value === SettingsPosition.DOUBLE_RIGHT),
            })}
          >
            <input
              type='radio'
              name='statistics_widget_position'
              id={elem.id}
              value={elem.value}
              onChange={handleChange}
              checked={position === elem.value}
              disabled={isDisabledInput(elem.value)}
            />
            <label htmlFor={elem.id}>
              {elem.value === SettingsPosition.SINGLE ? (
                <PositionSingleIcon
                  className={cn(wStyles.icon, {
                    [wStyles.active]: position === elem.value,
                  })}
                />
              ) : elem.value === SettingsPosition.DOUBLE_LEFT ? (
                <PositionDoubleLeftIcon
                  className={cn(wStyles.icon, {
                    [wStyles.active]: position === elem.value,
                  })}
                />
              ) : elem.value === SettingsPosition.DOUBLE_RIGHT ? (
                <PositionDoubleRightIcon
                  className={cn(wStyles.icon, {
                    [wStyles.active]: position === elem.value,
                  })}
                />
              ) : elem.value === SettingsPosition.DOUBLE_BOTTOM ? (
                <PositionDoubleBottomIcon
                  className={cn(wStyles.icon, {
                    [wStyles.active]: position === elem.value,
                  })}
                />
              ) : elem.value === SettingsPosition.SEPARATE ? (
                <PositionSingleIcon
                  className={cn(wStyles.icon, {
                    [wStyles.active]: position === elem.value,
                  })}
                />
              ) : null}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default React.memo(WidgetPosition)
