import React from 'react'
import cn from 'classnames'

import { EditIcon, Palette, TickIcon } from '../elements'
import { isDefaultTheme } from '../../helpers/isDefaultTheme'

import themeStyles from './styles/Theme.module.scss'

import type { ThemeInterface } from '@/store/reducers/models/interfaces/styles.interface'

import { getActiveTheme, getActiveThemeId, getStylesThemes } from 'store/selectors/stylesSelectors'
import { setActiveStyleThemeId } from 'store/reducers/styles'
import { useAppDispatch, useAppSelector } from 'store/store'
import { TrashIcon } from '@/widgets/OptionsWidget/modules/Settings/components/Themes/components/elements/EditThemePopup/elements'
import { getUseAuthorization } from '@/store/selectors/generalSelectors'

const Theme: React.FC<PropsType> = ({
  name,
  id,
  colors,
  setActiveEditThemePopup,
  setActiveDeleteThemePopup,
  isDefault,
  isSelected,
}) => {
  const dispatch = useAppDispatch()
  const activeTheme = useAppSelector(getActiveTheme)
  const theme = useAppSelector(getStylesThemes)
  const activeThemeId = useAppSelector(getActiveThemeId)
  const useAuthorization = useAppSelector(getUseAuthorization)

  // TODO check conditions
  const canEditTheme =
    (useAuthorization ? !isDefault : !isDefaultTheme(id)) && activeTheme.id === id
  const canDeleteTheme = useAuthorization ? !isDefault || !isSelected : !isDefaultTheme(id)

  React.useEffect(() => {
    if (activeTheme && activeTheme.id !== activeThemeId) {
      dispatch(setActiveStyleThemeId(activeTheme.id))
    }
  }, [activeTheme])

  const handleSetActiveTheme = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, objId: string) => {
    e.stopPropagation()
    dispatch(setActiveStyleThemeId(objId))
  }
  return (
    <div className={cn(themeStyles.theme)}>
      <div
        className={cn(themeStyles.theme__title, { [themeStyles.active]: activeTheme.id === id })}
      >
        {activeTheme.id === id ? (
          <div
            className={cn(themeStyles.theme__active_selector)}
            onClick={(e) => handleSetActiveTheme(e, id)}
          >
            <TickIcon />
          </div>
        ) : (
          <div
            className={cn(themeStyles.notActiveSelector)}
            onClick={(e) => handleSetActiveTheme(e, id)}
          />
        )}
        <h2>{`${name}`}</h2>
        {canEditTheme && (
          <span
            className={cn(themeStyles.theme__edit_icon, {
              [themeStyles.active]: activeTheme.id === id,
            })}
            onClick={() => {
              setActiveEditThemePopup(true)
            }}
          >
            <EditIcon />
          </span>
        )}
        {canDeleteTheme && (
          <span
            className={themeStyles.theme__delete_icon}
            onClick={() => {
              setActiveDeleteThemePopup(id)
            }}
          >
            <TrashIcon />
          </span>
        )}
      </div>
      <div className={themeStyles.theme__id}>ID: {id}</div>
      <Palette id={id} colors={colors} theme={theme} disabled={!canEditTheme} />
    </div>
  )
}

type PropsType = {
  id: ThemeInterface['id']
  name: ThemeInterface['name']
  colors: ThemeInterface['colors']
  isDefault: ThemeInterface['isDefault']
  isSelected: ThemeInterface['isSelected']
  setActiveEditThemePopup: (val: boolean) => void
  setActiveDeleteThemePopup: (val: string | null) => void
}

export default React.memo(Theme)
