import React from "react";
import cn from "classnames";
import styles from "./styles/Select.module.scss";
import { SportsArrowDropDownIcon } from "components/common/";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const SportsListSelect: React.FC<PropsType> = ({
  handleChange,
  toggleExpandedSelect,
  sportType,
  sportsList,
  expandedSelect,
  elemRef,
}) => {
  const sportTypeSelectRef = React.useRef<HTMLDivElement>(null);

  const handleSelectOutsideClick = React.useCallback((e: MouseEvent | any) => {
    const path = e.composedPath() || (e.composedPath && e.composedPath());
    if (!path.includes(sportTypeSelectRef.current)) {
      toggleExpandedSelect(false);
    }
  }, []);

  React.useEffect(() => {
    document.body.addEventListener("click", handleSelectOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleSelectOutsideClick);
    };
  }, [handleSelectOutsideClick]);

  return (
    <div className={cn(styles.select_wrapper)} ref={sportTypeSelectRef}>
      <div className={cn(styles.select)} onClick={() => toggleExpandedSelect(!expandedSelect)}>
        <span>{sportType.split('_').join(' ')}</span>
        <SportsArrowDropDownIcon
          className={cn(styles.arrow, {
            [styles.active]: expandedSelect,
          })}
        />
        <b className={cn(styles.label)}>Sport Type</b>
      </div>
      <SelectList
        elemRef={elemRef}
        expandedSelect={expandedSelect}
        sportsList={sportsList}
        sportType={sportType}
        handleChange={handleChange}
      />
    </div>
  );
};

const SelectList: React.FC<ownProps> = React.memo(
  ({ expandedSelect, sportsList, sportType, elemRef, handleChange }) => {
    React.useEffect(() => {
      if (expandedSelect) {
        !!elemRef && elemRef.style.setProperty("overflow", "hidden");
      }

      return () => {
        !!elemRef && elemRef.style.setProperty("overflow", "auto");
      };
    }, [expandedSelect, elemRef]);

    return (
      <ul
        className={cn(styles.select__list, {
          [styles.active]: expandedSelect,
        })}
      >
        <OverlayScrollbarsComponent>
          {sportsList.map((el: string, index: number) => {
            return (
              <li
                onClick={() => handleChange(el)}
                className={cn(styles.option, {
                  [styles.selected]: sportType === el,
                })}
                key={`${index}_${el}`}
              >
                <span>{el.split('_').join(' ')}</span>
              </li>
            );
          })}
        </OverlayScrollbarsComponent>
      </ul>
    );
  },
);

type ownProps = {
  sportsList: Array<string>;
  expandedSelect?: boolean;
  handleChange: (elem: string) => void;
  sportType: string;
  elemRef: HTMLDivElement | null;
};

type PropsType = {
  sportsList: Array<string>;
  handleChange: (elem: string) => void;
  sportType: string;
  expandedSelect: boolean;
  toggleExpandedSelect: (val: boolean) => void;
  elemRef: HTMLDivElement | null;
};

export default SportsListSelect;
