import React from 'react'
import cn from 'classnames'

import statStyles from './styles/StatisticTypes.module.scss'

import type { AllStatisticsTypes } from '@/store/reducers/models/types/settings.type'

import { getEventStatistics } from '@/store/selectors/settingsSelectors'
import { CheckedIcon, UncheckedIcon } from '@/widgets/OptionsWidget/modules/elements'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { setEventStatTypes } from '@/store/thunks/settingsThunks'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { sportsWidgetsMapConfig } from '../../config/sports-widgets-map-config'
import { AvailableStatisticsWidgets } from '@/store/reducers/models/enums/settings.enum'

const StatisticTypes = () => {
  const dispatch = useAppDispatch()
  const eventStatistics = useAppSelector(getEventStatistics)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const update = eventStatistics.map(item => {
      if (item.key === name) {
        return {
          ...item,
          value: checked,
        }
      }

      return item
    })

    if (update.every(s => s.value === false)) {
      const stat = update.find(stat => stat.key === AvailableStatisticsWidgets.STAT)
      stat && (stat.value = true)
    }

    dispatch(setEventStatTypes(update))
  }

  return (
    <div className={cn(statStyles.statistics__types)}>
      <h3 className={cn(statStyles.statistics__types_title)}>Stat Types</h3>
      <ul className={cn(statStyles.statistics__types_list)}>
        {
          eventStatistics.map((stat, index) => {
            const replacedKey = stat.name.replace('_', ' ')

            return (
              <li className={cn(statStyles.list_item)} key={`${stat.key}_${index}`}>
                <input
                  id={`${stat.key}_${index}`}
                  name={stat.key}
                  type='checkbox'
                  checked={stat.value}
                  onChange={handleChange}
                />
                <label htmlFor={`${stat.key}_${index}`}>
                  {stat.value === true ? <CheckedIcon /> : <UncheckedIcon />}
                </label>
                <span>{replacedKey}</span>
              </li>
            )
          })
        }
      </ul>
      <div className={cn(statStyles.statistics__types_info)}>
        <p>Default stats widget can be disabled when at least one other stat widget is enabled. If that stat will not be
          available in a match - default stats widget will be shown</p>
      </div>
    </div>
  )
}

export default React.memo(StatisticTypes)
