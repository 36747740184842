import type { StoreStateType } from './store'

export const saveToSessionStorage = (state: StoreStateType, key: string): void => {
  if (state) {
    const initialState = JSON.stringify(state)
    sessionStorage.setItem(key, initialState)
  }
}

export const loadFromSessionStorage = (key: string) => {
  try {
    const serializedState = sessionStorage.getItem(key)

    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (error) {
    return undefined
  }
}
