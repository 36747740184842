import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

import liveMode from './reducers/liveMode'
import styles from './reducers/styles'
import settings from './reducers/settings'
import languages from './reducers/languages'
import demoMode from './reducers/demoMode'
import general from './reducers/general'
import { loadFromSessionStorage, saveToSessionStorage } from './sessionStorage'

import type { TypedUseSelectorHook } from 'react-redux'

import { SessionStorage } from '@/proto/typescript/enums/session-storage.enum'

export const rootReducer = combineReducers({
  liveMode,
  demoMode,
  languages,
  styles,
  settings,
  general,
})

const initialState = loadFromSessionStorage(SessionStorage.STORE_STATE)

const store = configureStore({ reducer: rootReducer, preloadedState: initialState })

store.subscribe(() => {
  const storeState = {
    liveMode: { ...store.getState().liveMode, matches: {} },
    languages: store.getState().languages,
    styles: store.getState().styles,
    settings: store.getState().settings,
    general: store.getState().general,
  }

  saveToSessionStorage(storeState, SessionStorage.STORE_STATE)
})

type RootReducerType = typeof rootReducer

export type AppStateType = ReturnType<RootReducerType>
export type StoreStateType = Omit<AppStateType, 'demoMode'>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector

export default store
