import { createSelector } from 'reselect'

import type { AppStateType } from '../store'

import { getActiveSport } from '@/store/selectors/generalSelectors'

export const getSettings = (state: AppStateType) => state.settings

export const getStatisticsWidgetPosition = createSelector(getSettings, (state) => state.position)

export const getEventStatistics = createSelector(
  getSettings,
  getActiveSport,
  (state, event) => state.sports[event].statistics,
)

export const getEventHeader = createSelector(
  getSettings,
  getActiveSport,
  (state, event) => state.sports[event].header,
)

export const getEventOther = createSelector(
  getSettings,
  getActiveSport,
  (state, event) => state.sports[event].other,
)

/* export const getEventAnimations = createSelector(
  getSettings,
  getActiveSport,
  (state, event) => state.sports[event].animations,
) */
