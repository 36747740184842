import React, { useEffect } from 'react'
import cn from 'classnames'

import hStyles from './styles/Header.module.scss'
import { AnimationResolution } from './components'
import { ExportIcon, ImportIcon, SaveIcon } from './elements'

import { ActiveSports, WidgetMode } from '@/store/reducers/models/enums/general.enum'
import { getExistingSportType } from '@/store/selectors/liveModeSelectors'
import { getStyles } from 'store/selectors/stylesSelectors'
import { getSettings } from 'store/selectors/settingsSelectors'
import { setStyles } from 'store/reducers/styles'
import { setSettings } from 'store/reducers/settings'
import { setMode, setSport } from '@/store/reducers/general'
import {
  getActiveMode,
  getActiveSport,
  getUseAuthorization,
} from '@/store/selectors/generalSelectors'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { useLiveSocket } from '@/widgets/MatchSelectorWidget/hooks/useLiveSocket'
import { setModeThunk } from '@/store/thunks/settingsThunks'
import { saveChangesToBackOffice } from '@/store/thunks/backOfficeThunks'

const IS_DEMO_ENV = process.env.REACT_APP_DEMO_MODE;

const Header: React.FC = () => {
  const dispatch = useAppDispatch()

  const styles = useAppSelector(getStyles)
  const settings = useAppSelector(getSettings)
  const widgetMode = useAppSelector(getActiveMode)
  const existingSportType = useAppSelector(getExistingSportType)
  const activeSport = useAppSelector(getActiveSport)
  const useAuthorization = useAppSelector(getUseAuthorization)

  const { position, sports } = settings

  const customConfig = {
    styles: {
      ...styles,
    },
    settings: {
      position,
      sports,
    },
  }

  useEffect(() => {
    dispatch(setModeThunk(widgetMode))
  }, [])

  const { subscribeToSport, unsubscribeFromAll } = useLiveSocket(activeSport)

  const handleSetLiveMode = (e: React.MouseEvent<HTMLElement>) => {
    if (IS_DEMO_ENV) return;

    if (existingSportType) {
      dispatch(setSport(activeSport))
      subscribeToSport(activeSport)
    } else {
      dispatch(setSport(ActiveSports.FOOTBALL))
      subscribeToSport(ActiveSports.FOOTBALL)
    }

    dispatch(setModeThunk(e.currentTarget.id as WidgetMode))
  }

  const handleSetDemoMode = (e: React.MouseEvent<HTMLElement>) => {
    unsubscribeFromAll()
    dispatch(setSport(existingSportType ? activeSport : ActiveSports.FOOTBALL))
    dispatch(setModeThunk(e.currentTarget.id as WidgetMode))
  }

  const handleFileChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]

    const reader = new FileReader()

    reader.readAsText(file)

    reader.onload = function () {
      const config = JSON.parse(JSON.parse(JSON.stringify(reader.result)))
      dispatch(setSettings(config.settings))
      dispatch(setStyles(config.styles))
      target.value = ''
    }
  }

  const handleSave = () => {
    dispatch(saveChangesToBackOffice())
  }

  return (
    <header className={cn(hStyles.header)}>
        {
            !IS_DEMO_ENV
            && <div className={cn(hStyles.header__btn_state_wrapper)}>
            <button
                id='demo'
                className={cn(hStyles.header__state_btn, {
                    [hStyles.active]: widgetMode === WidgetMode.DEMO,
                })}
                onClick={handleSetDemoMode}
            >
                Demo
            </button>
            <button
                id='live'
                className={cn(hStyles.header__state_btn, {
                    [hStyles.active]: widgetMode === WidgetMode.LIVE,
                })}
                onClick={handleSetLiveMode}
            >
                Live
            </button>
        </div>
        }
        <div className={cn(hStyles.header__animation_resolution_wrapper)}>
            <AnimationResolution/>
        </div>
        {
            !IS_DEMO_ENV
            && <div className={cn(hStyles.header__button_right_wrapper)}>
                {/* <div className={cn(hStyles.header__btn)}>
          <ExportIcon />
          <a
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(customConfig),
            )}`}
            download='config.json'
          >
            Export Config
          </a>
        </div>
        <div className={cn(hStyles.header__btn)}>
          <ImportIcon />
          <label htmlFor='import-config'>Import Config</label>
          <input
            className={cn(hStyles.header__input)}
            onChange={handleFileChange}
            type='file'
            name='importConfig'
            id='import-config'
            accept='application/JSON'
          />
        </div> */}
                {useAuthorization && (
                    <div className={cn(hStyles.header__btn)} onClick={handleSave}>
                        <SaveIcon/>
                        <span>Save</span>
                    </div>
                )}
            </div>
        }
    </header>
  )
}
export default React.memo(Header)
