import React, {useRef} from 'react'
import cn from 'classnames'

import { PlayIcon } from '../../elements'

import styles from './styles/DemoSportEvents.module.scss'

import { useAppDispatch, useAppSelector } from 'store/store'
import { fetchActiveEvent, fetchResetDemo } from '@/store/thunks/demoModeThunks'
import { getDemoMode, getSelectedSportEventsList } from '@/store/selectors/demoModeSelectors'
import {getSelectedLanguage} from "@/store/selectors/languagesSelectors";

const defaultDemoMatch = {
  value: 'Launch_Demo_Match',
  id: 999999,
}

const DemoSportEvents: React.FC<PropsType> = ({ setActiveEventIndex, activeEventIndex }) => {
  const dispatch = useAppDispatch()

  const demoMode = useAppSelector(getDemoMode)
  const selectedSportEventsList = useAppSelector(getSelectedSportEventsList)
  const selectedLanguage = useAppSelector(getSelectedLanguage)

  const [typeEventID, setTypeEventId] = React.useState<number>(0)
  const isMounted = useRef(true);

  let eventsList: { value: string; id: number }[] = [defaultDemoMatch, ...selectedSportEventsList]

  React.useEffect(() => {
    if (selectedLanguage && !isMounted.current) {
        dispatch(fetchResetDemo(demoMode.demoSessionId));
        setActiveEventIndex(0)
      }

    if (isMounted.current) isMounted.current = false;

  }, [selectedLanguage]);

  const handleClick = (type: number | string, index: number) => {
    setActiveEventIndex(index)
    setTypeEventId(+type);

    if (type === 999999) {
      dispatch(fetchResetDemo(demoMode.demoSessionId))
    } else {
      dispatch(fetchActiveEvent({ eventID: type, uuid: demoMode.demoSessionId }))
    }
  }

  return (
    <ul className={cn(styles.events_list)}>
      {eventsList.map((elem, index) => {
        const name = elem.value
          .split('_')
          .map((element) => element.charAt(0).toUpperCase() + element.substring(1).toLowerCase())
          .join(' ')

        const value = elem.id
        if (index === 0) {
          return (
            <li
              className={cn(styles.events_list__item, {
                [styles.active]: activeEventIndex === index,
              })}
              key={`${name}_${index}`}
              onClick={() => handleClick(value, index)}
            >
              <span>{name}</span>
              <PlayIcon className={cn(styles.play_btn)} />
            </li>
          )
        }
        return (
          <li
            className={cn(styles.events_list__item, {
              [styles.active]: activeEventIndex === index,
            })}
            key={`${name}_${index}`}
            onClick={() => handleClick(value, index)}
          >
            <span>{name}</span>
            <PlayIcon className={cn(styles.play_btn)} />
          </li>
        )
      })}
    </ul>
  )
}

type PropsType = {
  activeEventIndex: number
  setActiveEventIndex: (index: number) => void
}

export default React.memo(DemoSportEvents)
