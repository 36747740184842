import React, { useEffect } from 'react'

import AnimationWidget from '../AnimationWidget/AnimationWidget'
import { convertSettingsToV2 } from '../helpers/convertSettingsToV2'
import { convertStylesToV2 } from '../helpers/convertStylesToV2'

import { getSettings } from '@/store/selectors/settingsSelectors'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { getStyles } from '@/store/selectors/stylesSelectors'
import { getIsMatchesLoaded, getSelectedMatchId } from '@/store/selectors/liveModeSelectors'
import { getLanguages } from '@/store/selectors/languagesSelectors'
import { useAppSelector } from '@/store/store'

const LiveWidget: React.FC = React.memo(() => {
  const settings = useAppSelector(getSettings)
  const sport = useAppSelector(getActiveSport)
  const stateStyles = useAppSelector(getStyles)
  const languages = useAppSelector(getLanguages)
  const selectedMatch = useAppSelector(getSelectedMatchId)

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && settings && stateStyles) {
      const newSettings = convertSettingsToV2(settings)
      const newStyles = convertStylesToV2(stateStyles)
      const newConfig = {
        styles: newStyles,
        settings: newSettings,
      }
      console.log('admin configuration', newConfig)
      sc('setConfiguration', newConfig)
    }
  }, [settings, stateStyles])

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && languages) {
      console.log('admin languages', languages)
      sc('setLanguage', languages.selectedLanguage)
    }
  }, [languages])

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && selectedMatch) {
      console.log('admin liveMode', selectedMatch)
      sc('setMatchID', selectedMatch)
    }
  }, [selectedMatch])

  return selectedMatch ? <AnimationWidget matchID={selectedMatch} /> : null
})

export default LiveWidget
