import React from "react";

const PositionSingleIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}
    >
      <path
        d="M7.14285 8.33398H32.857C33.4253 8.33398 33.9704 8.54125 34.3722 8.91019C34.7741 9.27913 34.9999 9.77951 34.9999 10.3013V29.7C34.9999 30.2218 34.7741 30.7222 34.3722 31.0911C33.9704 31.46 33.4253 31.6673 32.857 31.6673H7.14285C6.57453 31.6673 6.02949 31.46 5.62763 31.0911C5.22576 30.7222 5 30.2218 5 29.7V10.3013C5 9.77951 5.22576 9.27913 5.62763 8.91019C6.02949 8.54125 6.57453 8.33398 7.14285 8.33398ZM32.857 29.7V10.3013H7.14285V29.7H32.857ZM13.9583 33.334C13.6132 33.334 13.3333 33.6138 13.3333 33.959C13.3333 34.3042 13.6132 34.584 13.9583 34.584H16.3447C16.6899 34.584 16.9697 34.3042 16.9697 33.959C16.9697 33.6138 16.6899 33.334 16.3447 33.334H13.9583ZM18.8068 33.334C18.4616 33.334 18.1818 33.6138 18.1818 33.959C18.1818 34.3042 18.4616 34.584 18.8068 34.584H21.1932C21.5384 34.584 21.8182 34.3042 21.8182 33.959C21.8182 33.6138 21.5384 33.334 21.1932 33.334H18.8068ZM23.0303 33.959C23.0303 33.6138 23.3101 33.334 23.6553 33.334H26.0417C26.3868 33.334 26.6667 33.6138 26.6667 33.959C26.6667 34.3042 26.3868 34.584 26.0417 34.584H23.6553C23.3101 34.584 23.0303 34.3042 23.0303 33.959Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default PositionSingleIcon;
