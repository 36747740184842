import React, { useEffect } from 'react'
import cn from 'classnames'

import styles from './styles/MatchSelector.module.scss'
import { SportTypeEvent } from './components'
import { NoLiveMatchesPopup } from './components/elements'

import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

import {
  getTournaments,
  getLiveModeMatches,
  getSelectedMatchId,
  getSportsList,
  getIsMatchesLoaded,
  getExistingSportType,
} from '@/store/selectors/liveModeSelectors'
import { Preloader, SectionCaption, SportsListSelect } from 'components/common'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { setSport } from '@/store/reducers/general'
import { useLiveSocket } from '@/widgets/MatchSelectorWidget/hooks/useLiveSocket'

const MatchSelector: React.FC = () => {
  const dispatch = useAppDispatch()

  const sportsList = useAppSelector(getSportsList)
  const liveMatches = useAppSelector(getLiveModeMatches)
  const selectedSportType = useAppSelector(getActiveSport)
  const tournaments = useAppSelector(getTournaments)
  const selectedMatchId = useAppSelector(getSelectedMatchId)
  const isMatchesLoaded = useAppSelector(getIsMatchesLoaded)
  const existingSportType = useAppSelector(getExistingSportType)

  const [activeElem, setActiveElem] = React.useState<boolean>(true)
  const [expandedSelect, toggleExpandedSelect] = React.useState<boolean>(false)
  const [activeEventName, setActiveEventName] = React.useState<string | undefined>(undefined)
  const matchSelectorRef = React.useRef<HTMLDivElement>(null)

  const { subscribeToSport, unsubscribeFromAll } = useLiveSocket(selectedSportType)

  const handleChange = React.useCallback((elem: string) => {
    toggleExpandedSelect(false)
    unsubscribeFromAll()
    dispatch(setSport(elem as ActiveSports))
    subscribeToSport(elem)
  }, [])

  const activePopup = !existingSportType || (!Object.values(liveMatches).length && isMatchesLoaded)

  useEffect(() => {
    setActiveElem(!activePopup)
  }, [activePopup])

  const onTournamentNameChange = (name: string | undefined): void => {
    setActiveEventName(name || Object.keys(tournaments)[0] || '');
  }

  return (
    <section
      ref={matchSelectorRef}
      className={cn(styles.match_selector, {
        [styles.expanded]: activeElem,
      })}
    >
      <SectionCaption
        captionTitle='Match Selector'
        activeElem={activeElem}
        setActiveElem={setActiveElem}
        captionPosition='left'
      />

      {activeElem && sportsList.length ? (
        <SportsListSelect
          expandedSelect={expandedSelect}
          toggleExpandedSelect={toggleExpandedSelect}
          sportType={selectedSportType}
          handleChange={handleChange}
          sportsList={sportsList}
          elemRef={matchSelectorRef.current}
        />
      ) : activeElem && !sportsList.length ? (
        <Preloader />
      ) : null}

      {activeElem && existingSportType ? (
        <SportTypeEvent
          liveMatches={liveMatches}
          tournaments={tournaments}
          activeEventName={activeEventName}
          setActiveEventName={onTournamentNameChange}
          selectedMatch={selectedMatchId}
        />
      ) : null}

      {activePopup ? <NoLiveMatchesPopup /> : null}
    </section>
  )
}

export default React.memo(MatchSelector)
