// @ts-nocheck
import { FC, memo, useEffect, useState } from 'react'
import styles from './AnimationWidget.module.scss';
import { useAppSelector } from '@/store/store'
import { getStatisticsWidgetPosition } from '@/store/selectors/settingsSelectors'
import { SettingsPosition } from '@/store/reducers/models/enums/settings.enum'

const LOGGER_STATUS_KEY = 'LOGGER_STATUS'

const AnimationWidget: FC<{ matchID?: string }> = memo(({ matchID }) => {
  const [isShow, setIsShow] = useState(true)
  const [isLoggerOn, setIsLoggerOn] = useState<boolean | null>(null)
  const [statisticsWidth, setStatisticsWidth] = useState<1 | 0.5>(1);
  const position = useAppSelector(getStatisticsWidgetPosition)

  useEffect(() => {
    try {
      let statusValue = false
      const status = JSON.parse(localStorage.getItem(LOGGER_STATUS_KEY))
      if (typeof status === 'boolean') {
        statusValue = status
      }
      if (window.SCAW) {
        window.SCAW('setLoggerStatus', statusValue)
        window.SCAW('useClientConfiguration', true)
      }
      setIsLoggerOn(statusValue)
    } catch (_) {
      if (window.SCAW) {
        window.SCAW('setLoggerStatus', false)
      }
      setIsLoggerOn(false)
    }
  }, [])

  function switchWidgetVisibility(): void {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && !!matchID && !isShow) {
      sc('setMatchID', matchID)
    }

    setIsShow((prev) => !prev)
  }

  function switchLoggerStatus(): void {
    localStorage.setItem(LOGGER_STATUS_KEY, JSON.stringify(!isLoggerOn))
    window.location.reload()
  }

  function changeStatisticsWidth(): void {
    setStatisticsWidth((v) => v > 0.5 ? 0.5 : 1);
  }

  return (
    <>
      {
        process.env.REACT_APP_ENVIRONMENT === 'dev'
          ? <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <button type={'button'} onClick={switchWidgetVisibility}>{isShow ? 'Hide' : 'Show'}</button>
            <button type={'button'} onClick={switchLoggerStatus}>Logger {isLoggerOn ? 'On' : 'Off'}</button>
            {position === SettingsPosition.SEPARATE ? <button type={'button'} onClick={changeStatisticsWidth}>Change Stat width</button> : null}
          </div>
          : null
      }
      {
        (isShow && isLoggerOn !== null) ?
          <>
            <sc-animation-component style={{ display: 'block', marginBottom: '10px'}}></sc-animation-component>
            <sc-animation-component-statistic style={{display: 'block', width: `${statisticsWidth * 100}%`}}></sc-animation-component-statistic>
          </>
          : null
      }
    </>
  )
})

export default AnimationWidget
