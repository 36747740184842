import React from 'react'
import cn from 'classnames'

import hStyles from './styles/Header.module.scss'
import { HeaderTypes } from './components'

import { getEventHeader } from 'store/selectors/settingsSelectors'
import { OptionsArrowDropDownIcon } from 'components/common'
import { CheckedIcon, UncheckedIcon } from 'widgets/OptionsWidget/modules/elements'
import { useAppDispatch, useAppSelector } from 'store/store'
import { HeaderWidgets } from '@/store/reducers/models/enums/settings.enum'
import { setEventHeaderTypes } from '@/store/thunks/settingsThunks'

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const eventHeader = useAppSelector(getEventHeader)

  const [expandedElem, toggleExpandedElem] = React.useState<boolean>(false)

  const isChecked = eventHeader.every(s => !!s.value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target

    const nextHeaderState = checked
      ? eventHeader.map(item => ({...item, value: true}))
      : eventHeader.map(item => ({...item, value: false}))
    dispatch(setEventHeaderTypes(nextHeaderState))
  }

  return (
    <div
      className={cn(hStyles.header, {
        [hStyles.expanded]: expandedElem,
      })}
      style={{ marginBottom: !expandedElem ? '5px' : '' }}
    >
      <div
        className={cn(hStyles.header__title)}
        key='evHeader'
        onClick={() => toggleExpandedElem(!expandedElem)}
      >
        <input
          id='evHeader'
          name='evHeader'
          type='checkbox'
          checked={isChecked}
          onChange={handleChange}
        />
        <label htmlFor='evHeader'>{isChecked ? <CheckedIcon /> : <UncheckedIcon />}</label>
        <h2>Header</h2>

        <OptionsArrowDropDownIcon
          className={cn(hStyles.arrow, {
            [hStyles.rotated]: expandedElem,
          })}
        />
      </div>
      <HeaderTypes />
    </div>
  )
}
export default React.memo(Header)
