import React, { useMemo } from 'react'
import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import lStyles from './styles/LanguageSelect.module.scss'

import type { LanguagesListInterface } from '@/store/reducers/models/interfaces/languages.interface'

import { LanguageCaptionIcon } from '@/widgets/OptionsWidget/modules/elements'
import { setLanguages, setSelectedLanguage } from 'store/reducers/languages'
import { getLanguagesList, getSelectedLanguage } from '@/store/selectors/languagesSelectors'
import { OptionsArrowDropDownIcon } from 'components/common'
import { useAppDispatch, useAppSelector } from 'store/store'

const LanguageSelect: React.FC = () => {
  const dispatch = useAppDispatch()
  const languagesList = useAppSelector(getLanguagesList)
  const selectedLanguage = useAppSelector(getSelectedLanguage)

  const [expanded, setExpanded] = React.useState(true)

  React.useEffect(() => {
    dispatch(setLanguages(languagesList))
  }, [dispatch, languagesList])

  const handleSelectActiveLang = (elemLang: string): void => {
    dispatch(setSelectedLanguage(elemLang))
  }

  const activeLanguage = useMemo(() => {
    const language = languagesList.find(
      (elem: LanguagesListInterface) => elem.id === selectedLanguage,
    )

    return language ? language.name : 'Not selected'
  }, [selectedLanguage, languagesList])

  return (
    <div
      className={cn(lStyles.language_select, {
        [lStyles.expanded]: expanded,
      })}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className={cn(lStyles.item, lStyles.title, {
          [lStyles.active]: selectedLanguage,
        })}
      >
        <LanguageCaptionIcon />
        <span>{activeLanguage}</span>

        <OptionsArrowDropDownIcon
          className={cn(lStyles.arrow, {
            [lStyles.rotated]: !expanded,
            [lStyles.active]: selectedLanguage,
          })}
        />
      </div>
      <OverlayScrollbarsComponent>
        <ul>
          {languagesList.map((elem: LanguagesListInterface, index: number) => {
            const { name, id } = elem
            return (
              <li
                onClick={() => handleSelectActiveLang(id)}
                className={cn(lStyles.item, {
                  [lStyles.selected]: selectedLanguage === id,
                })}
                key={`${name}_${id}_${index}`}
              >
                <span>{name}</span>
              </li>
            )
          })}
        </ul>
      </OverlayScrollbarsComponent>
    </div>
  )
}

export default React.memo(LanguageSelect)
