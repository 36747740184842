import React from "react";

const PositionDoubleRightIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}
    >
      <path
        d="M7.14286 31.666L32.8571 31.666C33.4255 31.666 33.9705 31.4769 34.3724 31.1403C34.7742 30.8037 35 30.3472 35 29.8711L35 10.1275C35 9.6515 34.7742 9.19499 34.3724 8.85838C33.9705 8.52178 33.4255 8.33269 32.8571 8.33269L7.14285 8.33268C6.57453 8.33268 6.02949 8.52178 5.62762 8.85838C5.22576 9.19499 5 9.6515 5 10.1275L5 29.8711C5 30.3472 5.22577 30.8037 5.62763 31.1403C6.02949 31.4769 6.57454 31.666 7.14286 31.666ZM32.8571 29.8711L15.7143 29.8711L15.7143 10.1275L32.8571 10.1275L32.8571 29.8711ZM7.14285 10.1275L13.5714 10.1275L13.5714 29.8711L7.14286 29.8711L7.14285 10.1275Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default PositionDoubleRightIcon;
