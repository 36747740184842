import React from 'react'
import cn from 'classnames'

import { AnimationIframe } from './modules'
import styles from './styles/MainWidget.module.scss'

import { getWidgetWidth } from '@/store/selectors/generalSelectors'
import { useAppSelector } from '@/store/store'

const MainWidget: React.FC = () => {
  const width = useAppSelector(getWidgetWidth)

  return (
    <section style={{ width: `${width}px` }} className={cn(styles.main_widget)}>
      <AnimationIframe />
    </section>
  )
}

export default React.memo(MainWidget)
