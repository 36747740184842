import React from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import {
  HeaderWidget,
  OptionsWidget,
  MatchSelectorWidget,
  DemoModeWidget,
  MainWidget,
} from '../../widgets'

import styles from './styles/AdminPanelWrapper.module.scss'

import { getActiveMode } from '@/store/selectors/generalSelectors'
import { WidgetMode } from '@/store/reducers/models/enums/general.enum'

const AdminPanelWrapper: React.FC = React.memo(() => {
  const widgetMode = useSelector(getActiveMode)

  const liveWidgetMode = widgetMode === WidgetMode.LIVE
  const demoWidgetMode = widgetMode === WidgetMode.DEMO
  return (
    <div className={cn(styles.adminPanelWrapper)}>
      <HeaderWidget />
      <main className={cn('main', 'container')}>
        {liveWidgetMode && <MatchSelectorWidget />}
        {demoWidgetMode && <DemoModeWidget />}
        <MainWidget />
        <OptionsWidget />
      </main>
    </div>
  )
})

export default AdminPanelWrapper
