import type {
  StylesInterface,
  ThemeInterface,
} from '@/store/reducers/models/interfaces/styles.interface'
import type {
  SettingsInterface,
} from '@/store/reducers/models/interfaces/settings.interface'
import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

import {
  SportsWidgetInterface,
  SportWidgetInterface,
  WidgetItemInterface,
} from '@/store/reducers/models/interfaces/settings.interface'
import {
  BOSportWidgetInterface
} from '@/widgets/MainWidget/modules/AnimationIframe/components/models/interfaces/bo-sport-widget.interface'

export interface BackOfficeThemeInterface {
  id: string
  is_default: boolean
  is_selected: boolean
  created_at?: string
  updated_at?: string
  name: string
  colors_teams_home: string
  colors_teams_away: string
  colors_teams_none: string
  colors_backgrounds_main: string
  colors_backgrounds_additional: string
  colors_fonts_main: string
  colors_fonts_sub_text: string
  colors_active_selected: string
  colors_active_text: string
}

export interface BackOfficeStylesForUpdateInterface {
  themes: BackOfficeThemeForUpdateInterface[]
  selected_theme: string
}

export interface BackOfficeThemeForUpdateInterface {
  id: string
  name: string
  colors: {
    home: string
    away: string
    none: string
  }
  backgrounds: {
    main: string
    additional: string
  }
  fonts: {
    main: string
    sub_text: string
  }
  active: {
    selected: string
    text: string
  }
}

export type SportAttribute = { [key: string]: boolean }

export interface BackOfficeSportUpdateDTO {
  sport_name: string;
  header: SportAttribute,
  statistic: SportAttribute,
  other: SportAttribute
}

export interface BackOfficeConfigUpdateDTO {
  position: string
  sports: BackOfficeSportUpdateDTO[]
}

export const convertThemeFromBackOfficeToStore = (
  backOfficeTheme: BackOfficeThemeInterface,
): ThemeInterface => {
  const convertedTheme: ThemeInterface = {
    id: '',
    name: '',
    isDefault: false,
    isSelected: false,
    colors: {
      teams: { home: '', away: '', none: '' },
      active: { selected: '', text: '' },
      backgrounds: { main: '', additional: '' },
      fonts: { main: '', sub_text: '' },
    },
  }

  convertedTheme.id = backOfficeTheme.id
  convertedTheme.name = backOfficeTheme.name
  convertedTheme.isDefault = backOfficeTheme.is_default
  convertedTheme.isSelected = backOfficeTheme.is_selected

  convertedTheme.colors.teams.away = backOfficeTheme.colors_teams_away
  convertedTheme.colors.teams.home = backOfficeTheme.colors_teams_home
  convertedTheme.colors.teams.none = backOfficeTheme.colors_teams_none

  convertedTheme.colors.active.selected = backOfficeTheme.colors_active_selected
  convertedTheme.colors.active.text = backOfficeTheme.colors_active_text

  convertedTheme.colors.backgrounds.additional = backOfficeTheme.colors_backgrounds_additional
  convertedTheme.colors.backgrounds.main = backOfficeTheme.colors_backgrounds_main

  convertedTheme.colors.fonts.main = backOfficeTheme.colors_fonts_main
  convertedTheme.colors.fonts.sub_text = backOfficeTheme.colors_fonts_sub_text

  return convertedTheme
}

export const convertStylesFromBackOfficeToStore = (
  backOfficeThemes: BackOfficeThemeInterface[],
): StylesInterface => {
  const convertedThemes = backOfficeThemes.map((theme) => convertThemeFromBackOfficeToStore(theme))

  return {
    themes: convertedThemes,
    selectedThemeId: convertedThemes.find((theme) => theme.isSelected)?.id || '',
  }
}

export const convertStylesFromStoreToBackOffice = (
  styles: StylesInterface,
): BackOfficeStylesForUpdateInterface => {
  const convertedThemes = styles.themes.map((theme) => {
    const convertedTheme: BackOfficeThemeForUpdateInterface = {
      id: '',
      name: '',
      colors: { home: '', away: '', none: '' },
      backgrounds: { main: '', additional: '' },
      fonts: { main: '', sub_text: '' },
      active: { selected: '', text: '' },
    }

    convertedTheme.id = theme.id
    convertedTheme.name = theme.name
    convertedTheme.colors = theme.colors.teams
    convertedTheme.backgrounds = theme.colors.backgrounds
    convertedTheme.fonts = theme.colors.fonts
    convertedTheme.active = theme.colors.active

    return convertedTheme
  })

  return {
    themes: convertedThemes,
    selected_theme: styles.selectedThemeId,
  }
}

export const convertThemeFromStoreToBackOffice = (
  storeTheme: ThemeInterface,
): BackOfficeThemeInterface => {
  const convertedTheme = {
    id: '',
    is_default: false,
    is_selected: false,
    name: '',
    colors_teams_away: '',
    colors_teams_home: '',
    colors_teams_none: '',
    colors_active_selected: '',
    colors_active_text: '',
    colors_backgrounds_additional: '',
    colors_backgrounds_main: '',
    colors_fonts_main: '',
    colors_fonts_sub_text: '',
  }

  convertedTheme.id = storeTheme.id
  convertedTheme.is_default = storeTheme.isDefault
  convertedTheme.is_selected = storeTheme.isSelected
  convertedTheme.name = storeTheme.name

  convertedTheme.colors_teams_away = storeTheme.colors.teams.away
  convertedTheme.colors_teams_home = storeTheme.colors.teams.home
  convertedTheme.colors_teams_none = storeTheme.colors.teams.none

  convertedTheme.colors_active_selected = storeTheme.colors.active.selected
  convertedTheme.colors_active_text = storeTheme.colors.active.text

  convertedTheme.colors_backgrounds_additional = storeTheme.colors.backgrounds.additional
  convertedTheme.colors_backgrounds_main = storeTheme.colors.backgrounds.main

  convertedTheme.colors_fonts_main = storeTheme.colors.fonts.main
  convertedTheme.colors_fonts_sub_text = storeTheme.colors.fonts.sub_text

  return convertedTheme
}

export const convertSportsSettingsFromBackOfficeToStore = (backOfficeSportsSettings: BOSportWidgetInterface[]): SportsWidgetInterface => {
  const convertedSportsSettings = backOfficeSportsSettings.map((backOfficeSport) => {

    const statistics: WidgetItemInterface[] = backOfficeSport.attributes
      .filter(attr => attr.widget_group === 'statistic')
      .map(attr => ({
        value: attr.value,
        group: attr.widget_group,
        key: attr.name,
        name: attr.name,
      }))

    const header: WidgetItemInterface[] = backOfficeSport.attributes
      .filter(attr => attr.widget_group === 'header')
      .map(attr => ({
        value: attr.value,
        group: attr.widget_group,
        key: attr.name,
        name: attr.name,
      }))

    const other: WidgetItemInterface[] = backOfficeSport.attributes
      .filter(attr => attr.widget_group === 'other')
      .map(attr => ({
        value: attr.value,
        group: attr.widget_group,
        key: attr.name,
        name: attr.name,
      }))

    return [
      backOfficeSport.sport_name as ActiveSports,
      {
        statistics,
        header,
        other,
      },
    ]
  })

  return Object.fromEntries(convertedSportsSettings)
}

export const convertSettingsToBO = (settings: SettingsInterface): BackOfficeConfigUpdateDTO => {
  const convertedSports: BackOfficeSportUpdateDTO[] = Object.entries(settings.sports).map(([sport, widgets]: [string, SportWidgetInterface]) => {
    const sportStatistic: SportAttribute = widgets.statistics.reduce((acc: any, curr: WidgetItemInterface) => (acc[curr.key] = curr.value, acc), {})
    const sportHeader: SportAttribute = widgets.header.reduce((acc: any, curr: WidgetItemInterface) => (acc[curr.key] = curr.value, acc), {})
    const sportOther: SportAttribute = widgets.other.reduce((acc: any, curr: WidgetItemInterface) => (acc[curr.key] = curr.value, acc), {})

    return {
      sport_name: sport,
      statistic: sportStatistic,
      header: sportHeader,
      other: sportOther,
    }
  })

  return {
    position: settings.position,
    sports: convertedSports,
  }
}
