import React from "react";

const RangeBubbleIcon: React.FC = () => {
  return (
      <svg width="66" height="40" viewBox="0 0 66 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3895_696)">
          <rect x="3" y="1" width="60" height="30.8298" rx="7" fill="#00BFFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M33 34.9999L33.0002 35V34.9998L40.6594 31.0958H33.0002H32.9998H25.3406L32.9998 34.9998V35L33 34.9999Z" fill="#00BFFE"/>
        </g>
        <defs>
          <filter id="filter0_d_3895_696" x="0" y="0" width="66" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="1.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3895_696"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3895_696" result="shape"/>
          </filter>
        </defs>
      </svg>
  );
};

export default RangeBubbleIcon;
