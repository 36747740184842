import { createSlice } from '@reduxjs/toolkit'

import type {
  MatchIdInterface,
  LiveModeMatchInterface,
} from './models/interfaces/liveMode/liveModeMatchInterface'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { LiveModeInterface } from '@/store/reducers/models/interfaces/liveMode/liveMode.interface'
import type { ActiveSports } from '@/store/reducers/models/enums/general.enum'

const initialState: LiveModeInterface = {
  sports: [],
  matches: {},
  selectedMatchId: '',
  isMatchesLoaded: false,
}

const liveModeSlice = createSlice({
  name: 'liveMode',
  initialState,
  reducers: {
    setSportsList(state, action: PayloadAction<ActiveSports[]>) {
      state.sports = action.payload
    },
    updateMatch(state, action: PayloadAction<LiveModeMatchInterface>) {
      const match = action.payload
      const { id } = match.match.matchId
      state.matches[id] = match
    },
    deleteMatch(state, action: PayloadAction<MatchIdInterface['id']>) {
      const id = action.payload

      delete state.matches[id]
    },
    setSelectedMatch(state, action: PayloadAction<string>) {
      state.selectedMatchId = action.payload
    },
    setIsMatchesLoaded(state, action: PayloadAction<boolean>) {
      state.isMatchesLoaded = action.payload
    },
    clearMatches(state) {
      state.matches = {}
      state.selectedMatchId = ''
      state.isMatchesLoaded = false
    },
  },
})
export const {
  setIsMatchesLoaded,
  setSportsList,
  updateMatch,
  deleteMatch,
  setSelectedMatch,
  clearMatches,
} = liveModeSlice.actions
export default liveModeSlice.reducer
