import React from "react";

const CaptionArrow: React.FC<PropsType> = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}>
      <path fill="#7166F9" fillRule="evenodd" clipRule="evenodd"
            d="M9.21015 6.23524C9.5031 5.92159 9.98917 5.92159 10.2821 6.23524L15.4898 11.8111C15.7701 12.1111 15.7701 12.5889 15.4898 12.8889L10.2821 18.4648C10.1401 18.6168 9.95147 18.7 9.74614 18.7C9.5408 18.7 9.3522 18.6168 9.21015 18.4648C8.92995 18.1647 8.92995 17.687 9.21015 17.387L13.9146 12.35L9.21015 7.31304C8.92995 7.01302 8.92995 6.53525 9.21015 6.23524Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default CaptionArrow;
