// source: running_ball.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var id_pb = require("./id_pb.js");
goog.object.extend(proto, id_pb);
goog.exportSymbol("proto.BallPosition", null, global);
goog.exportSymbol("proto.GameEvent", null, global);
goog.exportSymbol("proto.GameEventType", null, global);
goog.exportSymbol("proto.StatItem", null, global);
goog.exportSymbol("proto.StatLog", null, global);
goog.exportSymbol("proto.StatLogItem", null, global);
goog.exportSymbol("proto.StatLogItem.StatLogType", null, global);
goog.exportSymbol("proto.Timer", null, global);
goog.exportSymbol("proto.Timer.Direction", null, global);
goog.exportSymbol("proto.Timer.State", null, global);
goog.exportSymbol("proto.Timer.Timer_Scope", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GameEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GameEvent.repeatedFields_, null);
};
goog.inherits(proto.GameEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GameEvent.displayName = "proto.GameEvent";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatItem = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatItem.displayName = "proto.StatItem";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatLogItem = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatLogItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatLogItem.displayName = "proto.StatLogItem";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatLog = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.StatLog.repeatedFields_, null);
};
goog.inherits(proto.StatLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatLog.displayName = "proto.StatLog";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BallPosition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BallPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BallPosition.displayName = "proto.BallPosition";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Timer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Timer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Timer.displayName = "proto.Timer";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GameEvent.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GameEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.GameEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GameEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GameEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        statId: (f = msg.getStatId()) && id_pb.ID.toObject(includeInstance, f),
        infoList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
        team: jspb.Message.getFieldWithDefault(msg, 1, 0),
        type: jspb.Message.getFieldWithDefault(msg, 2, 0),
        ballPosition:
          (f = msg.getBallPosition()) && proto.BallPosition.toObject(includeInstance, f),
        ts: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GameEvent}
 */
proto.GameEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GameEvent();
  return proto.GameEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GameEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GameEvent}
 */
proto.GameEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setStatId(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.addInfo(value);
        break;
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTeam(value);
        break;
      case 2:
        var value = /** @type {!proto.GameEventType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 4:
        var value = new proto.BallPosition();
        reader.readMessage(value, proto.BallPosition.deserializeBinaryFromReader);
        msg.setBallPosition(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GameEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GameEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GameEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f != null) {
    writer.writeMessage(3, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedString(5, f);
  }
  f = message.getTeam();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getBallPosition();
  if (f != null) {
    writer.writeMessage(4, f, proto.BallPosition.serializeBinaryToWriter);
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(6, f);
  }
};

/**
 * optional ID stat_id = 3;
 * @return {?proto.ID}
 */
proto.GameEvent.prototype.getStatId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 3));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setStatId = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.clearStatId = function () {
  return this.setStatId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GameEvent.prototype.hasStatId = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated string info = 5;
 * @return {!Array<string>}
 */
proto.GameEvent.prototype.getInfoList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setInfoList = function (value) {
  return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.addInfo = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.clearInfoList = function () {
  return this.setInfoList([]);
};

/**
 * optional int64 team = 1;
 * @return {number}
 */
proto.GameEvent.prototype.getTeam = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setTeam = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional GameEventType type = 2;
 * @return {!proto.GameEventType}
 */
proto.GameEvent.prototype.getType = function () {
  return /** @type {!proto.GameEventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.GameEventType} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional BallPosition ball_position = 4;
 * @return {?proto.BallPosition}
 */
proto.GameEvent.prototype.getBallPosition = function () {
  return /** @type{?proto.BallPosition} */ (
    jspb.Message.getWrapperField(this, proto.BallPosition, 4)
  );
};

/**
 * @param {?proto.BallPosition|undefined} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setBallPosition = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.clearBallPosition = function () {
  return this.setBallPosition(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GameEvent.prototype.hasBallPosition = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int64 ts = 6;
 * @return {number}
 */
proto.GameEvent.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.GameEvent} returns this
 */
proto.GameEvent.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.StatItem.prototype.toObject = function (opt_includeInstance) {
    return proto.StatItem.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.StatItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.StatItem.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        statId: (f = msg.getStatId()) && id_pb.ID.toObject(includeInstance, f),
        team: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFieldWithDefault(msg, 3, 0),
        scope: jspb.Message.getFieldWithDefault(msg, 7, ""),
        gamePart: jspb.Message.getFieldWithDefault(msg, 6, ""),
        ts: jspb.Message.getFieldWithDefault(msg, 9, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatItem}
 */
proto.StatItem.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatItem();
  return proto.StatItem.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatItem}
 */
proto.StatItem.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 8:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setStatId(value);
        break;
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTeam(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setValue(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setScope(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setGamePart(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatItem.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.StatItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatItem.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f != null) {
    writer.writeMessage(8, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getTeam();
  if (f !== 0) {
    writer.writeInt64(1, f);
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getGamePart();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(9, f);
  }
};

/**
 * optional ID stat_id = 8;
 * @return {?proto.ID}
 */
proto.StatItem.prototype.getStatId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 8));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setStatId = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.clearStatId = function () {
  return this.setStatId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatItem.prototype.hasStatId = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional int64 team = 1;
 * @return {number}
 */
proto.StatItem.prototype.getTeam = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setTeam = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 value = 3;
 * @return {number}
 */
proto.StatItem.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string scope = 7;
 * @return {string}
 */
proto.StatItem.prototype.getScope = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setScope = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string game_part = 6;
 * @return {string}
 */
proto.StatItem.prototype.getGamePart = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setGamePart = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional int64 ts = 9;
 * @return {number}
 */
proto.StatItem.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatItem} returns this
 */
proto.StatItem.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.StatLogItem.prototype.toObject = function (opt_includeInstance) {
    return proto.StatLogItem.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.StatLogItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.StatLogItem.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 2, 0),
        scope: jspb.Message.getFieldWithDefault(msg, 3, ""),
        gamePart: jspb.Message.getFieldWithDefault(msg, 5, ""),
        scoreMap: (f = msg.getScoreMap()) ? f.toObject(includeInstance, undefined) : [],
        ts: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatLogItem}
 */
proto.StatLogItem.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatLogItem();
  return proto.StatLogItem.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatLogItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatLogItem}
 */
proto.StatLogItem.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {!proto.StatLogItem.StatLogType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setScope(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setGamePart(value);
        break;
      case 4:
        var value = msg.getScoreMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint32,
            jspb.BinaryReader.prototype.readString,
            null,
            0,
            "",
          );
        });
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatLogItem.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.StatLogItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatLogItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatLogItem.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getGamePart();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getScoreMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      4,
      writer,
      jspb.BinaryWriter.prototype.writeUint32,
      jspb.BinaryWriter.prototype.writeString,
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(6, f);
  }
};

/**
 * @enum {number}
 */
proto.StatLogItem.StatLogType = {
  EVENT: 0,
  SCORING: 1,
  UNKNOWN: 4,
};

/**
 * optional StatLogType type = 2;
 * @return {!proto.StatLogItem.StatLogType}
 */
proto.StatLogItem.prototype.getType = function () {
  return /** @type {!proto.StatLogItem.StatLogType} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.StatLogItem.StatLogType} value
 * @return {!proto.StatLogItem} returns this
 */
proto.StatLogItem.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional string scope = 3;
 * @return {string}
 */
proto.StatLogItem.prototype.getScope = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.StatLogItem} returns this
 */
proto.StatLogItem.prototype.setScope = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string game_part = 5;
 * @return {string}
 */
proto.StatLogItem.prototype.getGamePart = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.StatLogItem} returns this
 */
proto.StatLogItem.prototype.setGamePart = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * map<uint32, string> score = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.StatLogItem.prototype.getScoreMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
    jspb.Message.getMapField(this, 4, opt_noLazyCreate, null)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.StatLogItem} returns this
 */
proto.StatLogItem.prototype.clearScoreMap = function () {
  this.getScoreMap().clear();
  return this;
};

/**
 * optional int64 ts = 6;
 * @return {number}
 */
proto.StatLogItem.prototype.getTs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatLogItem} returns this
 */
proto.StatLogItem.prototype.setTs = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.StatLog.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.StatLog.prototype.toObject = function (opt_includeInstance) {
    return proto.StatLog.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.StatLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.StatLog.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        logId: (f = msg.getLogId()) && id_pb.ID.toObject(includeInstance, f),
        prev: jspb.Message.getFieldWithDefault(msg, 6, 0),
        next: jspb.Message.getFieldWithDefault(msg, 8, 0),
        logList: jspb.Message.toObjectList(
          msg.getLogList(),
          proto.StatLogItem.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatLog}
 */
proto.StatLog.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatLog();
  return proto.StatLog.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatLog}
 */
proto.StatLog.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setLogId(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPrev(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setNext(value);
        break;
      case 7:
        var value = new proto.StatLogItem();
        reader.readMessage(value, proto.StatLogItem.deserializeBinaryFromReader);
        msg.addLog(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatLog.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.StatLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatLog.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLogId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getPrev();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getLogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(7, f, proto.StatLogItem.serializeBinaryToWriter);
  }
};

/**
 * optional ID log_id = 1;
 * @return {?proto.ID}
 */
proto.StatLog.prototype.getLogId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.setLogId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.clearLogId = function () {
  return this.setLogId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatLog.prototype.hasLogId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 prev = 6;
 * @return {number}
 */
proto.StatLog.prototype.getPrev = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.setPrev = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional uint32 next = 8;
 * @return {number}
 */
proto.StatLog.prototype.getNext = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.setNext = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * repeated StatLogItem log = 7;
 * @return {!Array<!proto.StatLogItem>}
 */
proto.StatLog.prototype.getLogList = function () {
  return /** @type{!Array<!proto.StatLogItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StatLogItem, 7)
  );
};

/**
 * @param {!Array<!proto.StatLogItem>} value
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.setLogList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};

/**
 * @param {!proto.StatLogItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StatLogItem}
 */
proto.StatLog.prototype.addLog = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.StatLogItem, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.StatLog} returns this
 */
proto.StatLog.prototype.clearLogList = function () {
  return this.setLogList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.BallPosition.prototype.toObject = function (opt_includeInstance) {
    return proto.BallPosition.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.BallPosition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.BallPosition.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        ballPositionX: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        ballPositionY: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        valid: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BallPosition}
 */
proto.BallPosition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BallPosition();
  return proto.BallPosition.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BallPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BallPosition}
 */
proto.BallPosition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBallPositionX(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBallPositionY(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setValid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BallPosition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.BallPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BallPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BallPosition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBallPositionX();
  if (f !== 0.0) {
    writer.writeFloat(3, f);
  }
  f = message.getBallPositionY();
  if (f !== 0.0) {
    writer.writeFloat(4, f);
  }
  f = message.getValid();
  if (f) {
    writer.writeBool(5, f);
  }
};

/**
 * optional float ball_position_x = 3;
 * @return {number}
 */
proto.BallPosition.prototype.getBallPositionX = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.BallPosition} returns this
 */
proto.BallPosition.prototype.setBallPositionX = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};

/**
 * optional float ball_position_y = 4;
 * @return {number}
 */
proto.BallPosition.prototype.getBallPositionY = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.BallPosition} returns this
 */
proto.BallPosition.prototype.setBallPositionY = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional bool valid = 5;
 * @return {boolean}
 */
proto.BallPosition.prototype.getValid = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.BallPosition} returns this
 */
proto.BallPosition.prototype.setValid = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Timer.prototype.toObject = function (opt_includeInstance) {
    return proto.Timer.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Timer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Timer.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        statId: (f = msg.getStatId()) && id_pb.ID.toObject(includeInstance, f),
        value: jspb.Message.getFieldWithDefault(msg, 2, 0),
        updateTimeMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
        state: jspb.Message.getFieldWithDefault(msg, 3, 0),
        direction: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Timer}
 */
proto.Timer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Timer();
  return proto.Timer.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Timer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Timer}
 */
proto.Timer.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new id_pb.ID();
        reader.readMessage(value, id_pb.ID.deserializeBinaryFromReader);
        msg.setStatId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setValue(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUpdateTimeMs(value);
        break;
      case 3:
        var value = /** @type {!proto.Timer.State} */ (reader.readEnum());
        msg.setState(value);
        break;
      case 4:
        var value = /** @type {!proto.Timer.Direction} */ (reader.readEnum());
        msg.setDirection(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Timer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Timer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Timer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timer.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f != null) {
    writer.writeMessage(1, f, id_pb.ID.serializeBinaryToWriter);
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(2, f);
  }
  f = message.getUpdateTimeMs();
  if (f !== 0) {
    writer.writeInt64(5, f);
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};

/**
 * @enum {number}
 */
proto.Timer.State = {
  PAUSED: 0,
  TICKING: 1,
};

/**
 * @enum {number}
 */
proto.Timer.Direction = {
  ASC: 0,
  DESC: 1,
};

/**
 * @enum {number}
 */
proto.Timer.Timer_Scope = {
  ALL_MATCH: 0,
  CURRENT_GAME_PART: 1,
};

/**
 * optional ID stat_id = 1;
 * @return {?proto.ID}
 */
proto.Timer.prototype.getStatId = function () {
  return /** @type{?proto.ID} */ (jspb.Message.getWrapperField(this, id_pb.ID, 1));
};

/**
 * @param {?proto.ID|undefined} value
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.setStatId = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.clearStatId = function () {
  return this.setStatId(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Timer.prototype.hasStatId = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.Timer.prototype.getValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 update_time_ms = 5;
 * @return {number}
 */
proto.Timer.prototype.getUpdateTimeMs = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.setUpdateTimeMs = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional State state = 3;
 * @return {!proto.Timer.State}
 */
proto.Timer.prototype.getState = function () {
  return /** @type {!proto.Timer.State} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!proto.Timer.State} value
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional Direction direction = 4;
 * @return {!proto.Timer.Direction}
 */
proto.Timer.prototype.getDirection = function () {
  return /** @type {!proto.Timer.Direction} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {!proto.Timer.Direction} value
 * @return {!proto.Timer} returns this
 */
proto.Timer.prototype.setDirection = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

/**
 * @enum {number}
 */
proto.GameEventType = {
  DANGEROUS_ATTACK: 0,
  ATTACK: 1,
  SAFE_POSSESSION: 2,
  GOAL: 3,
  CORNER: 4,
  YELLOW_CARD: 5,
  RED_CARD: 6,
  GOAL_KICK: 7,
  PENALTY: 8,
  DANGEROUS_FREE_KICK_ZONE: 9,
  FREE_KICK_ZONE: 10,
  SHOT_ON_TARGET: 11,
  SHOT_OFF_TARGET: 12,
  SUBSTITUTION: 13,
  KICK_OFF: 14,
  END_OF_HALF: 15,
  START_OF_HALF2: 16,
  END_OF_FULL_TIME: 17,
  EXTRA_TIME1: 18,
  END_OF_EXTRA_TIME1: 19,
  EXTRA_TIME2: 20,
  END_OF_EXTRA_TIME: 21,
  PENALTY_SHOOT_OUT: 22,
  PENALTY_MISSED: 23,
  THROW_ZONE: 24,
  INJURY: 25,
  INJURY_TIME: 26,
  MATCH_INFO: 27,
  OFFSIDE: 28,
  SUBSTITUTION_ENHANCED: 29,
  TAKE_ON_DANGEROUS_ATTACK: 30,
  TAKE_ON_ATTACK: 31,
  TAKE_ON_SAFE_POSSESSION: 32,
  REPLAY_POSSESSION: 33,
  REPLAY_PENALTY: 34,
  REPLAY_GOAL: 35,
  REPLAY_TRIGGER: 36,
  VIDEO_REFEREE_IN_PROGRESS: 37,
  VIDEO_REFEREE_REVIEWING_RED_CARD: 38,
  VIDEO_REFEREE_REVIEWING_GOAL: 39,
  VIDEO_REFEREE_REVIEWING_PENALTY: 40,
  SHOOT_OUT_SCORE: 41,
  SHOOT_OUT_MISS: 42,
  SHOOT_OUT_TO_TAKE: 43,
  BLOCKED_SHOT: 320,
  GOAL_ABANDONED: 321,
  RED_CARD_CANCELLED: 322,
  YELLOW_CARD_CANCELLED: 323,
  STANDBY_FOR_KICK_OFF: 324,
  POINT_INVESTIGATION: 325,
  POSSIBLE_RED_CARD: 387,
  SHOT_WOODWORK: 388,
  POSSIBLE_PENALTY: 389,
  PENALTY_RETAKEN: 390,
  PENALTY_SHOOTOUT_NOT_STARTED: 391,
  TWO_POINTS: 44,
  THREE_POINTS: 45,
  IN_POSSESSION: 46,
  FREE_THROW: 47,
  FREE_THROW_SCORED: 48,
  FREE_THROW_MISSED: 49,
  TIME_OUT: 50,
  END_OF_QUARTER: 51,
  END_OF_MATCH: 52,
  OVER_TIME: 53,
  FOUL: 54,
  START_OF_QUARTER1: 55,
  START_OF_QUARTER2: 56,
  START_OF_QUARTER3: 57,
  START_OF_QUARTER4: 58,
  START_OF_HALF1: 59,
  TWO_POINTS_SHOT_MISSED: 60,
  THREE_POINTS_SHOT_MISSED: 61,
  TV_TIME_OUT_OVER: 326,
  POINT_MISSED: 327,
  FOUL_CANCELLED: 351,
  START_GAME: 386,
  POSSIBLE_FREE_THROW: 392,
  TECHNICAL_FOUL: 393,
  CORRECTED_SCORES: 394,
  SERVICE: 62,
  RALLY: 63,
  POINT: 64,
  LAST_X_POINTS: 65,
  FAULT: 66,
  ACE: 67,
  END_OF_SET: 68,
  TECHNICAL_TIME_OUT: 69,
  BREAKDOWN: 70,
  CURRENT_STREAK: 71,
  GOLDEN_SET: 72,
  STANDBY_FOR_SERVE: 328,
  TIME_OUT_OVER: 329,
  BLOCK: 330,
  ERROR: 331,
  OUT: 332,
  TRY: 73,
  CONVERSION_SCORED: 75,
  CONVERSION_MISSED: 76,
  PENALTY_SCORED: 77,
  SIN_BIN: 78,
  SIN_BIN_RETURN: 79,
  DROP: 80,
  SCRUM: 81,
  LINE_OUT: 82,
  LINE_OUT_WON: 342,
  TERRITORIAL_PLAY: 83,
  NO_TRY: 84,
  SUDDEN_DEATH: 85,
  END_OF_SUDDEN_DEATH: 86,
  PENALTY_AWARDED: 87,
  KICK_TO_TOUCH: 88,
  KICK_DOWNFIELD: 89,
  PENALTY_ADVANTAGE: 90,
  TAP_AND_GO_BY: 91,
  SAFE_FREE_KICK: 333,
  PENALTY_POINTS: 334,
  PENALTY_TRY: 335,
  TWENTYTWO_DROP_OUT: 336,
  SCRUM_OUTCOME: 337,
  SCRUM_RESET: 338,
  MAUL: 339,
  CONVERSION_ATTEMPT: 92,
  TACKLE_COUNT: 93,
  DROP_OUT: 94,
  GOLDEN_POINT: 95,
  TWENTY_METRE_RESTART: 576,
  LINE_BREAK: 577,
  KICK_AT_GOAL_ATTEMPT: 578,
  RUNS_SCORED: 96,
  BYE: 97,
  LEG_BYE: 98,
  WIDE: 99,
  NO_BALL: 100,
  WICKET_SCORED: 101,
  BOWLED: 102,
  LBW: 103,
  RUN_OUT: 104,
  CAUGHT: 105,
  STUMPED: 106,
  RETIRED_HURT: 107,
  HIT_WICKET: 108,
  HANDLED_BALL: 109,
  HIT_BALL_TWICE: 110,
  OBSTRUCTING: 111,
  TIMED_OUT: 112,
  RETIRED_OUT: 113,
  RAIN_DELAY: 114,
  HAT_TRICK_BALL: 115,
  STAT_ADJUSTMENT: 116,
  FIRST_INNINGS: 117,
  SECOND_INNINGS: 118,
  POWER_PLAY: 119,
  THIRD_UMPIRE: 120,
  THIRD_UMPIRE_NOT_OUT: 121,
  REVIEW: 122,
  REVIEW_SUCCESSFUL: 123,
  REVIEW_UNSUCCESSFUL: 124,
  FREE_HIT: 125,
  SUPER_OVER: 126,
  BAD_LIGHT: 127,
  DUCK: 128,
  GOLDEN_DUCK: 129,
  LANDMARK: 130,
  CENTURY: 131,
  BOWLING: 132,
  END_OF_OVER: 133,
  LUNCH: 134,
  TEA: 135,
  PITCH_INSPECTION: 136,
  MAIDEN_OVER: 137,
  INNINGS_BREAK: 138,
  PITCH_CONDITION: 396,
  MATCH_STOP_SUSPENSION: 397,
  MATCH_START_SUSPENSION: 398,
  BALL_RECYCLED: 399,
  BALL_KICKED: 401,
  CONVERSION: 402,
  DROP_GOAL: 403,
  TMO: 404,
  DENIED_TRY: 405,
  DENIED_DROP_GOAL: 406,
  POSSIBLE_TRY: 407,
  PENALTY_TIME: 139,
  POWER_PLAY_OVER: 140,
  SHOT: 141,
  PENALTY_SHOT: 142,
  PULLED_KEEPER: 143,
  KEEPER_BACK: 144,
  FACE_OFF: 145,
  PUCK_DROPPED: 146,
  FACE_OFF_WINNER: 147,
  ICING: 148,
  PENALTY_OVER: 149,
  START_OF_PERIOD1: 313,
  END_OF_PERIOD1: 314,
  START_OF_PERIOD2: 315,
  END_OF_PERIOD2: 316,
  START_OF_PERIOD3: 317,
  END_OF_PERIOD3: 318,
  VIDEO_REVIEW_START: 340,
  VIDEO_REVIEW_OVER: 341,
  SUSPENDED_SERIOUS_INJURY: 343,
  TIME_STOPPED: 344,
  MATCH_IS_IN_PROGRESS: 345,
  GOALTENDER_IS_ON_THE_ICE: 346,
  GOALKEEPER_OFF: 347,
  HIT: 348,
  MATCH_START_DELAYED: 349,
  BALL_SAFE: 350,
  POSSIBLE_GOAL: 409,
  GOAL_SAVE: 411,
  PLAYER_INJURED: 412,
  PLAYER_UNINJURED: 413,
  EMPTY_NET_OVER: 414,
  POSSIBLE_EMPTY_NET: 415,
  EMPTY_NET: 416,
  PLAYER_ON_SERVE: 150,
  DOUBLE_FAULT: 151,
  BREAK_POINT: 152,
  GAME: 153,
  END_OF_GAME: 154,
  LET_SERVE1: 155,
  LET_SERVE2: 156,
  CHALLENGE: 157,
  CHALLENGE_SUCCESS: 158,
  CHALLENGE_FAILED: 159,
  START_OF_SET1: 160,
  START_OF_SET2: 161,
  START_OF_SET3: 162,
  START_OF_SET4: 163,
  START_OF_FINAL_SET: 164,
  MATCH_STARTED: 165,
  BREAK_POINT_WON: 417,
  DARKNESS: 418,
  LET: 419,
  MATCH_POINT: 420,
  MATCH_POINT_WON: 421,
  PAUSE: 422,
  PLAY_RESUMING_SOON: 423,
  REPLAY_POINT: 424,
  TIEBREAKER: 425,
  TIEBREAK_WON: 426,
  TOILET_BREAK: 427,
  OVER_TIME1: 166,
  SUSPENDED: 167,
  END_OF_QUARTER1: 168,
  END_OF_QUARTER2: 169,
  END_OF_QUARTER3: 170,
  END_OF_QUARTER4: 171,
  END_OF_OVER_TIME: 172,
  END_OF_PERIOD_OVER_TIME: 173,
  PENALTY_SHOOT_OUT_LOWER: 174,
  OVER_TIME2: 175,
  THROW_OFF: 176,
  END_OF_HALF1: 177,
  END_OF_HALF2: 178,
  HALF_2_BOTTOM: 179,
  THROW_OFF_OVER_TIME_BOTTOM: 180,
  OVER_TIME_HALF_2_BOTTOM: 181,
  WITHOUT_GOALKEEPER: 352,
  INTERCEPT: 353,
  GOAL_CANCELLED: 428,
  MANUAL_TIME_ADJUSTMENT: 429,
  BLUE_CARD: 430,
  SHOT_SAVED: 431,
  BALL_FOUL: 432,
  RULE_FOUL: 433,
  SUSPENSION_CANCELLED: 434,
  GOALKEEPER_BACK: 435,
  TOUCH_DOWN: 182,
  FIELD_GOAL: 183,
  PAT_ATTEMPT: 184,
  CONVERSION_PAT_ATTEMPT: 185,
  PAT_SCORED: 186,
  PAT_BLOCKED: 187,
  PAT_FAIL: 188,
  PAT_FUMBLED: 189,
  PAT_INTERCEPTION_ANIM: 190,
  PAT_MADE: 191,
  CONVERSION_BLOCKED: 192,
  PAT_CONVERSION_FAIL: 193,
  PAT_CONVERSION_FUMBLED: 194,
  PAT_INTERCEPTION: 195,
  SAFETY: 196,
  TURNOVER: 197,
  TURNOVER_INTERCEPTION: 198,
  LOST_FUMBLE: 199,
  FUMBLE: 200,
  FLAG_ON_PLAY: 201,
  PICKED_UP: 202,
  PENALTIES_OFFSET: 203,
  PENALTY_AFTER_TOUCH_DOWN: 204,
  PENALTY_DECLINED: 205,
  SPOT_FOUL: 206,
  THIRD_AND_LONG: 207,
  PUNT_RETURNED: 208,
  FAIR_CATCH: 209,
  PUNT_TOUCH_BACK: 210,
  PUNT_DOWNED: 211,
  PUNT_OUT_OF_BOUNDS: 212,
  PUNT_BLOCKED: 213,
  PUNT_FAKE: 214,
  KICK_OFF_RETURNED: 215,
  FAIR_CATCH_OTHER: 216,
  KICK_OFF_TOUCH_BACK: 217,
  KICK_OFF_DOWNED: 218,
  KICK_OFF_OUT_OF_BOUNDS: 219,
  ONSIDE_KICK: 220,
  BACK_SACK: 221,
  TWO_MINUTE_WARNING: 222,
  COACHES_CHALLENGE: 223,
  TV_TIME_OUT: 224,
  OFFICAL_REVIEW: 225,
  WEATHER_DELAY: 226,
  DELAY: 227,
  FIELD_GOAL_MISSED: 228,
  FAKE_FIELD_GOAL: 229,
  FIELD_GOAL_BLOCKED: 230,
  PASS: 231,
  RUSH_OUTCOME: 232,
  PASS_INCOMPLETE: 233,
  RUSH_FAILED: 234,
  COACHES_CHALLENGE_CALL_STANDS: 235,
  CHALLENGE_OVERTURNED: 236,
  PUNT: 237,
  PLAY_START: 238,
  PLAY_OVER: 239,
  ANIM_PENALTY_ACCEPT: 240,
  ANIM_DEFENSIVE_RETURNS: 241,
  ANIM_KICK_OFF_MODE: 242,
  ANIM_VIDEO_REVIEW_START: 243,
  ANIM_VIDEO_REVIEW_OVER_CALL_STANDS: 244,
  ANIM_VIDEO_REVIEW_OVER_CALL_OVERTURNED: 245,
  REPLAY_PASS: 246,
  REPLAY_RUSH: 247,
  REPLAY_PUNT: 248,
  REPLAY_TOUCH_BACK: 249,
  REPLAY_PASS_INCOMPLETE: 250,
  REPLAY_FUMBLE: 251,
  REPLAY_INTERCEPTION: 252,
  REPLAY_TURNOVER_ON_DOWNS: 253,
  REPLAY_SAFETY: 254,
  REPLAY_TOUCH_DOWN: 255,
  REPLAY_FIELD_GOAL_SCORED: 256,
  REPLAY_PAT_SCORED: 257,
  REPLAY_TWO_PAT_CONVERSION: 258,
  REPLAY_FIELD_GOAL_MISSED: 259,
  FIELD_GOAL_ATTEMPT: 260,
  START_INNINGS: 261,
  BALL_IN_PLAY: 262,
  SINGLE: 263,
  DOUBLE: 264,
  TRIPLE: 265,
  HOME_RUN: 266,
  RUN_SCORED: 267,
  BALL: 268,
  STRIKE: 269,
  FOUL_SHOT: 270,
  DOUBLE_PLAY: 271,
  TRIPLE_PLAY: 272,
  HIT_BY_PITCH: 273,
  SACRIFICE_FLY: 274,
  PITCH_OUTCOME: 275,
  OUT_SCORED: 276,
  END_OF_HALF_INNINGS: 277,
  WALK: 278,
  INTENTIONAL_WALK: 279,
  STEAL: 280,
  CAUGHT_STEALING: 281,
  REACH_ON_ERROR: 282,
  REACH_ON_FIELDERS: 283,
  NEW_BATTER: 284,
  NEW_PITCHER: 285,
  STRIKE_OUT: 286,
  RAIN_DELAY_OVER: 287,
  MOVE: 354,
  THROW: 288,
  TOTAL_SCORE: 289,
  DOUBLE_IN_MISS: 290,
  BUST: 291,
  GAME_SHOT: 292,
  NINE_DART_FINISH: 293,
  SET: 438,
  THROW_FIRST: 439,
  GOING_DOWN: 294,
  GOING_BEHIND: 295,
  RACE_OFF: 296,
  FURLONG: 297,
  JUMP: 298,
  PHOTO_FINISH: 299,
  END_OF_RACE: 300,
  RACE_RESULT: 301,
  RACE_IN_PROGRESS: 302,
  FALSE_START: 303,
  GPS_FEED_ERROR: 304,
  GPS_FEED_RESUME: 305,
  RUSHED_BEHIND: 306,
  BEHIND: 307,
  CENTER_BOUNCE: 308,
  FREE_KICK: 309,
  OUT_OF_BOUNDS: 310,
  THROW_IN: 311,
  MARK: 312,
  RERACK: 355,
  FRAME: 356,
  UNSCHEDULED_BREAK: 357,
  REMOVE_BALL: 358,
  FREE_BALL_AWARDED: 359,
  BLUE_BALL: 360,
  RED_BALL: 361,
  PINK_BALL: 362,
  GREEN_BALL: 363,
  YELLOW_BALL: 364,
  BROWN_BALL: 365,
  BLACK_BALL: 366,
  TABLE_READY: 436,
  PLAYER_TO_START: 437,
  SERVE_ACE: 367,
  SERVE_FAULT: 368,
  SIX_METER_PENALTY: 369,
  TEN_METER_PENALTY: 370,
  START_OF_INNING1: 371,
  START_OF_INNING2: 372,
  START_OF_INNING3: 373,
  START_OF_INNING4: 374,
  START_OF_INNING5: 375,
  START_OF_INNING6: 376,
  START_OF_INNING7: 377,
  START_OF_INNING8: 378,
  START_OF_INNING9: 379,
  START_OF_INNING10: 380,
  END_OF_INNING: 381,
  OUT_CANCELLED: 382,
  BALL_CANCELLED: 383,
  STRIKE_CANCELLED: 384,
  PITCHER_CHANGE_CANCELLED: 385,
};

goog.object.extend(exports, proto);
