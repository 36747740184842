import React from "react";

const OptionsArrowDropDownIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.662169 8.26735C0.285787 
                  7.91581 0.285787 7.33252 
                  0.662169 6.98099L7.3532 
                  0.731717C7.71322 0.395468 8.28654 
                  0.395468 8.64656 0.731717L15.3376 
                  6.98099C15.5201 7.15145 15.6199 
                  7.37776 15.6199 7.62417C15.6199 
                  7.87057 15.5201 8.09689 15.3376 
                  8.26734C14.9776 8.60359 14.4043 
                  8.60359 14.0442 8.26734L7.99988 
                  2.62205L1.95553 8.26735C1.59551 
                  8.60359 1.02219 8.60359 0.662169 
                  8.26735Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default OptionsArrowDropDownIcon;
