import React from 'react'
import cn from 'classnames'
import { v4 as uuid } from 'uuid'

import { isValidTheme } from '../../../helpers/isValidTheme'

import popupStyles from './styles/CreateThemePopup.module.scss'

import type { ThemeInterface } from '@/store/reducers/models/interfaces/styles.interface'

import { useAppDispatch, useAppSelector } from '@/store/store'
import { CloseIcon } from '@/components/common'
import { getActiveTheme, getStylesThemes } from '@/store/selectors/stylesSelectors'
import { getUseAuthorization } from '@/store/selectors/generalSelectors'
import { addStyleTheme, setActiveStyleThemeId } from '@/store/reducers/styles'
import { createBackOfficeTheme } from '@/store/thunks/backOfficeThunks'

type PropsType = {
  setActiveCreateThemePopup: (value: boolean) => void
}

const CreateThemePopup: React.FC<PropsType> = ({ setActiveCreateThemePopup }) => {
  const dispatch = useAppDispatch()

  const activeTheme = useAppSelector(getActiveTheme)
  const themes = useAppSelector(getStylesThemes)
  const useAuthorization = useAppSelector(getUseAuthorization)

  const [val, setVal] = React.useState<string>('')
  const [themeNameError, setThemeNameError] = React.useState(false)

  const isCreated = isValidTheme(themes, val)

  const handlePopupClose = () => {
    setVal('')
    setActiveCreateThemePopup(false)
  }

  React.useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlePopupClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  function copyThemes(themes: { [x: string]: any }) {
    const themesCopy: { [x: string]: any } = {}

    for (const key in themes) {
      if (typeof themes[key] === 'object') {
        themesCopy[key] = copyThemes(themes[key])
      } else {
        themesCopy[key] = themes[key]
      }
    }

    return themesCopy
  }

  const createStyleTheme = () => {
    const newObj: ThemeInterface = {
      id: uuid(),
      name: val,
      isDefault: false,
      isSelected: false,
      colors: copyThemes(activeTheme.colors) as any,
    }

    if (!isCreated) {
      setThemeNameError(true)
      return false
    }

    if (useAuthorization) {
      dispatch(createBackOfficeTheme(newObj))
    } else {
      dispatch(addStyleTheme(newObj))
      dispatch(setActiveStyleThemeId(newObj.id))
    }
    handlePopupClose()
  }

  return (
    <div className={cn(popupStyles.popup)}>
      <div className={cn(popupStyles.popup__content)}>
        <div className={cn(popupStyles.popup__header)}>
          <h2 className={cn(popupStyles.title)}>Create Theme</h2>
          <span onClick={handlePopupClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={cn(popupStyles.popup__body)}>
          <input
            value={val}
            minLength={1}
            onChange={(e) => setVal(e.target.value)}
            onInput={() => setThemeNameError(false)}
            type='text'
            name='create_theme'
            placeholder='Theme Name'
            style={{
              borderColor: themeNameError ? 'red' : '',
            }}
          />
          {themeNameError && (
            <span className={popupStyles.validation_message}>
              Theme with this name already exists
            </span>
          )}
        </div>
        <div className={cn(popupStyles.popup__footer)}>
          <button className={cn(popupStyles.cancel)} onClick={handlePopupClose}>
            Cancel
          </button>
          <button className={cn(popupStyles.confirm)} onClick={createStyleTheme} disabled={!val}>
            Confirm
          </button>
        </div>
      </div>
      <div className={cn(popupStyles.popup__backdrop)} onClick={handlePopupClose} />
    </div>
  )
}
export default React.memo(CreateThemePopup)
