import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {GeneralInterface} from '@/store/reducers/models/interfaces/general.interface'

import {ActiveSports, WidgetMinWidth, WidgetMode,} from '@/store/reducers/models/enums/general.enum'

const IS_DEMO_ENV = process.env.REACT_APP_DEMO_MODE;

export const initialState = {
  minWidth: WidgetMinWidth.SMALL,
  width: WidgetMinWidth.LARGE,
  sport: ActiveSports.FOOTBALL,
  mode: IS_DEMO_ENV ? WidgetMode.DEMO : WidgetMode.LIVE,
  useAuthorization: false,
  accessToken: null,
  refreshToken: null,
} as GeneralInterface

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setWidth(state, action: PayloadAction<number>) {
      state.width = action.payload
    },
    setMinWidth(state, action: PayloadAction<number>) {
      state.minWidth = action.payload
    },
    setSport(state, action: PayloadAction<ActiveSports>) {
      state.sport = action.payload
    },
    setMode(state, action: PayloadAction<WidgetMode>) {
      state.mode = IS_DEMO_ENV ? WidgetMode.DEMO : action.payload
    },
    setAccessToken(state, action: PayloadAction<string | null>) {
      state.accessToken = action.payload
    },
    setRefreshToken(state, action: PayloadAction<string | null>) {
      state.refreshToken = action.payload
    },
    setUseAuthorization(state, action: PayloadAction<boolean>) {
      state.useAuthorization = action.payload
    },
    initAuthorization(
      state,
      action: PayloadAction<{
        accessToken: string | null
        refreshToken: string | null
        useAuthorization: boolean
      }>,
    ) {
      const { accessToken, refreshToken, useAuthorization } = action.payload
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.useAuthorization = useAuthorization
    },
  },
})
export const {
  setWidth,
  setMinWidth,
  setMode,
  setSport,
  setAccessToken,
  setRefreshToken,
  setUseAuthorization,
  initAuthorization,
} = generalSlice.actions
export default generalSlice.reducer
