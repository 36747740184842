import React from 'react'
import cn from 'classnames'

import { createThemeColors } from '../../utils/ThemeHelpers'

import paletteStyles from './styles/Palette.module.scss'

import type {
  ThemeColorsInterface,
  ThemeInterface,
} from '@/store/reducers/models/interfaces/styles.interface'

import { useAppDispatch } from '@/store/store'
import { updateThemes } from '@/store/reducers/styles'

const Palette: React.FC<PropsType> = ({ id, colors, theme, disabled }) => {
  const dispatch = useAppDispatch()
  const { teams, backgrounds, fonts, active } = colors

  const handleTeamsColorChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { name } = e.target
    const color = e.target.value

    const updatedObj = theme.map((themeObj: ThemeInterface) => {
      if (themeObj.id === id) {
        if (themeObj.colors.teams.hasOwnProperty(name)) {
          return {
            ...themeObj,
            colors: { ...themeObj.colors, teams: { ...themeObj.colors.teams, [name]: color } },
          }
        }
      }

      return themeObj
    })

    dispatch(updateThemes(updatedObj))
  }

  const handleBackgroundsColorChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { name } = e.target
    const color = e.target.value

    const updatedObj = theme.map((themeObj: ThemeInterface) => {
      if (themeObj.id === id) {
        if (themeObj.colors.backgrounds.hasOwnProperty(name)) {
          return {
            ...themeObj,
            colors: {
              ...themeObj.colors,
              backgrounds: { ...themeObj.colors.backgrounds, [name]: color },
            },
          }
        }
      }

      return themeObj
    })

    dispatch(updateThemes(updatedObj))
  }

  const handleFontsColorChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { name } = e.target
    const color = e.target.value

    const updatedObj = theme.map((themeObj: ThemeInterface) => {
      if (themeObj.id === id) {
        if (themeObj.colors.fonts.hasOwnProperty(name)) {
          return {
            ...themeObj,
            colors: {
              ...themeObj.colors,
              fonts: { ...themeObj.colors.fonts, [name]: color },
            },
          }
        }
      }

      return themeObj
    })

    dispatch(updateThemes(updatedObj))
  }

  const handleActiveColorChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { name } = e.target
    const color = e.target.value

    const updatedObj = theme.map((themeObj: ThemeInterface) => {
      if (themeObj.id === id) {
        if (themeObj.colors.active.hasOwnProperty(name)) {
          return {
            ...themeObj,
            colors: {
              ...themeObj.colors,
              active: { ...themeObj.colors.active, [name]: color },
            },
          }
        }
      }

      return themeObj
    })

    dispatch(updateThemes(updatedObj))
  }

  return (
    <div className={cn(paletteStyles.palette)}>
      {createThemeColors('Teams', teams, id, handleTeamsColorChange, disabled)}

      {createThemeColors('Backgrounds', backgrounds, id, handleBackgroundsColorChange, disabled)}

      {createThemeColors('Fonts', fonts, id, handleFontsColorChange, disabled)}

      {createThemeColors('Active', active, id, handleActiveColorChange, disabled)}
    </div>
  )
}

type PropsType = {
  id: string
  colors: ThemeColorsInterface
  theme: ThemeInterface[]
  disabled: boolean
}

export default React.memo(Palette)
