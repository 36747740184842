import React from 'react'
import cn from 'classnames'

import paletteStyles from '../elements/Palette/styles/Palette.module.scss'
import { isDefaultTheme } from '../../helpers/isDefaultTheme'

import type {
  ThemeColorsActiveInterface,
  ThemeColorsBackgroundsInterface,
  ThemeColorsFontsInterface,
  ThemeColorsTeamsInterface,
} from '@/store/reducers/models/interfaces/styles.interface'

export function createThemeColors(
  objTitle: string,
  objToMap:
    | ThemeColorsTeamsInterface
    | ThemeColorsBackgroundsInterface
    | ThemeColorsFontsInterface
    | ThemeColorsActiveInterface,
  objId: string,
  callback: (e: React.ChangeEvent<HTMLInputElement>, objId: string) => void,
  disabled: boolean,
  activeKey?: true,
) {
  return (
    <div className={cn(paletteStyles.palette__list)}>
      <h3 className={cn(paletteStyles.list_title)}>{objTitle}</h3>
      <div className={cn(paletteStyles.list_item_wrapper)}>
        {Object.entries(objToMap).map(([key, keyVal], index) => {
          if (activeKey) {
            if (key !== 'active') {
              return false
            }
          }
          return (
            <li key={`${objId}_${key}_${index}`}>
              <input
                disabled={disabled}
                type='color'
                id={`${objId}_${key}_${keyVal}`}
                name={key}
                value={keyVal}
                onChange={(e) => callback(e, objId)}
              />
              <label
                htmlFor={`${objId}_${key}_${keyVal}`}
                style={{ backgroundColor: keyVal }}
                className={cn(paletteStyles.list_item)}
              />
            </li>
          )
        })}
      </div>
    </div>
  )
}
