import React from "react";

const PositionDoubleBottomIcon: React.FC<PropsType> = ({ className }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}
    >
      <path
        d="M32.8571 8.33398H7.14286C6.57454 8.33398 6.02949 8.53394 5.62763 8.88987C5.22576 9.2458 5 9.72855 5 10.2319V29.7694C5 30.2727 5.22576 30.7555 5.62763 31.1114C6.02949 31.4674 6.57454 31.6673 7.14286 31.6673H32.8571C33.4255 31.6673 33.9705 31.4674 34.3724 31.1114C34.7742 30.7555 35 30.2727 35 29.7694V10.2319C35 9.72855 34.7742 9.2458 34.3724 8.88987C33.9705 8.53394 33.4255 8.33398 32.8571 8.33398ZM32.8571 10.2319V21.6195H7.14286V10.2319H32.8571ZM7.14286 29.7694V23.5174H32.8571V29.7694H7.14286Z"
      />
    </svg>
  );
};

type PropsType = {
  className: string;
};

export default PositionDoubleBottomIcon;
