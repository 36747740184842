import type {
  ClientConfigurationSettingsInterface,
} from '@/config/models/interfaces/client-configuration-settings.interface'
import type { SettingsInterface } from '@/store/reducers/models/interfaces/settings.interface'

import {
  convertSettingsToBO,
} from '@/widgets/MainWidget/modules/AnimationIframe/components/helpers/convertBackOfficeData'

export const convertSettingsToV2 = (
  settings: SettingsInterface,
): ClientConfigurationSettingsInterface =>
  convertSettingsToBO(settings) as ClientConfigurationSettingsInterface
