import { createSelector } from 'reselect'

import type { AppStateType } from '../store'

import { WidgetMinWidth } from '@/store/reducers/models/enums/general.enum'

export const getGeneral = (state: AppStateType) => state.general

export const getUseAuthorization = createSelector(getGeneral, (state) => state.useAuthorization)
export const getAccessToken = createSelector(getGeneral, (state) => state.accessToken)
export const getRefreshToken = createSelector(getGeneral, (state) => state.refreshToken)

export const getWidgetWidth = createSelector(getGeneral, (state) => state.width)
export const getWidgetMinWidth = createSelector(getGeneral, (state) => state.minWidth)
export const getActiveSport = createSelector(getGeneral, (state) => state.sport)
export const getActiveMode = createSelector(getGeneral, (state) => state.mode)
export const getIsDisabledWidgetPosition = createSelector(
  getGeneral,
  (state) => state.width < WidgetMinWidth.LARGE,
)
