import React from 'react'
import cn from 'classnames'

import tStyles from './styles/Timeline.module.scss'

import { CheckedIcon, UncheckedIcon } from 'widgets/OptionsWidget/modules/elements'
import { useAppDispatch, useAppSelector } from 'store/store'
import { setEventOther } from '@/store/thunks/settingsThunks'
import { getEventOther } from '@/store/selectors/settingsSelectors'

const Other: React.FC = () => {
  const dispatch = useAppDispatch()
  const eventOther = useAppSelector(getEventOther)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const update = eventOther.map(item => {
      if (item.key === name) {
        return {
          ...item,
          value: checked,
        }
      }

      return item
    })

    dispatch(setEventOther(update))
  }
  return (
    <>
      {
        eventOther.map((item, index) => {
          const title = item.name.replace('_', ' ')

          return (
            <div className={cn(tStyles.timeline)} key={`fTimeline_${item.key}_${index}`}>
              <div className={cn(tStyles.timeline__title)}>
                <input
                  id={`${item.key}_${index}_fTimeline`}
                  name={item.key}
                  type='checkbox'
                  checked={item.value}
                  onChange={handleChange}
                />
                <label htmlFor={`${item.key}_${index}_fTimeline`}>
                  {item.value === true ? <CheckedIcon /> : <UncheckedIcon />}
                </label>
                <h2>{title}</h2>
              </div>
            </div>
          )
        })
      }
    </>
  )
}
export default React.memo(Other)
