import React, { useEffect } from 'react'

import AnimationWidget from '../AnimationWidget/AnimationWidget'
import { convertSettingsToV2 } from '../helpers/convertSettingsToV2'
import { convertStylesToV2 } from '../helpers/convertStylesToV2'

import { getSettings } from '@/store/selectors/settingsSelectors'
import { getActiveSport } from '@/store/selectors/generalSelectors'
import { getStyles } from '@/store/selectors/stylesSelectors'
import { getDemoMode } from '@/store/selectors/demoModeSelectors'
import { ActiveSports } from '@/store/reducers/models/enums/general.enum'
import { useAppSelector } from '@/store/store'
import { getLanguages } from '@/store/selectors/languagesSelectors'

export const SPORTS_IDS = new Map<ActiveSports, string>([
  [ActiveSports.FOOTBALL, '15723270'],
  [ActiveSports.BASKETBALL, '23859458'],
  [ActiveSports.TENNIS, '8349763'],
  [ActiveSports.VOLLEYBALL, '21297034'],
  [ActiveSports.AMERICAN_FOOTBALL, '22838555'],
  [ActiveSports.HANDBALL, '24030290'],
  [ActiveSports.ICE_HOCKEY, '21895857'],
  [ActiveSports.DARTS, 'darts'],
  [ActiveSports.DOTA_2, 'dota_2'],
  [ActiveSports.LOL, 'league_of_legends'],
  [ActiveSports.BASEBALL, '23437445'],
  [ActiveSports.WATER_POLO, '24012463'],
  [ActiveSports.RUGBY_LEAGUE, 'rugby'],
  [ActiveSports.RUGBY_UNION, 'rugby'],
  [ActiveSports.CRICKET, 'cricket'],
  [ActiveSports.TABLE_TENNIS, '23851987'],
])

const DemoWidget: React.FC = React.memo(() => {
  const demo = useAppSelector(getDemoMode)
  const settings = useAppSelector(getSettings)
  const sport = useAppSelector(getActiveSport)
  const stateStyles = useAppSelector(getStyles)
  const languages = useAppSelector(getLanguages)

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc) {
      console.log('Admin on change match ID')
      const matchId = SPORTS_IDS.get(sport) || SPORTS_IDS.get(ActiveSports.FOOTBALL)
      sc('setMatchID', matchId)
    }
  }, [sport])

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && settings && stateStyles) {
      const newSettings = convertSettingsToV2(settings)
      const newStyles = convertStylesToV2(stateStyles)
      const newConfig = {
        styles: newStyles,
        settings: newSettings,
      }
      console.log('admin configuration', newConfig)
      sc('setConfiguration', newConfig)
    }
  }, [settings, stateStyles])

  useEffect(() => {
    // @ts-ignore
    const sc = window.SCAW

    if (sc && languages) {
      console.log('admin languages', languages)
      sc('setLanguage', languages.selectedLanguage)
    }
  }, [languages])

  return <AnimationWidget matchID={SPORTS_IDS.get(sport)} />
})

export default DemoWidget
